import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CampaignCard       from 'app/components/common/campaign-card';
import Icon               from 'app/components/common/icon';
import Meta               from 'app/components/common/meta';
import CadminLayout       from 'app/components/company-admin/layout/';
import PageLoading        from 'app/components/layout/page-loading';
import PageDuck           from 'app/ducks/company-admin/page-campaigns-templates';
import CadminSlx          from 'app/selectors/company-admin/';

class PageCadminCampaignsTemplates extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  renderCampaignCards(campaigns, title, cardProps = {}) {
    if (!campaigns || !campaigns.length) return <Icon.Loading className="ca-campaigns-loading" />;
    return (
      <div className="ca-campaigns-section-live-campaigns">
        {title}
        <div className="ca-campaigns-section-live-campaigns-cards">
          {campaigns.map(lc => (
            <div key={lc.id} className="campaigns-cards-card">
              <CampaignCard campaign={lc} {...cardProps} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { company, templateCampaigns } = this.props;
    if (!company) return <PageLoading />;
    const templateTitle = <h4 className="ca-campaigns-section-title template"><Icon.DesignToolsPenStation /><span>Campaign Template Library</span></h4>;
    return (
      <CadminLayout className="ca-campaigns" company={company} activeItem="campaign-templates">
        <Meta title="Campaigns | Millie" />
        {!company.isSystemEvents && (
          this.renderCampaignCards(templateCampaigns, templateTitle, {isTemplate: true, companySlug: company.slug})
        )}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  templateCampaigns: PageDuck.Slx.templateCampaigns(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminCampaignsTemplates);
