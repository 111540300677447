import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import ToastAx         from 'app/actions/toast';
import BackstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import {
  DisbursementCauses as Causes,
  PercentPaymentStatuses,
  PercentPaymentMethods,
}                      from 'app/constants';
import format          from 'app/helpers/format';
import history         from 'app/history';
import paths           from 'app/paths';
import RoutingSlx      from 'app/selectors/routing';

const formatDateTime = (dtStr) => dtStr ? `${moment.utc(dtStr).format('MMM DD, YYYY hh:mmA')} UTC` : null;

class BackstagePageDisbursement extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      disb: null,
      percentObject: null,
      showFailures: false,
      showAll: false,
    };

    this.onClickShowAll = this.onClickShowAll.bind(this);
    this.onCloseAll = this.onCloseAll.bind(this);
    this.onClickShowFailures = this.onClickShowFailures.bind(this);
    this.onCloseFailures = this.onCloseFailures.bind(this);
    this.onClickRetry = this.onClickRetry.bind(this);
  }

  get disb() {
    return this.state.disb;
  }

  get donorName() {
    const {disb} = this.state;
    if (!disb) return null;
    const {user, company} = disb;
    if ((disb.causeType === Causes.DONATION) && user) return `User: ${user.firstName} ${user.lastName}`;
    if ((disb.causeType === Causes.FUND) && company) return `Pooled Fund (${company.name})`;
    if (company) return `Company: ${company.name}`;
    return '';
  }

  get isPercent() {
    return this.disb?.daf === 'percent';
  }

  componentDidMount() {
    BackstageApi.disbursementsSearch({id: this.props.id}).then(({disbursements}) => {
      const disb = (disbursements || [])[0] || null;
      this.setState({disb});
    });
    BackstageApi.disbursementsGetPercent(this.props.id).then(({percentObject}) => {
      this.setState({percentObject});
    });
  }

  onClickShowFailures() {
    this.setState({showFailures: true});
  }
  onCloseFailures() {
    this.setState({showFailures: false});
  }

  onClickRetry(event) {
    const id = this.disb.id;
    const el = event.target;
    el.disabled = true;
    BackstageApi.disbursementsRetry(id).then(() => {
      this.props.toastSuccess('Successfully disbursed 🕺');
    }).catch((error) => {
      this.props.toastError('🙀 Failed again. Refresh the page to see latest failure details.');
    });
  }

  onClickShowAll() {
    this.setState({showAll: true});
  }
  onCloseAll() {
    this.setState({showAll: false});
  }

  renderDetails() {
    const {disb} = this.state;
    if (!disb) return null;
    const {nonprofit} = disb;
    return (
      <table className="backstage">
        <tbody>
          <tr><th>ID</th><td>{disb.id}</td></tr>
          <tr>
            <th>Nonprofit</th>
            <td>
              {nonprofit?.name}<br />
              <Link href={paths.bsNonprofit(nonprofit?.id)}>View in Backstage</Link>
              &nbsp;&bull;&nbsp;
              <Link href={paths.nonprofit(nonprofit)}>Public Profile</Link>
            </td>
          </tr>
          <tr><th>Created</th><td>{formatDateTime(disb.createdAt)}</td></tr>
          <tr><th>Amount</th><td>{format.usd(disb.amountInCents)}</td></tr>
          <tr><th>Actual Amount</th><td>{format.usd(disb.actualAmountInCents)}</td></tr>
          <tr><th>Donor</th><td>{this.donorName}</td></tr>
          <tr><th>Anonymous</th><td>{disb.isAnonymous ? '***YES***' : 'no'}</td></tr>
          <tr><th>Note</th><td>{disb.note}</td></tr>
          <tr><th>Cause Type</th><td>{disb.causeType}<br />{disb.causeId}</td></tr>
        </tbody>
      </table>
    );
  }

  renderEntities() {
    const {disb} = this.state;
    if (!disb) return null;
    const {company, user, employee, fCampaign, bracket} = disb;
    return (
      <table className="backstage">
        <tbody>
          {!!company && (<tr>
            <th>Company</th>
            <td><Link href={paths.bsCompany(company.id)}>{company.name}</Link></td>
          </tr>)}
          {!!employee && (<tr>
            <th>Employee</th>
            <td>{`${employee.firstName} ${employee.lastName}`}<br />{employee.email}</td>
          </tr>)}
          {!!user && (<tr>
            <th>User</th>
            <td><Link href={paths.bsUser(user.id)}>{`${user.firstName} ${user.lastName}`}<br />{user.email}</Link></td>
          </tr>)}
          {!!bracket && (<tr>
            <th>Bracket</th>
            <td>{bracket.name}<br />{bracket.id}</td>
          </tr>)}
          {!!fCampaign && (<tr>
            <th>Campaign (Pooled Fund)</th>
            <td>{fCampaign.name}<br />{fCampaign.id}</td>
          </tr>)}
        </tbody>
      </table>
    );
  }

  renderToDaf() {
    const {disb} = this.state;
    if (!disb) return null;
    const {nonprofit} = disb;
    const hasFailures = !!Object.keys(disb.failures || {}).length;
    const showRetry = !disb.disbursedAt;
    return (
      <table className="backstage">
        <tbody>
          <tr><th>DAF</th><td>{disb.daf}</td></tr>
          <tr><th>DAF ID</th><td>{disb.dafId}</td></tr>
          <tr>
            <th>Sent to DAF</th>
            <td>{disb.disbursedAt ? formatDateTime(disb.disbursedAt) : <Icon.GoodOrBad isGood={false} />}</td>
          </tr>
          <tr>
            <th>Failed Attempts</th>
            <td>
              {Object.keys(disb.failures || {}).length}<br />
              {hasFailures && <button onClick={this.onClickShowFailures} className="btn xs">View Details</button>}
              {showRetry && <button onClick={this.onClickRetry} className="btn xs">Retry</button>}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderToNp() {
    const {disb, percentObject} = this.state;
    if (!disb) return null;
    if (!this.isPercent) return null;
    return (<>
      <h2 className="bs-page-h2">Percent &rarr; Nonprofit</h2>
      <table className="backstage">
        <tbody>
          <tr><th>Status (Fetched from Percent Just Now)</th><td>{percentObject?.status || 'loading...'}</td></tr>
          <tr><th>Payment Status</th><td>{disb.percentStatus || 'NULL (pending/not initiated)'}</td></tr>
          <tr><th>Payment Initiated</th><td>{formatDateTime(disb.percentPaymentInitiatedAt)}</td></tr>
          <tr><th>Payment Method</th><td>{disb.percentPaymentMethod || 'NULL (TBD)'}</td></tr>
          <tr><th>Payment Completed</th><td>{formatDateTime(disb.percentPaidAt)}</td></tr>
        </tbody>
      </table>
    </>);
  }

  renderFailures() {
    if (!this.state.showFailures) return null;
    const failures = this.disb?.failures || null;
    return (
      <Modal className="bform" onClose={this.onCloseFailures}>
        <pre>{JSON.stringify({failures}, null, 4)}</pre>
      </Modal>
    );
  }

  renderAll() {
    if (!this.state.showAll) return null;
    const {disb: millieObject, percentObject} = this.state;
    return (
      <Modal className="bform" onClose={this.onCloseAll}>
        <pre>{JSON.stringify({millieObject, percentObject}, null, 4)}</pre>
      </Modal>
    );
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bspage-disb bs-page">
          <div className="bspage-disb-head">
            <h1 className="bs-page-h1">Disbursement</h1>
          </div>
          <h2 className="bs-page-h2">Actions</h2>
          <div className="btn-row">
            <button onClick={this.onClickShowAll} className="btn secondary small">View Data</button>
          </div>
          <h2 className="bs-page-h2">Millie Details</h2>
          {this.renderDetails()}
          <h2 className="bs-page-h2">Associated Entities</h2>
          {this.renderEntities()}
          <h2 className="bs-page-h2">Millie &rarr; DAF</h2>
          {this.renderToDaf()}
          {this.renderToNp()}
          {this.renderFailures()}
          {this.renderAll()}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  id: RoutingSlx.params(state).id,
});
const dispatchToProps = (dispatch) => ({
  toastSuccess: (content) => dispatch(ToastAx.success(content)),
  toastError: (content) => dispatch(ToastAx.error(content)),
});

export default connect(stateToProps, dispatchToProps)(BackstagePageDisbursement);
