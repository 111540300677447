import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Section from 'app/components/company-dashboards/section';
import Feed    from 'app/components/social/feed';
import {
  SocialFeedTypes,
}              from 'app/constants';

class SectionSocial extends React.PureComponent {
  render() {
    const {dashboard, company, editMode, socialListingIds: listingIds, socialMoreCount: moreCount} = this.props;
    if (!dashboard.hasSocialFeed) return null;

    return (
      <Section title="Activity Feed" color="pink" janchorId="ja-social">
        <div className="cdash-social">
          <Feed
            initialIds={listingIds}
            initialMoreCount={moreCount}
            type={SocialFeedTypes.COMPANY}
            id={company.id}
            title="The Company Dashboard"
            className="cdash-social-feed"
            editMode={editMode}
          />
        </div>
      </Section>
    );
  }
}

SectionSocial.propTypes = {
  dashboard: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  socialListingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  socialMoreCount: PropTypes.number.isRequired,
};

export default SectionSocial;
