import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import MillieApi         from 'app/apis/millie';
import {
  SocialFeedTypes as FeedTypes,
}                        from 'app/constants';
import SocialDuck        from 'app/ducks/social';
import utils             from 'app/helpers/utils';
import history           from 'app/history';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';

const emptyPost = {
  body: null,
  // imgPaths: [
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210400/DSCF0667 copy.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210402/IMG_0812.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210404/IMG_1271.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025225040/IMG_5016.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210400/DSCF0667 copy.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210402/IMG_0812.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025210404/IMG_1271.JPG',
  //   'u/53d13edb-6f67-4957-a6db-4ff9f16c288e/20241025225040/IMG_5016.JPG',
  // ],
  imgPaths: [],
};

const urlsFromBody = (body='') => {
  const urlSet = new Set(utils.extractUrls(body));
  return [...urlSet];
};



/*
 *  Actions
 */

const Types = {
  OPEN: 'MODAL_SPOST_FRM_OPEN',
  CLOSE: 'MODAL_SPOST_FRM_CLOSE',
  SET_BODY: 'MODAL_SPOST_FRM_SET_BODY',
  IMG_ADD: 'MODAL_SPOST_FRM_IMG_ADD',
  IMG_SWAP: 'MODAL_SPOST_FRM_IMG_SWAP',
  IMG_DELETE: 'MODAL_SPOST_FRM_IMG_DELETE',
  IMG_MOVE_UP: 'MODAL_SPOST_FRM_IMG_MOVE_UP',
  IMG_MOVE_DOWN: 'MODAL_SPOST_FRM_IMG_MOVE_DOWN',
  IMG_DELETE_ALL: 'MODAL_SPOST_FRM_IMG_DELETE_ALL',
  URL_SET_META: 'MODAL_SPOST_FRM_URL_SET_META',
  URL_GET_META: 'MODAL_SPOST_FRM_URL_GET_META',
  URL_ADD: 'MODAL_SPOST_FRM_URL_ADD',
  URL_REMOVE: 'MODAL_SPOST_FRM_URL_REMOVE',
  URL_SET_PREVIEW: 'MODAL_SPOST_FRM_URL_SET_PREVIEW',
  LOAD_AUD_OPTS: 'MODAL_SPOST_FRM_LOAD_AUD_OPTS',
  SET_FEEDS: 'MODAL_SPOST_FRM_SET_FEEDS',
  BUMP_FEED_CHANGE: 'MODAL_SPOST_FRM_BUMP_FEED_CHANGE',
  SUBMIT: 'MODAL_SPOST_FRM_SUBMIT',
};

const detectUrls = async (autoSetPreview=false, dispatch, getState) => {
  const state = getState();
  const prevUrls = Slx.urls(state);
  const newUrls = Slx.bodyUrls(state);
  prevUrls.forEach((url) => {
    const isRemoved = !newUrls.includes(url);
    if (isRemoved) dispatch(Ax.urlRemove(url));
  });
  newUrls.forEach((url) => {
    const isAdded = !prevUrls.includes(url);
    if (isAdded) dispatch(Ax.urlAdd(url, autoSetPreview));
  });
};
const autoDetectUrls = _.debounce(detectUrls.bind(null, true), 2000);

let _onCreate;

const Ax = {

  open: ({post=emptyPost, feedType, feedId, onCreate, onUpdate} = {}) => (dispatch, getState) => {
    _onCreate = onCreate;
    const promise = Promise.resolve();
    dispatch({type: Types.OPEN, promise, post, feedType, feedId}).then(() => {
      dispatch(Ax.loadAudienceOpts({targetType: feedType, targetId: feedId, postId: post?.id}));
      dispatch(Ax.setBody(post.body, true));
    });
  },

  close: () => {
    _onCreate = null;
    return {type: Types.CLOSE};
  },

  setBody: (body, immediate=false) => (dispatch, getState) => {
    dispatch({type: Types.SET_BODY, body});
    immediate ? detectUrls(false, dispatch, getState) : autoDetectUrls(dispatch, getState);
  },

  urlAdd: (url, autoSetPreview=true) => (dispatch, getState) => {
    dispatch(Ax.urlGetMeta(url));
    return dispatch({type: Types.URL_ADD, autoSetPreview, url});
  },

  urlRemove: (url) => (dispatch, getState) => {
    return dispatch({type: Types.URL_REMOVE, url});
  },

  urlGetMeta: (url) => {
    const promise = MillieApi.urlsMetaCached(url);
    return {type: Types.URL_GET_META, url, promise};
  },

  urlSetPreview: (previewUrl) => {
    return {type: Types.URL_SET_PREVIEW, previewUrl};
  },

  imgAdd: (imgPath) => {
    return {type: Types.IMG_ADD, imgPath};
  },

  imgSwap: (fromIndex, toIndex) => {
    return {type: Types.IMG_SWAP, fromIndex, toIndex};
  },

  imgDelete: (index) => {
    return {type: Types.IMG_DELETE, index};
  },

  imgMoveUp: (index) => {
    return {type: Types.IMG_MOVE_UP, index};
  },

  imgMoveDown: (index) => {
    return {type: Types.IMG_MOVE_DOWN, index};
  },

  imgDeleteAll: () => {
    return {type: Types.IMG_DELETE_ALL};
  },

  loadAudienceOpts: ({targetType, targetId, postId}) => (dispatch, getState) => {
    const promise = MillieApi.socialAudienceOpts({targetType, targetId, postId});
    const key = `${Math.random()}`;
    return dispatch({type: Types.LOAD_AUD_OPTS, promise, key});
  },

  setFeeds: (feeds) => {
    return {type: Types.SET_FEEDS, feeds};
  },

  bumpFeedChange: () => {
    return {type: Types.BUMP_FEED_CHANGE};
  },

  submit: () => (dispatch, getState) => {
    const state = getState();
    const isNew = Slx.isNew(state);
    const id = Slx.id(state);
    const saveAttrs = Slx.saveAttrs(state);
    const promise = isNew
      ? dispatch(SocialDuck.Ax.postsCreate(saveAttrs))
      : dispatch(SocialDuck.Ax.postsUpdate(id, saveAttrs));
    promise.then(({socialPostListing: listing, hasFeedChange=false}) => {
      if ( isNew && _onCreate) _onCreate(listing);
      if (hasFeedChange) dispatch(Ax.bumpFeedChange());
      dispatch(Ax.close());
    });
    return dispatch({type: Types.SUBMIT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  id: null,
  feedType: null,
  feedId: null,
  isLoading: false,
  isOpen: false,
  isSubmitting: false,
  urls: [],
  urlMetas: {},
  urlMetasLoading: {},
  previewUrl: null,
  body: null,
  imgPaths: [],
  feeds: null,
  audOpts: null,
  audOptsLoading: false,
  audOptsKey: null,
  feedChangeCounter: 0,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.OPEN}_PENDING`]: (state, action) => {
    const {previewUrlObj} = action.post;
    return {...state,
      id: action.post?.id || null,
      feedType: action.feedType || null,
      feedId: action.feedId || null,
      isOpen: true,
      urls: [],
      urlMetas: previewUrlObj ? {[previewUrlObj.url]: previewUrlObj.meta} : {},
      urlMetasLoading: {},
      previewUrl: previewUrlObj?.url || null,
      body: null,
      imgPaths: action.post?.imgPaths || [],
      audOpts: null,
      feeds: null,
    };
  },
  [`${Types.OPEN}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.OPEN}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [Types.CLOSE]: (state, action) => {
    return {...state,
      id: null,
      isOpen: false,
    };
  },

  [Types.SET_BODY]: (state, action) => {
    return {...state,
      body: action.body,
    };
  },

  [Types.IMG_ADD]: (state, action) => {
    return {...state,
      imgPaths: [...state.imgPaths, action.imgPath],
    };
  },

  [Types.IMG_SWAP]: (state, action) => {
    const {fromIndex: a, toIndex: b} = action;
    const imgPaths = [...state.imgPaths];
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_DELETE]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    imgPaths.splice(action.index, 1);
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_MOVE_UP]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    if (action.index <= 0) return state;
    const a = action.index;
    const b = action.index - 1;
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_MOVE_DOWN]: (state, action) => {
    const imgPaths = [...state.imgPaths];
    if (action.index >= (imgPaths.length - 1)) return state;
    const a = action.index;
    const b = action.index + 1;
    [imgPaths[a], imgPaths[b]] = [imgPaths[b], imgPaths[a]];
    return {...state,
      imgPaths,
    };
  },

  [Types.IMG_DELETE_ALL]: (state, action) => {
    return {...state,
      imgPaths: [],
    };
  },

  [Types.URL_SET_META]: (state, action) => {
    const {url, meta} = action;
    return {...state,
      urlMetas: {...state.urlMetas,
        [url]: meta,
      },
    };
  },

  [Types.URL_ADD]: (state, action) => {
    const newState = {...state,
      urls: [...state.urls, action.url],
    };
    if (action.autoSetPreview) {
      newState.previewUrl = action.url;
    }
    return newState;
  },

  [Types.URL_REMOVE]: (state, action) => {
    const urls = [...state.urls];
    const index = urls.indexOf(action.url);
    urls.splice(index, 1);
    let previewUrl = state.previewUrl;
    if (previewUrl === action.url) {
      previewUrl = urls.find(u => u !== action.url) || null;
    }
    return {...state,
      urls,
      previewUrl,
    };
  },

  [`${Types.URL_GET_META}_PENDING`]: (state, action) => {
    const {url} = action;
    return {...state,
      urlMetasLoading: {...state.urlMetasLoading,
        [url]: true,
      },
    };
  },
  [`${Types.URL_GET_META}_RESOLVED`]: (state, action) => {
    const {url, result: {meta}} = action;
    const urlMetasLoading = {...state.urlMetasLoading};
    delete urlMetasLoading[url];
    return {...state,
      urlMetasLoading,
      urlMetas: {...state.urlMetas,
        [url]: meta,
      },
    };
  },
  [`${Types.URL_GET_META}_REJECTED`]: (state, action) => {
    const {url} = action;
    const urlMetasLoading = {...state.urlMetasLoading};
    delete urlMetasLoading[url];
    return {...state, urlMetasLoading};
  },

  [Types.URL_SET_PREVIEW]: (state, action) => {
    return {...state,
      previewUrl: action.previewUrl,
    };
  },

  [`${Types.LOAD_AUD_OPTS}_PENDING`]: (state, action) => {
    return {...state,
      audOptsLoading: true,
      audOptsKey: action.key,
    };
  },
  [`${Types.LOAD_AUD_OPTS}_RESOLVED`]: (state, action) => {
    if (action.key !== state.audOptsKey) return state;
    return {...state,
      audOptsLoading: false,
      audOpts: action.result,
      feeds: action.result.existingFeeds,
    };
  },
  [`${Types.LOAD_AUD_OPTS}_REJECTED`]: (state, action) => {
    if (action.key !== state.audOptsKey) return state;
    return {...state,
      audOptsLoading: false,
    };
  },

  [Types.SET_FEEDS]: (state, action) => {
    return {...state,
      feeds: action.feeds,
    };
  },

  [`${Types.SUBMIT}_PENDING`]: (state, action) => {
    return {...state,
      isSubmitting: true,
    };
  },
  [`${Types.SUBMIT}_RESOLVED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
    };
  },
  [`${Types.SUBMIT}_REJECTED`]: (state, action) => {
    return {...state,
      isSubmitting: false,
    };
  },

  [Types.BUMP_FEED_CHANGE]: (state, action) => {
    return {...state,
      feedChangeCounter: state.feedChangeCounter + 1,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selId                  = (state) => state.modalSocialPostForm.id;
  const selFeedType            = (state) => state.modalSocialPostForm.feedType;
  const selFeedId              = (state) => state.modalSocialPostForm.feedId;
  const selIsLoading           = (state) => state.modalSocialPostForm.isLoading;
  const selIsOpen              = (state) => state.modalSocialPostForm.isOpen;
  const selIsSubmitting        = (state) => state.modalSocialPostForm.isSubmitting;
  const selUrls                = (state) => state.modalSocialPostForm.urls;
  const selUrlMetas            = (state) => state.modalSocialPostForm.urlMetas;
  const selUrlMetasLoading     = (state) => state.modalSocialPostForm.urlMetasLoading;
  const selPreviewUrl          = (state) => state.modalSocialPostForm.previewUrl;
  const selBody                = (state) => state.modalSocialPostForm.body;
  const selImgPaths            = (state) => state.modalSocialPostForm.imgPaths;
  const selFeeds               = (state) => state.modalSocialPostForm.feeds;
  const selAudOpts             = (state) => state.modalSocialPostForm.audOpts;
  const selAudOptsLoading      = (state) => state.modalSocialPostForm.audOptsLoading;
  const selFeedChangeCounter   = (state) => state.modalSocialPostForm.feedChangeCounter;

  const selIsNew = createSelector(
    [selId],
    (id) => !id
  );

  const selBodyUrls = createSelector(
    [selBody],
    (body) => urlsFromBody(body)
  );

  const selUrlObjs = createSelector(
    [selUrls, selUrlMetas, selUrlMetasLoading],
    (urls, metas, metasLoading) => {
      return urls.map((url) => {
        const meta = metas[url] || null;
        const loading = metasLoading[url] || false;
        return {url, meta, loading};
      });
    }
  );

  const selUrlsLoading = createSelector(
    [selUrlObjs],
    (urlObjs) => {
      return !!urlObjs.find(uo => uo.loading);
    }
  );

  const selPreviewUrlObj = createSelector(
    [selPreviewUrl, selUrlObjs, selImgPaths],
    (previewUrl, urlObjs, imgPaths) => {
      if (!!imgPaths?.length) return null;
      return urlObjs.find((obj) => obj.url === previewUrl) || null;
    }
  );

  const selAudienceLabel = createSelector(
    [selFeeds, selAudOpts],
    (feeds, audOpts) => {
      if (!feeds || !audOpts) return 'loading...';
      const firstFeed = (audOpts.availableFeeds || []).find(f => f.id === feeds[0]?.id);
      if (!firstFeed) return 'No audience';
      const otherCount = Math.max(feeds.length - 1, 0);
      let str = (firstFeed.type === FeedTypes.COMPANY) ? 'Company Dashboard' : firstFeed.name;
      if (otherCount) str += ` + ${otherCount} more`;
      return str;
    }
  );

  const selSaveAttrs = createSelector(
    [selBody, selImgPaths, selPreviewUrlObj, selFeedType, selFeedId, selIsNew, selFeeds],
    (body, imgPaths, previewUrlObj, feedType, feedId, isNew, feeds) => {
      return {
        body,
        imgPaths: imgPaths.length ? imgPaths : null,
        previewUrlObj: previewUrlObj ? _.omit(previewUrlObj, ['loading']) : null,
        feeds,
      };
    }
  );

  const selCanSubmit = createSelector(
    [selSaveAttrs, selIsNew, selAudOptsLoading],
    (saveAttrs, isNew, audOptsLoading) => {
      const hasFeeds = !!saveAttrs.feeds?.length;
      if (!hasFeeds) return false;
      if (audOptsLoading) return false;
      const hasBody = !!(saveAttrs.body || '').trim();
      const hasImgs = !!saveAttrs.imgPaths?.length;
      return hasBody || hasImgs;
    }
  );

  return {
    isLoading: selIsLoading,
    isOpen: selIsOpen,
    isSubmitting: selIsSubmitting,
    id: selId,
    isNew: selIsNew,
    bodyUrls: selBodyUrls,
    urls: selUrls,
    urlMetas: selUrlMetas,
    urlObjs: selUrlObjs,
    urlsLoading: selUrlsLoading,
    body: selBody,
    imgPaths: selImgPaths,
    previewUrl: selPreviewUrl,
    previewUrlObj: selPreviewUrlObj,
    saveAttrs: selSaveAttrs,
    canSubmit: selCanSubmit,
    feedType: selFeedType,
    feedId: selFeedId,
    audOpts: selAudOpts,
    feeds: selFeeds,
    audienceLabel: selAudienceLabel,
    feedChangeCounter: selFeedChangeCounter,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
