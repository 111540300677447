import _ from 'lodash';

import { Types }       from 'app/actions/company-admin/builder-bracket';
import bracketHelpers  from 'app/helpers/brackets';
import utils           from 'app/reducers/utils';

const initialState = {
  hasTouched: false,
  savePending: false,
  hasSubmitted: false,
  // entities
  collectionIds: null,
  bracketId: null,
  nonprofitIds: [],
  // attrs
  name: null,
  startDateTime: null,
  size: null,
  timezone: null,
  roundDuration: null,
  roundDurationUnit: null,
  hasSocialFeed: true,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_BRACKET_ID]: (state, {bracketId}) => {
    return {
      ...initialState,
      bracketId,
    };
  },

  [Types.SET_BRACKET]: (state, {bracket, hasTouched = false}) => {
    if (state.bracketId && (bracket.id !== state.bracketId)) return state;
    const attrs = _.pick({...initialState, ...bracket}, bracketHelpers.editAttrs);
    return {
      ...state,
      ...attrs,
      hasTouched,
    };
  },

  [Types.SET_KEY_VAL]: (state, {key, val}) => {
    const newState = {...state,
      hasTouched: true,
      [key]: val,
    };
    if (key === 'size') {
      newState.nonprofitIds = state.nonprofitIds.slice(0, val);
    }
    return newState;
  },

  [Types.SET_KEY_VALS]: (state, {keyVals}) => {
    const newState = {...state,
      ...keyVals,
      hasTouched: true,
    };
    if (keyVals['size']) {
      newState.nonprofitIds = state.nonprofitIds.slice(0, keyVals['size']);
    }
    return newState;
  },

  [Types.SET_NONPROFIT]: (state, {nonprofitId, index}) => {
    const nonprofitIds = [...state.nonprofitIds];
    const oldIndex = nonprofitIds.indexOf(nonprofitId);
    if (oldIndex >= 0) {
      nonprofitIds[oldIndex] = nonprofitIds[index];
    }
    nonprofitIds[index] = nonprofitId;
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },

  [Types.REMOVE_NONPROFIT]: (state, {nonprofitId}) => {
    const nonprofitIds = [...state.nonprofitIds].map(id => (id === nonprofitId) ? null : id);
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },

  [Types.ADD_NONPROFIT]: (state, {nonprofit}) => {
    const nonprofitIds = [...state.nonprofitIds];
    // find first unused index
    let i = 0;
    while (nonprofitIds[i]) i++;
    nonprofitIds[i] = nonprofit.id;
    return {
      ...state,
      nonprofitIds,
      hasTouched: true,
    };
  },

  [Types.SET_HAS_SUBMITTED]: (state, {hasSubmitted}) => {
    return {
      ...state,
      hasSubmitted,
    };
  },

  [`${Types.SAVE}_PENDING`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      savePending: true,
    };
  },

  [`${Types.SAVE}_RESOLVED`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      savePending: false,
      hasTouched: false,
    };
  },

  [`${Types.SAVE}_REJECTED`]: (state, {bracketId}) => {
    if (bracketId !== state.bracketId) return state;
    return {...state,
      savePending: false,
    };
  },

  [`${Types.FETCH_COLS}_RESOLVED`]: (state, {result: {collections}}) => {
    const collectionIds = collections.map(c => c.id);
    return {...state, collectionIds};
  },

});

export default reducer;
