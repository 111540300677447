import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import VolEventsAx   from 'app/actions/vol-events';
import FakeLines     from 'app/components/common/fake-lines';
import Icon          from 'app/components/common/icon';
import ProfileMain   from 'app/components/event-profiles/main';
import ProfileTop    from 'app/components/event-profiles/top';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import Feed          from 'app/components/social/feed';
import ModalRegister from 'app/components/volunteer/modal-register';
import ModalTrack    from 'app/components/volunteer/modal-track';
import {
  USER_DEFAULT_AVATAR_URL,
  VolEventTypes,
  SocialFeedTypes,
}                    from 'app/constants';
import categories    from 'app/helpers/categories';
import format        from 'app/helpers/format';
import slackHelpers  from 'app/helpers/slack';
import utils         from 'app/helpers/utils';
import helpers       from 'app/helpers/vol-events';
import paths         from 'app/paths';
import prompts       from 'app/prompts';

const renderParagraphs = (text) => {
  return (<>
    {(text || '').split('\n').map((p, i) => (
      <p key={i}>{p}</p>
    ))}
  </>);
};

class VolEventProfile extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showModalRegister: false,
      expandedShiftIds: [],
    };

    this.onClickRegister = this.onClickRegister.bind(this);
    this.onCloseRegisterModal = this.onCloseRegisterModal.bind(this);
    this.renderVolList = this.renderVolList.bind(this);
    this.onClickLoadMoreUsers = this.onClickLoadMoreUsers.bind(this);
  }

  get isEvent() {
    return this.props.volEvent.type === VolEventTypes.EVENT;
  }

  onClickLoadMoreUsers(shiftId) {
    this.setState((state) => {
      const idsSet = new Set(this.state.expandedShiftIds);
      idsSet.add(shiftId);
      return {expandedShiftIds: [...idsSet]};
    });
  }

  onClickRegister() {
    if (this.props.editMode) return;
    if (this.props.volEvent.isOngoing) {
      this.props.openTrackModal(this.props.volEvent);
    } else {
      this.setState({showModalRegister: true});
    }
  }

  onCloseRegisterModal() {
    this.setState({showModalRegister: false});
  }

  async onClickCancelReg(participantId, event) {
    const { unregister, volEvent } = this.props;
    const msg = this.isEvent
      ? `Are you sure?\nThis will remove yourself as an attendee.`
      : `Are you sure?\nThis will remove yourself as a volunteer and remove your volunteer hours as well.`;
    const confirmBtnLabel = 'Yes, Cancel';
    const confirmBtnColor = 'danger';
    const cancelBtnLabel = 'No, Nevermind';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel, confirmBtnColor, cancelBtnLabel});
    if (didConfirm) {
      event.target.disabled = true;
      event.target.innerText = 'Cancelling...';
      unregister(volEvent.id, participantId);
    }
  }

  renderShifts() {
    const { volEvent, editMode } = this.props;
    const shifts = _.get(volEvent, 'shifts', []);
    const hasMany = (shifts.length > 1) && !volEvent.isOngoing;
    const hasManyClass = hasMany ? 'many' : 'single';
    return (
      <div className={`vevent-profile-shifts ${hasManyClass}`}>
        {volEvent.isOngoing ? (
          <div className="vevent-profile-shift">
            <div className="vevent-profile-shift-time">
              <Icon.Calendar />
              <span>Ongoing</span>
            </div>
          </div>
        ) : (
          shifts.map((shift, i) => {
            if (shift._delete) return null;
            const formattedShiftTime = helpers.formatShiftTime(shift, editMode);
            const showTime = !!(editMode || formattedShiftTime);
            const slotStrs = [];
            if (shift.capacity) slotStrs.push(`${shift.capacity} ${format.pluralize('spot', shift.capacity)}`);
            if (shift.volCount) slotStrs.push(`${shift.volCount} signed up`);
            let slotStr = slotStrs.join(' | ');
            if (shift.capacity && shift.volCount && shift.capacity > shift.volCount) slotStr += `, ${shift.capacity - shift.volCount} more needed`;
            return (
              <div key={i} className="vevent-profile-shift">
                {showTime && (
                  <div className="vevent-profile-shift-time">
                    <Icon.Calendar />
                    {formattedShiftTime ? (
                      <span>{formattedShiftTime}</span>
                    ) : (
                      <FakeLines lineHeight={9} count={1} />
                    )}
                  </div>
                )}
                {!!slotStrs.length && (
                  <div className="vevent-profile-shift-cap">
                    <Icon.PartyMusicDanceWoman />
                    <span>{slotStr}</span>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  }

  renderRegisterButton() {
    return this.isEvent ? (
      <button className="btn special groups secondary pill" onClick={this.onClickRegister}>
        Sign Up
      </button>
    ) : (
      <button className="btn special orange secondary pill icon" onClick={this.onClickRegister}>
        <Icon.HandExpand /> Volunteer
      </button>
    );

  }

  renderTop() {
    const { volEvent, editMode } = this.props;
    const showDesc = !!(editMode || volEvent.description);
    const { slackChannel, nteeCode } = volEvent;
    const location = volEvent.isRemote
      ? 'Remote'
      : [volEvent.addressLine1, volEvent.city, volEvent.state].filter(p => p).join(', ') + (volEvent.postalCode ? ` ${volEvent.postalCode}` : '');
    const showLocation = !!(editMode || location);
    const nteeName = nteeCode && categories.majorNtees[nteeCode[0]]?.name;
    const showCat = !!(editMode || (nteeCode && nteeName));
    const company = volEvent.isSystemEvent ? null : volEvent.company;
    const slackUrl = slackChannel ? slackHelpers.deepLinkChannel(slackChannel.slackWorkspaceId, slackChannel.slackId) : null;

    return (
      <ProfileTop title={volEvent.title} imgUrl={volEvent.imgPath} groups={volEvent.groups} company={company} editMode={editMode}>
        {showDesc && (
          volEvent.description ? renderParagraphs(volEvent.description) : <FakeLines count={2} lineHeight={9} />
        )}
        <ProfileTop.Links links={volEvent.links} />
        {volEvent.isSystemEvent && utils.isUrl(volEvent.externalUrl) && (
          <a href={volEvent.externalUrl} className="btn special jungle" target="_blank">Get The Details</a>
        )}
        <div className="vevent-profile-location">
          {showLocation && (<>
            <Icon.Pin /> {location || <FakeLines count={1} lineHeight={9} />}
          </>)}
        </div>
        {slackChannel && (
          <div className="vevent-profile-slack">
            <Icon.LogoSlack color /> <a href={slackUrl}>#{slackChannel.name}</a>
          </div>
        )}
        {this.renderShifts()}
        {showCat && (
          <div className="vevent-profile-location">
            <Icon.Ntee nteeCode={nteeCode} /> {nteeName || <FakeLines count={1} lineHeight={9} />}
          </div>
        )}
        {!volEvent.isSystemEvent && (<>
          <hr />
          {this.renderRegisterButton()}
        </>)}
      </ProfileTop>
    );
  }

  renderRegistration() {
    const { volEvent, editMode, myParticipants } = this.props;
    if (editMode) return null;
    if (!myParticipants.length) return null;

    return (
      <div className="vevent-profile-reg">
        <img src="/images/dance-party.svg" className="vevent-profile-reg-party" />
        <h1 className="vevent-profile-reg-heading">You're signed up!</h1>
        <div className="vevent-profile-reg-shifts">
          {myParticipants.map((p) => {
            return (
              <div className="vevent-profile-reg-shift" key={p.id}>
                <div className="vevent-profile-reg-shift-time">
                  {p.shift ? (
                    helpers.formatShiftTime(p.shift)
                  ) : (
                    <span>
                      {moment(p.date).format('dddd, MMMM Do, YYYY')}
                      {!!p.minutes && (
                        <span> | {format.hours(p.minutes)}hrs</span>
                      )}
                    </span>
                  )}
                </div>
                <div className="vevent-profile-reg-shift-actions">
                  {p.shift && <a className="btn small blue icon" href={paths.volEventShiftIcs(volEvent.id, p.shift.id)}><Icon.Calendar />Add to Calendar</a>}
                  <button className="btn small slate secondary" onClick={this.onClickCancelReg.bind(this, p.id)}>Cancel</button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderCols() {
    const { volEvent, editMode } = this.props;
    const showNonprofit = !!(editMode || volEvent.nonprofitId);
    const editCardProps = editMode ? {target: '_blank', donateDisabled: true} : {};
    return (
      <div className="vevent-profile-cols">
        {showNonprofit && (
          <div className="vevent-profile-cols-col">
            <NonprofitCard id={volEvent.nonprofitId} withDonationBox {...editCardProps} />
          </div>
        )}
        {!volEvent.isSystemEvent && (
          <div className="vevent-profile-cols-col">
            {this.renderVolunteers()}
          </div>
        )}
        <div className="vevent-profile-cols-col">
          {this.renderDetails()}
        </div>
      </div>
    );
  }

  renderVolList(emps, shift) {
    const { expandedShiftIds } = this.state;
    const limit = 4;
    const isExpanded = !shift || expandedShiftIds.includes(shift.id);
    const showUsers = isExpanded ? emps : emps.slice(0, limit);
    const showLoadMore = !isExpanded && (emps.length > limit);
    const renderedIds = []; // if an event is changed from multiple shifts to be ongoing, duplicate emps could be in here
    return (
      <div className="vevent-profile-vols-users">
        {showUsers.map((user) => {
          if (renderedIds.includes(user.id)) return null;
          renderedIds.push(user.id);
          const name = `${user.firstName} ${user.lastName}`;
          const img = user.avatarUrl || USER_DEFAULT_AVATAR_URL;
          return (
            <div className="vevent-profile-vols-users-user" key={user.id}>
              <div className="vevent-profile-vols-users-user-img" style={{backgroundImage: `url("${img}")`}} />
              <span>{name}</span>
            </div>
          );
        })}
        {showLoadMore && (
          <button className="vevent-profile-vols-users-loadmore" onClick={this.onClickLoadMoreUsers.bind(this, shift.id)}>Load More</button>
        )}
      </div>
    );
  }

  renderVolunteers() {
    const { volEvent, editMode } = this.props;
    const heading = this.isEvent ? 'Attendees' : 'Volunteers';

    if (editMode) {
      return (
        <div className="vevent-profile-vols vevent-profile-box">
          <h3 className="vevent-profile-box-heading">{heading}</h3>
          <FakeLines count={9} style="users" />
        </div>
      );
    }

    return (
      <div className="vevent-profile-vols vevent-profile-box">
        <h3 className="vevent-profile-box-heading">{heading}</h3>
        {volEvent.isOngoing ? (
          this.renderVolList(volEvent.employees)
        ) : (
          volEvent.shifts.map((shift) => {
            return (
              <div className="vevent-profile-vols-shift" key={shift.id}>
                <div className="vevent-profile-vols-shift-time">{helpers.formatShiftTime(shift, undefined, true)}</div>
                <div className="vevent-profile-vols-shift-slots">
                  {!!shift.volCount && (
                    <span className="vevent-profile-vols-shift-slots-count">{shift.volCount} Signed Up</span>
                  )}
                  {!!shift.capacity && (shift.capacity - shift.volCount > 0) && (
                    <span className="vevent-profile-vols-shift-slots-need">{shift.capacity - shift.volCount} More Needed</span>
                  )}
                </div>
                {this.renderVolList(shift.employees, shift)}
              </div>
            );
          })
        )}
      </div>
    );
  }

  renderDetails() {
    const { volEvent, editMode } = this.props;
    const { details, leadEmployee, contact } = volEvent;
    const showDetails  = !!(editMode || details);
    const showEmployee = !!(editMode || leadEmployee);
    const showContact  = !!(editMode || contact);
    if (!showDetails && !showEmployee && !showContact) return null;

    return (
      <div className="vevent-profile-details vevent-profile-box">
        {showDetails && (<>
          <h3 className="vevent-profile-box-heading">Details</h3>
          {details ? renderParagraphs(details) : <FakeLines lineHeight={9} />}
        </>)}
        {showEmployee && (<>
          <h3 className="vevent-profile-box-heading">Event Lead</h3>
          {leadEmployee ? (
            <div className="vevent-profile-details-lead">{`${leadEmployee.firstName} ${leadEmployee.lastName}`} </div>
          ) : (
            <FakeLines style="users" count={1} />
          )}
        </>)}
        {showContact && (<>
          <h3 className="vevent-profile-box-heading">Contact</h3>
          {contact ? renderParagraphs(contact) : <FakeLines lineHeight={9} />}
        </>)}
      </div>
    );
  }

  renderSocial() {
    const {editMode, socialListingIds: listingIds, socialMoreCount: moreCount, volEvent} = this.props;
    if (!volEvent?.hasSocialFeed) return null;

    return (
      <div className="vevent-profile-social">
        <h2 className="vevent-profile-social-heading">Activity Feed</h2>
        <Feed initialIds={listingIds || []} initialMoreCount={moreCount || 0} type={SocialFeedTypes.EVENT} id={volEvent.id} title={volEvent.title} className="vevent-profile-social-feed" editMode={editMode} />
      </div>
    );
  }

  render() {
    const { volEvent, editMode, myParticipants } = this.props;
    const { showModalRegister } = this.state;
    const typeClass = `type-${volEvent.type}`;

    return (
      <ProfileMain className={`vevent-profile ${typeClass}`} top={this.renderTop()} editMode={editMode}>
        {this.renderRegistration()}
        {this.renderCols()}
        {this.renderSocial()}
        {showModalRegister && !editMode && (
          <ModalRegister onClose={this.onCloseRegisterModal} volEvent={volEvent} myParticipants={myParticipants} />
        )}
        <ModalTrack />
      </ProfileMain>
    );
  }

}

VolEventProfile.propTypes = {
  volEvent: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  myParticipants: PropTypes.arrayOf(PropTypes.object),
  socialListingIds: PropTypes.arrayOf(PropTypes.string),
  socialMoreCount: PropTypes.number,
};

VolEventProfile.defaultProps = {
  editMode: false,
  myParticipants: [],
  socialListingIds: null,
  socialMoreCount: null,
};

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
  unregister: (eventId, participantId) => dispatch(VolEventsAx.unregister(eventId, participantId)),
  openTrackModal: (volEvent) => dispatch(VolEventsAx.trackOpenModal(volEvent)),
});

export default connect(stateToProps, dispatchToProps)(VolEventProfile);
