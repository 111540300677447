import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import cdn from 'app/helpers/cdn';

class PageHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.isScrolled = false;
    this.currentId = null;

    this.state = {
      isScrolled: this.isScrolled,
      currentId: this.currentId,
    };

    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onClickTop = this.onClickTop.bind(this);
    this.checkAnchors = _.throttle(this.checkAnchors.bind(this), 100, {leading: true, trailing: true});
  }

  componentDidMount() {
    this.elBody = document.body;

    window.addEventListener('scroll', this.onWindowScroll);
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
    window.removeEventListener('resize', this.onWindowResize);
  }

  get janchorsQuery() {
    return this.props.items.map(i => `#${i.id}`).join(', ');
  }

  onWindowScroll() {
    this.checkScroll();
    this.checkAnchors();
  }

  onWindowResize() {
    this.checkScroll();
    this.checkAnchors();
  }

  onClickTop(event) {
    event.preventDefault();
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  onClickItem(item) {
    const janchorEl = document.querySelector(`#${item.id}`);
    window.janchorEl = janchorEl;
    if (janchorEl) {
      const offset = -124;
      const scrollTo = window.scrollY + janchorEl.getBoundingClientRect().top + offset;
      window.scrollTo({top: scrollTo, behavior: 'smooth'});
    }
  }

  checkScroll() {
    const isScrolled = document.documentElement.scrollTop > this.props.threshold;
    if (isScrolled !== this.isScrolled) {
      this.isScrolled = isScrolled;
      this.setState({isScrolled});
    }
  }

  checkAnchors() {
    if (!this.janchorsQuery) return;
    const janchorEls = document.querySelectorAll(this.janchorsQuery);
    const midY = document.documentElement.clientHeight / 2;

    const janchorDetails = [...janchorEls].map((janchorEl) => {
      const {top} = janchorEl.getBoundingClientRect();
      const isAboveCenter = top < midY;
      const isVisible = isAboveCenter && (top > 0);
      return {id: janchorEl.id, isVisible, isAboveCenter};
    });
    // get the first one that's within the top half of screen
    // if none, get the last one that above the mid point
    const currentId = (() => {
      const firstVisible = janchorDetails.find(jd => jd.isVisible);
      if (firstVisible) return firstVisible.id;
      const lastAbove = janchorDetails.reverse().find(jd => jd.isAboveCenter);
      if (lastAbove) return lastAbove.id;
      return null;
    })();

    if (currentId !== this.currentId) {
      this.currentId = currentId;
      this.setState({currentId});
    }
  }

  renderItems() {
    const {items, colors} = this.props;
    const {currentId} = this.state;
    const hasActive = !!items.find(i => i.id === currentId);
    const colorClass = colors ? 'has-colors' : 'no-colors';

    return (
      <div className={`pheader-items ${hasActive ? 'has-active' : ''} ${colorClass}`}>
        {items.map((item) => {
          const {id, color, label, icon: ItemIcon} = item;
          const isActive = id === currentId;
          return (
            <div key={id} className={`pheader-item ${isActive ? 'active' : ''} color-${color}`} onClick={this.onClickItem.bind(this, item)}>
              {!!ItemIcon && <ItemIcon className="pheader-item-icon" />}
              <span className="pheader-item-label">{label}</span>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {title, imgPath, thinTitle} = this.props;
    const {isScrolled} = this.state;
    const showClass = isScrolled ? 'show' : 'hide';

    return (
      <div className={`pheader ${showClass}`}>
        <div className="pheader-con widther">
          <div className="pheader-main">
            {!!imgPath && (
              <img className="pheader-main-img" src={cdn.imgUrl(imgPath, {width: 256, height: 128})} />
            )}
            <div className="pheader-main-text">
              <a className={`pheader-main-text-title pink-hover ${thinTitle ? 'thin' : ''}`} onClick={this.onClickTop}>{title}</a>
            </div>
          </div>
          {this.renderItems()}
        </div>
      </div>
    );
  }

}

PageHeader.propTypes = {
  imgPath: PropTypes.string,
  title: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object,
    color: PropTypes.string,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  colors: PropTypes.bool,
  thinTitle: PropTypes.bool,
};

PageHeader.defaultProps = {
  threshold: 100,
  colors: false,
  thinTitle: false,
};

export default PageHeader;
