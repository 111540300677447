import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Icon          from 'app/components/common/icon';
import Link          from 'app/components/common/link';
import Section       from 'app/components/company-dashboards/section';
import NonprofitCard from 'app/components/nonprofits/nonprofit-card';
import {
  USER_DEFAULT_AVATAR_URL,
}                    from 'app/constants';
import paths         from 'app/paths';
import format        from 'app/helpers/format';

class SectionCommunity extends React.PureComponent {

  renderNonprofits() {
    const { dashboard } = this.props;
    const ids = _.get(dashboard, 'lovedNonprofitIds', []);
    if (!ids.length) return null;
    return (
      <div className="cdash-box cdash-com-nonprofits">
        <h2 className="cdash-box-heading center">Nonprofits We Love</h2>
        <div className="cdash-com-nonprofits-list">
          {ids.map((id) => (
            <NonprofitCard key={id} id={id} small />
          ))}
        </div>
      </div>
    );
  }

  renderTeam() {
    const { data } = this.props;
    const emps = _.get(data, 'randomEmployees.employees') || [];
    const totalCount = _.get(data, 'randomEmployees.totalCount') || 0;
    const users = Array.from(new Array(15));
    const othersCount = Math.max(totalCount - emps.length, 0);
    return (
      <div className="cdash-box cdash-com-team">
        <Icon.Lock1 className="cdash-box-private" />
        <h2 className="cdash-box-heading">Our Team</h2>
        <div className="cdash-com-team-members">
          {emps.map((emp) => {
            return (
              <div key={emp.id} className="cdash-com-team-member" style={{backgroundImage: `url('${emp.avatarUrl || USER_DEFAULT_AVATAR_URL}')`}} />
            );
          })}
        </div>
        {!!othersCount && (
          <p className="cdash-com-team-others">+ {numeral(othersCount).format('0,0')} {format.pluralize('other', othersCount)}</p>
        )}
      </div>
    );
  }

  renderProgram() {
    const { dashboard, company } = this.props;
    const programDetails = _.get(dashboard, 'programDetailsText') || '';
    const budget = company.currentMatchBudget;
    const hasMatch = !!(budget?.employeeAmount && company.currentMatchPercent);

    return (
      <div className="cdash-box cdash-com-program">
        <Icon.Lock1 className="cdash-box-private" />
        <h2 className="cdash-box-heading">Our Program Details</h2>
        <div className="cdash-com-program-details">
          {programDetails.split('\n').map((p, i) => <p key={i}>{p}</p>)}
        </div>
        {hasMatch && <Link href={paths.nonprofits({companyId: dashboard.companyId})} className="btn blue small">View Match List</Link>}
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const showEmployeeOnly = !!(data && data.randomEmployees);

    return (
      <Section title="Our Community" color="gray" className="cdash-com" janchorId="ja-comm">
        {this.renderNonprofits()}
        {showEmployeeOnly && (
          <div className="cdash-com-row2">
            <div className="cdash-com-row2-cell1">
              {this.renderTeam()}
            </div>
            <div className="cdash-com-row2-cell2">
              {this.renderProgram()}
            </div>
          </div>
        )}
      </Section>
    );
  }

}

SectionCommunity.propTypes = {
  data: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

export default SectionCommunity;
