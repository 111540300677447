import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Link         from 'app/components/common/link';
import Section      from 'app/components/company-dashboards/section';
import StatsRow     from 'app/components/company-dashboards/stats-row';
import VolEventCard from 'app/components/volunteer/vol-event-card';
import paths        from 'app/paths';

const getStatsFromData = (data) => {
  const totalHours = (_.get(data, 'volTotals.totalMinutes') || 0) / 60;
  const numEvents = _.get(data, 'volTotals.numEvents') || 0;
  const numEmployees = _.get(data, 'volTotals.numEmployees') || 0;
  const numNonprofits = _.get(data, 'volTotals.numNonprofits') || 0;
  const totalDfdAmountInCents = _.get(data, 'volTotals.totalDfdAmountInCents') || 0;
  return [
    {key: 'Hours', val: numeral(totalHours).format('0,0')},
    {key: 'Volunteers', val: numeral(numEmployees).format('0,0')},
    {key: 'Nonprofits', val: numeral(numNonprofits).format('0,0')},
    ...(totalDfdAmountInCents ? [
      {key: 'Dollars for Doers', val: numeral(totalDfdAmountInCents / 100).format('$0,0')},
    ] : [
      {key: 'Events', val: numeral(numEvents).format('0,0')},
    ]),
  ];
};

class SectionVolunteer extends React.PureComponent {

  renderStats() {
    const stats = getStatsFromData(this.props.data);
    return (
      <div className="cdash-box">
        <h3 className="cdash-box-heading">By The Numbers</h3>
        <StatsRow stats={stats} color="orange" />
      </div>
    );
  }

  renderNonprofits() {
    const { data } = this.props;
    const nonprofits = _.get(data, 'volTopNonprofits', []);
    return (
      <div className="cdash-vol-nonprofits cdash-box">
        <h3 className="cdash-box-heading">Top Organizations Supported</h3>
        <table className="cdash-box-table">
          <tbody>
            {nonprofits.map((n, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td><Link className="pink-hover" href={paths.nonprofit(n)}>{n.name}</Link></td>
                <td>{numeral(n.totalMinutes / 60).format('0,0')} Hours</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderOpportunities() {
    const { dashboard } = this.props;
    const ids = _.get(dashboard, 'volEventIds', []);
    return (
      <div className="cdash-vol-opps">
        {ids.filter(id => id).map((id) => (
          <div className="cdash-vol-opps-opp" key={id}>
            <VolEventCard id={id} />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard.showSectionVolunteer) return null;

    return (
      <Section title="Volunteering & Events" color="orange" className="cdash-vol" janchorId="ja-vol">
        <div className="cdash-vol-row1">
          <div className="cdash-vol-row1-cell1">
            {this.renderStats()}
          </div>
          <div className="cdash-vol-row1-cell2">
            {this.renderNonprofits()}
          </div>
        </div>
        {this.renderOpportunities()}
      </Section>
    );
  }

}

SectionVolunteer.propTypes = {
  data: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default SectionVolunteer;
