import React from 'react';
import PropTypes from 'prop-types';

import FancyImage from 'app/components/common/fancy-image';
import Link       from 'app/components/common/link';
import config     from 'app/config';

const getHost = (url) => {
  if (!url) return null;
  const u = new URL(url);
  return u.host;
}

class UrlPreview extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  get isInternal() {
    return !!this.props.urlObj?.url?.startsWith(config.baseUrl);
  }

  get href() {
    const {url} = this.props.urlObj;
    return this.isInternal
      ? (new URL(url)).pathname
      : url;
  }

  onClick(event) {
    const {onClick, urlObj, noClick} = this.props;
    if (noClick) {
      event.preventDefault();
      return;
    }
    if (!onClick) return;
    onClick(event, urlObj);
  }

  render() {
    const {className, urlObj, noClick, onClick} = this.props;
    const {url, meta, loading} = urlObj;
    const title = meta?.title || url;
    const desc = (() => {
      if (loading) return 'loading...';
      return meta?.description || null;
    })();
    const imgUrl = meta?.image || null;
    const host = getHost(url);
    const noClickClass = noClick ? 'no-click' : '';
    const usePushstate = this.isInternal && !noClick && !onClick;

    const children = (<>
      {imgUrl && (
        <FancyImage imgUrl={imgUrl} className="url-preview-img" />
      )}
      <div className="url-preview-text">
        <div className="url-preview-text-host">{host}</div>
        <div className="url-preview-text-title">{title}</div>
        {!!desc && (
          <div className="url-preview-text-desc">{desc}</div>
        )}
      </div>      
    </>);
    return usePushstate
      ? <Link href={this.href} className={`url-preview ${className} ${noClickClass}`} onClick={this.onClick}>{children}</Link>
      : <a rel="nofollow" target="_blank" href={url} className={`url-preview ${className} ${noClickClass}`} onClick={this.onClick}>{children}</a>;
  }

}

UrlPreview.propTypes = {
  urlObj: PropTypes.shape({
    url: PropTypes.string.isRequired,
    meta: PropTypes.object,
    loading: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  noClick: PropTypes.bool,
};

UrlPreview.defaultProps = {
  className: '',
  onClick: null,
  noClick: false,
};

export default UrlPreview;
