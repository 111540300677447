import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import GrantsAx           from 'app/actions/company-admin/grants';
import Checkbox           from 'app/components/common/checkbox';
import CurrencyInput      from 'app/components/common/currency-input';
import Link               from 'app/components/common/link';
import Icon               from 'app/components/common/icon';
import Meta               from 'app/components/common/meta';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import StandardInput      from 'app/components/common/standard-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import PaymentMethodInput from 'app/components/company-admin/wallet/payment-method-input'
import PageLoading        from 'app/components/layout/page-loading';
import NonprofitCard      from 'app/components/nonprofits/nonprofit-card';
import {
  DonatableTypes,
  COMPANY_BALANCE,
}                         from 'app/constants';
import PageDuck           from 'app/ducks/company-admin/page-grants-new';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';
import RoutingSlx         from 'app/selectors/routing';

class PageCadminGrantsCreate extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickCreate = this.onClickCreate.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onSelectPaymentMethod = this.onSelectPaymentMethod.bind(this);
    this.onChangeNote = this.onChangeNote.bind(this);
    this.onChangeWantsCoverFee = this.onChangeWantsCoverFee.bind(this);
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  get isBalance() {
    return this.props.selectedPaymentMethod === COMPANY_BALANCE;
  }

  get showBalanceExceeded() {
    const {amount, company} = this.props;
    if (!this.isBalance) return false;
    if (!_.isFinite(amount)) return false;
    const isOver = amount > company.balance.amount;
    return isOver;
  }

  get showAmountMin() {
    const {amount} = this.props;
    if (!_.isFinite(amount)) return false;
    return amount < 100;
  }

  get validations() {
    const v = {};
    if (this.showAmountMin) {
      v.amount = ['minimum $1.00'];
    }
    if (this.showBalanceExceeded) {
      v.amount = ['can not exceed company wallet balance'];
    }
    if (!Object.keys(v).length) return null;
    return v;
  }

  get fmtBalance() {
    return numeral(this.props.company.balance.amount / 100).format('$0,0.00');
  }

  onChangeNonprofit(nonprofit) {
    this.props.setNonprofit(nonprofit);
    console.log(nonprofit);
  }

  onChangeAmount({amount, currencyCode}) {
    this.props.setAmount(amount);
  }

  onChangeNote(event) {
    const note = event.target.value;
    this.props.setNote(note);
  }

  onChangeWantsCoverFee(event) {
    const wantsCoverFee = event.target.checked;
    this.props.setWantsCoverFee(wantsCoverFee);
  }

  onSelectPaymentMethod(paymentMethod) {
    this.props.setSelectedPaymentMethod(paymentMethod);
  }

  async onClickCreate() {
    const {confirmationMsg, submit} = this.props;
    const didConfirm = await prompts.confirm(confirmationMsg);
    if (didConfirm) {
      submit();
    }
  }

  render() {
    const {
      selectedPaymentMethod, amount, nominalAmount, note, nonprofit,
      showCoverFee, wantsCoverFee, feeAmount,
      canSubmit, submitPending, submitError, submitErrorStripeMsg, submitErrorIneligible,
      company, paymentMethods,
    } = this.props;
    if (!company) return <PageLoading />;
    const btnDisabled = !canSubmit || submitPending;
    const btnLabel = (() => {
      if (submitPending) return 'Sending Donation...';
      if (_.isFinite(amount)) return `Donate ${numeral(amount / 100).format('$0,0.00')}`;
      return 'Donate';
    })();

    return (
      <CadminLayout className="cadmin-cgrant" company={company} activeItem="grants">
        <Meta title="Send Grant | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Send Grant</h1>
            <div className="ca-box-header-controls">
            </div>
          </div>
          <div className="ca-box-body">
            <div className="cadmin-cgrant-body">
              <div className="cadmin-cgrant-form">
                <label className="cadmin-cgrant-form-label">Nonprofit Recipient</label>
                <NonprofitInput onChange={this.onChangeNonprofit} selectedNonprofit={nonprofit} intl={this.hasIntl} />
                <label className="cadmin-cgrant-form-label">Grant Amount</label>
                <CurrencyInput onChange={this.onChangeAmount} amount={nominalAmount} currencyCode="USD" name="amount" validations={this.validations} />
                <label className="cadmin-cgrant-form-label">Note</label>
                <StandardInput type="textarea" name="note" label="Note to nonprofit" value={note || ''} onChange={this.onChangeNote} />
                <label className="cadmin-cgrant-form-label">Payment Method</label>
                <PaymentMethodInput paymentMethods={paymentMethods} onSelect={this.onSelectPaymentMethod} selectedPaymentMethod={selectedPaymentMethod} balanceObj={COMPANY_BALANCE} label="Select a Payment Method" />
                {this.isBalance && (
                  <p className="cadmin-cgrant-form-field-msg">Current balance: {this.fmtBalance}</p>
                )}
                {showCoverFee && (<>
                  <label className="cadmin-cgrant-form-label margin">Cover Fee</label>
                  <div className="cb-label-con">
                    <Checkbox id="cadmin-cgrant-cover-fee-cb" checked={wantsCoverFee} onChange={this.onChangeWantsCoverFee} />
                    <label htmlFor="cadmin-cgrant-cover-fee-cb">Cover Fee (+{numeral(feeAmount / 100).format('$0,0.00')})</label>
                  </div>
                </>)}
              </div>
              <div className="cadmin-cgrant-preview">
                <NonprofitCard id={nonprofit?.id} target="_blank" />
              </div>
            </div>
          </div>
          <div className="ca-box-footer">
            <button onClick={this.onClickCreate} className="btn special pink" disabled={btnDisabled}>{btnLabel}</button>
            {submitError && <div className="cadmin-cgrant-error-msg">Oops! Something went wrong.</div>}
            {submitErrorStripeMsg && <div className="cadmin-cgrant-error-msg">{`Message from payment processor: ${submitErrorStripeMsg}`}</div>}
            {submitErrorIneligible && <div className="cadmin-cgrant-error-msg">This nonprofit is not currently eligible to receive donations.</div>}
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  query: RoutingSlx.query(state),

  paymentMethods: PageDuck.Slx.paymentMethods(state),
  nonprofit: PageDuck.Slx.nonprofit(state),
  selectedPaymentMethod: PageDuck.Slx.selectedPaymentMethod(state),
  note: PageDuck.Slx.note(state),
  nominalAmount: PageDuck.Slx.nominalAmount(state),
  amount: PageDuck.Slx.amount(state),
  feeAmount: PageDuck.Slx.feeAmount(state),
  showCoverFee: PageDuck.Slx.showCoverFee(state),
  wantsCoverFee: PageDuck.Slx.wantsCoverFee(state),
  coverFee: PageDuck.Slx.coverFee(state),

  params: PageDuck.Slx.params(state),
  canSubmit: PageDuck.Slx.canSubmit(state),
  confirmationMsg: PageDuck.Slx.confirmationMsg(state),
  submitPending: PageDuck.Slx.submitPending(state),
  submitError: PageDuck.Slx.submitError(state),
  submitErrorStripeMsg: PageDuck.Slx.submitErrorStripeMsg(state),
  submitErrorIneligible: PageDuck.Slx.submitErrorIneligible(state),
});

const dispatchToProps = (dispatch) => ({
  setNonprofit: (nonprofit) => dispatch(PageDuck.Ax.setNonprofit(nonprofit)),
  setSelectedPaymentMethod: (pm) => dispatch(PageDuck.Ax.setSelectedPaymentMethod(pm)),
  setAmount: (nominalAmount) => dispatch(PageDuck.Ax.setAmount(nominalAmount)),
  setNote: (note) => dispatch(PageDuck.Ax.setNote(note)),
  setWantsCoverFee: (wantsCoverFee) => dispatch(PageDuck.Ax.setWantsCoverFee(wantsCoverFee)),
  submit: () => dispatch(PageDuck.Ax.submit()),

  // create: (companyId, params) => dispatch(GrantsAx.create(companyId, params)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGrantsCreate);
