import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi         from 'app/apis/backstage';
import BtnMasqueradeCompany from 'app/components/backstage/common/btn-masquerade-company';
import BalancesByOwner      from 'app/components/backstage/common/balances-by-owner';
import BackstageLayout      from 'app/components/backstage/layout';
import ModalEdit            from 'app/components/backstage/modal-edit-company';
import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import config               from 'app/config';
import {
  Features,
  CompanyCustomerTypes as CustomerTypes,
}                           from 'app/constants';
import paths                from 'app/paths';
import RoutingSlx           from 'app/selectors/routing';

const custTypeNames = {
  [CustomerTypes.CUSTOMER]: 'Customer',
  [CustomerTypes.FRIENDS]: 'Friends & Family',
  [CustomerTypes.TEST]: 'Testing/Dev',
};

class BackstageCompanyPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      showEditModal: false,
    };

    this.onClickDtdSend = this.onClickDtdSend.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
  }

  onClickDtdSend() {
    backstageApi.companiesSendToDtd(this.props.qsId).then(() => {
      this.fetch();
    });
  }

  onClickEdit() {
    this.setState({showEditModal: true});
  }

  onCloseEditModal() {
    this.setState({showEditModal: false});
  }

  /*
   *  Life Cycle
   */

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.qsId !== this.props.qsId) && this.props.qsId) {
      this.fetch();
    }
  }

  /*
   *  Tasks
   */

  fetch() {
    this.setState({company: null});
    backstageApi.companiesGet(this.props.qsId).then(({company}) => {
      this.setState({company});
    });
  }

  /*
   *  Render
   */

  renderKeyVals(keyVals) {
    return (
      <table className="backstage">
        <tbody>
          {_.map(keyVals, (val, key) => (
            <tr key={key}>
              <th>{key}</th>
              <td>{val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderDetails() {
    const { company } = this.state;
    if (!company) return null;
    const { customerGivingUser: cgUser, employeeGivingUser: egUser } = company;

    const keyVals = {
      'ID': company.id,
      'Source': company.source,
      'Year 2020 Starts On': moment.utc(company.year2020StartDate).format('MMMM DD, YYYY'),
      'Current Year': (<span>
        <strong>{company.currentYear}</strong>
        <br />
        <span>{moment.utc(company.currentYearStartDate).format('MM/DD/YY')} - {moment.utc(company.currentYearEndDate).format('MM/DD/YY')}</span>
      </span>),
      'Timezone': company.timezone,
      'URL Slug': company.slug,
      'Employees': (<span>
        {numeral(company.employeeCount).format('0,0')} / {numeral(company.saasEmployeeCap).format('0,0')}
      </span>),
      'Customer Giving User': cgUser ? (<span>
        <Link href={paths.user(cgUser.id)}>{`${cgUser.firstName} ${cgUser.lastName}`}</Link>
        <br />
        <span>{cgUser.email}</span>
      </span>) : '',
      'Employee Giving User': egUser ? (<span>
        <Link href={paths.user(egUser.id)}>{`${egUser.firstName} ${egUser.lastName}`}</Link>
        <br />
        <span>{egUser.email}</span>
      </span>) : '',
      'Customer Type': custTypeNames[company.customerType] || company.customerType,
    };

    return this.renderKeyVals(keyVals);
  }

  renderFeatures() {
    const { company } = this.state;
    if (!company) return null;

    return (
      <div>
        {Object.values(Features).map((feature) => {
          const isOn = company.saasWhitelist.includes(feature);
          return <div key={feature} className={`bs-feature-pill ${isOn ? 'on' : 'off'}`}>{feature}</div>;
        })}
      </div>
    );
  }

  renderDtd() {
    const { company } = this.state;
    if (!company) return null;
    return (
      <div>
        Update Last Sent: {company.dtdUpdatedAt && moment(company.dtdUpdatedAt).format('lll')}
        <br /><br />
        <button className="btn small" onClick={this.onClickDtdSend}>Send Update</button>
      </div>
    );
  }

  render() {
    const { company, showEditModal } = this.state;
    const stripeUrl = `${config.stripeDashboardUrl}/customers/${company?.stripeCustomerId}`;
    return (
      <BackstageLayout>
        <div className="bs-page page-bs-company">
          <h1 className="bs-page-h1">Company - {company ? company.name : null}</h1>

          <br />
          <h2>Actions</h2> 
          {company && (<div className="page-bs-company-actions">
            <button className="btn small" onClick={this.onClickEdit}>Edit</button>
            &nbsp;
            <a href={stripeUrl} target="_blank" className="btn small icon"><Icon.ExternalLink />Stripe Profile</a>
            &nbsp;
            <a href={`/api/cadmin/${company.id}/subscription/go-to-portal`} target="_blank" className="btn small icon"><Icon.ExternalLink />Customer Billing Portal</a>
            &nbsp;
            <BtnMasqueradeCompany className="small" id={company.id} />
            &nbsp;
            <Link className="btn small danger" href={paths.cadminDashboardView(company.slug, company.companyDashboardId)}>Admin</Link>
          </div>)}

          <br />
          <h2>Details</h2>
          {this.renderDetails()}

          <br />
          <h2>Features</h2>
          {this.renderFeatures()}

          <br />
          <h2>Balances</h2>
          {company && (
            <BalancesByOwner types={[BalancesByOwner.Types.COMPANY]} id={company.id} key={company.id} />
          )}

          <br />
          <h2>Double The Donation</h2>
          {this.renderDtd()}

          <br />
        </div>
        {(showEditModal && company) && (
          <ModalEdit company={company} onClose={this.onCloseEditModal} />
        )}
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
  qsId: RoutingSlx.params(state).id,
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageCompanyPage);
