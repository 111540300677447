import axios from 'app/axios';

import config from 'app/config';

const client = axios.create({
  baseURL: '/api/backstage',
});

const Endpoints =  {

  testSlack: (channel, text, parentId) => {
    return client.post('/test-slack', {channel, text, parentId}).then(res => res.data);
  },

  /*
   *  Pending Claims
   */

  pendingClaimsFetch: () => {
    return client.get('/pending-claims').then(res => res.data);
  },

  pendingClaimsApprove: (id) => {
    return client.post(`/pending-claims/${id}/approve`).then(res => res.data);
  },

  pendingClaimsReject: (id) => {
    return client.post(`/pending-claims/${id}/reject`).then(res => res.data);
  },

  /*
   *  Disbursements
   */

  disbursementsSearch: (params = {}) => {
    return client.get('/disbursements', {params}).then(res => res.data);
  },

  disbursementsRetry: (disbursementId) => {
    return client.patch(`/disbursements/${disbursementId}/retry`).then(res => res.data);
  },

  disbursementsGetPercent: (disbursementId) => {
    return client.get(`/disbursements/${disbursementId}/percent`).then(res => res.data);
  },

  /*
   *  Failed Charges
   */

  failedChargesFetch: () => {
    return client.get('/failed-charges').then(res => res.data);
  },

  /*
   *  Nonprofit Profiles
   */

  nonprofitProfilesFetch: ({page = 1} = {}) => {
    return client.get(`/nonprofit-profiles?page=${page}`).then(res => res.data);
  },

  nonprofitProfilesCreate: (nonprofitId) => {
    const body = {nonprofitId};
    return client.post('/nonprofit-profiles', body).then(res => res.data);
  },

  /*
   *  Users
   */

  usersFetch: ({page = 1, sort = 'join-date', search} = {}) => {
    const params = {page, sort, search};
    return client.get('/users', {params}).then(res => res.data);
  },

  usersGet: (id) => {
    return client.get(`/users/${id}`).then(res => res.data);
  },

  usersSearch: (search) => {
    const params = {search};
    return client.get('/users/search', {params}).then(res => res.data);
  },

  usersMasquerade: (id) => {
    return client.post(`/users/${id}/masquerade`).then(res => res.data);
  },

  /*
   *  Collections
   */

  collectionsFetch: () => {
    return client.get('/collections').then(res => res.data);
  },

  collectionsCreate: (title, description, order, path) => {
    const body = {title, description, order, path};
    return client.post('/collections', body).then(res => res.data);
  },

  collectionUpdate: (collectionId, params) => {
    return client.patch(`/collections/${collectionId}`, params).then(res => res.data);
  },

  collectionNonprofitsCreate: (collectionId, nonprofitId, order) => {
    const body = {collectionId, nonprofitId, order};
    return client.post('/collection-nonprofits', body).then(res => res.data);
  },

  collectionNonprofitsDelete: (collectionNonprofitId) => {
    return client.delete(`/collection-nonprofits/${collectionNonprofitId}`).then(res => res.data);
  },

  /*
   *  Nonprofits
   */

  nonprofitsFetch: (query = {}) => {
    return client.get(`/nonprofits`, {params: query}).then(res => res.data);
  },

  nonprofitsGet: (id) => {
    return client.get(`/nonprofits/${id}`).then(res => res.data);
  },

  nonprofitsToggleDisabled: (id) => {
    return client.patch(`/nonprofits/${id}/toggle-disabled`).then(res => res.data);
  },
  nonprofitsDisable: (id) => {
    return client.patch(`/nonprofits/${id}/disable`).then(res => res.data);
  },
  nonprofitsEnable: (id) => {
    return client.patch(`/nonprofits/${id}/enable`).then(res => res.data);
  },

  nonprofitsMakeUnclaimed: (id) => {
    return client.patch(`/nonprofits/${id}/make-unclaimed`).then(res => res.data);
  },

  nonprofitsCreate: (attrs) => {
    const body = attrs;
    return client.post(`/nonprofits`, body).then(res => res.data);
  },

  nonprofitsUpdate: (id, attrs) => {
    const body = attrs;
    return client.patch(`/nonprofits/${id}`, body).then(res => res.data);
  },

  nonprofitEinData: (ein) => {
    return client.get(`/nonprofits/${ein}/data`).then(res => res.data);
  },

  nonprofitsUpdateEligibility: (id, {eligible, eligibleOverrideType}) => {
    const body = {eligible, eligibleOverrideType};
    return client.patch(`/nonprofits/${id}/eligibility`, body).then(res => res.data);
  },

  /*
   *  Nonprofit Badges
   */

  nonprofitBadgesAdd: (nonprofitId, badge) => {
    return client.put(`/nonprofits/${nonprofitId}/badges/${badge}`).then(res => res.data);
  },

  nonprofitBadgesRemove: (nonprofitId, badge) => {
    return client.delete(`/nonprofits/${nonprofitId}/badges/${badge}`).then(res => res.data);
  },

  /*
   *  Nonprofit Countries
   */

  nonprofitCountriesAdd: (nonprofitId, isoCode) => {
    return client.put(`/nonprofits/${nonprofitId}/countries/${isoCode}`).then(res => res.data);
  },

  nonprofitCountriesRemove: (nonprofitId, isoCode) => {
    return client.delete(`/nonprofits/${nonprofitId}/countries/${isoCode}`).then(res => res.data);
  },

  /*
   *  Companies
   */

  companiesFetch: ({search} = {}) => {
    const params = {search};
    return client.get('/companies', {params}).then(res => res.data);
  },

  companiesGet: (id) => {
    return client.get(`/companies/${id}`).then(res => res.data);
  },

  companiesCreate: (attrs) => {
    const body = attrs;
    return client.post('/companies', body).then(res => res.data);
  },

  companiesUpdate: (id, attrs) => {
    const body = attrs;
    return client.patch(`/companies/${id}`, body).then(res => res.data);
  },

  companiesSendToDtd: (id) => {
    return client.post(`/companies/${id}/send-to-dtd`).then(res => res.data);
  },

  companiesMasquerade: (id) => {
    return client.post(`/companies/${id}/masquerade`).then(res => res.data);
  },

  /*
   *  Dashboard
   */

  dashboardOverview: (query = {}) => {
    return client.get('/dashboard/overview', {params: query}).then(res => res.data);
  },

  /*
   *  Feature Flags
   */

  featureFlagsGet: (key) => {
    return client.get(`/feature-flags/${key}`).then(res => res.data);
  },

  featureFlagsAddUser: (key, userId) => {
    return client.post(`/feature-flags/${key}/users/${userId}`).then(res => res.data);
  },

  featureFlagsRemoveUser: (key, userId) => {
    return client.delete(`/feature-flags/${key}/users/${userId}`).then(res => res.data);
  },

  featureFlagsAddCompany: (key, companyId) => {
    return client.post(`/feature-flags/${key}/companies/${companyId}`).then(res => res.data);
  },

  featureFlagsRemoveCompany: (key, companyId) => {
    return client.delete(`/feature-flags/${key}/companies/${companyId}`).then(res => res.data);
  },

  /*
   *  Balances
   */

  balancesFetch: (query = {}) => {
    return client.get('/balances', {params: query}).then(res => res.data);
  },

  balancesGet: (id) => {
    return client.get(`/balances/${id}`).then(res => res.data);
  },

  balancesFetchTransactions: (id, query = {}) => {
    return client.get(`/balances/${id}/transactions`, {params: query}).then(res => res.data);
  },

  /*
   *  BalanceTransactions
   */

  balanceTransactionsCreate: (attrs) => {
    const body = attrs;
    return client.post(`/balance-transactions`, body).then(res => res.data);
  },

  /*
   *  Disputes
   */

  disputesFetchUnresolved: () => {
    return client.get(`/disputes/unresolved`).then(res => res.data);
  },

  disputesResolve: (disputeId) => {
    return client.patch(`/disputes/${disputeId}/resolve`).then(res => res.data);
  },

  /*
   *  NTEE Codes
   */

  nteeCodesFetch: () => {
    return client.get('/ntee-codes').then(res => res.data);
  },

  nteeCodesUpdate: (code, attrs) => {
    const body = attrs;
    return client.patch(`/ntee-codes/${code}`, body).then(res => res.data);
  },

  /*
   *  Percent Validation Submissions
   */

  pvssSearch: ({nonprofitId}={}) => {
    const params = {nonprofitId};
    return client.get('/percent-validation-submissions', {params}).then(res => res.data);
  },

  pvssCreate: ({ein, website}) => {
    const body = {ein, website};
    return client.post('/percent-validation-submissions', body).then(res => res.data);
  },

  /*
   *  Nonprofit Requests
   */

  nonprofitRequestsSearch: ({page, sort} = {}) => {
    const params = {page, sort};
    return client.get('/nonprofit-requests', {params}).then(res => res.data);
  },

  nonprofitRequestsGetOrg: ({registryId, registeredNumber}) => {
    return client.get(`/nonprofit-requests/${registryId}/${encodeURIComponent(registeredNumber)}`).then(res => res.data);
  },

  nonprofitRequestsApprove: ({registryId, registeredNumber, attrs}) => {
    const body = attrs;
    return client.post(`/nonprofit-requests/${registryId}/${encodeURIComponent(registeredNumber)}/approve`, body).then(res => res.data);
  },

  /*
   *  Nonprofit Builds
   */

  nonprofitBuildsCreate: (nonprofitId) => {
    return client.post(`/nonprofit-builds/${nonprofitId}`).then(res => res.data);
  },

  nonprofitBuildsUpdate: (nonprofitId, status) => {
    const body = {status};
    return client.patch(`/nonprofit-builds/${nonprofitId}`, body).then(res => res.data);
  },

};

export {
  client,
};

export default Endpoints;
