// https://www.streamlinehq.com/icons/streamline-light

import React from 'react';

import { Categories } from 'app/constants';

const StreamlineSvg = ({className, size=24, children, fill='none', ...props}) => (
  <svg {...props} viewBox={`0 0 ${size} ${size}`} className={`icon ${className || ''}`}>
    <g fill={fill} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      {children}
    </g>
  </svg>
);

const Icon = {

  AccountingBill: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="2.504" y="7" width="19" height="10" rx="0.5" ry="0.5" />
      <rect x="0.504" y="5" width="23" height="14" rx="1" ry="1" />
      <circle cx="12.004" cy="12" r="3" />
      <path d="M5,9.25a.25.25,0,1,1-.25.25A.25.25,0,0,1,5,9.25" />
      <path d="M19,14.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    </StreamlineSvg>
  )),

  AccountingBills: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.504" y="4" width="19" height="12" rx="1" ry="1" />
      <path d="M21.5,6V17a1,1,0,0,1-1,1H2.5" />
      <path d="M23.5,8V19a1,1,0,0,1-1,1H4.5" />
      <circle cx="10.004" cy="10" r="3" />
      <line x1="2.504" y1="14" x2="4.504" y2="14" />
      <line x1="15.504" y1="6" x2="17.504" y2="6" />
    </StreamlineSvg>
  )),

  AccountingCalc1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="3.004" y="0.5" width="18" height="23" rx="2.5" ry="2.5" />
      <line x1="3.004" y1="15.5" x2="21.004" y2="15.5" />
      <line x1="3.004" y1="8.5" x2="21.004" y2="8.5" />
      <line x1="12.004" y1="8.5" x2="12.004" y2="23.5" />
      <line x1="5.504" y1="12.5" x2="8.504" y2="12.5" />
      <line x1="15.004" y1="12.5" x2="18.004" y2="12.5" />
      <line x1="15.004" y1="20.5" x2="18.004" y2="20.5" />
      <line x1="15.004" y1="18.5" x2="18.004" y2="18.5" />
      <line x1="7.004" y1="11" x2="7.004" y2="14" />
      <line x1="5.504" y1="18" x2="8.504" y2="21" />
      <line x1="8.504" y1="18" x2="5.504" y2="21" />
      <line x1="17.005" y1="5.5" x2="17.005" y2="3.5" />
    </StreamlineSvg>
  )),

  AccountingCoins: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="16.004" cy="8" r="7.5" />
      <path d="M8.53,8.526a7.5,7.5,0,1,0,6.948,6.948" />
      <line x1="7.504" y1="13.5" x2="7.504" y2="12.5" />
      <path d="M9,13.5H6.971a1.342,1.342,0,0,0-.5,2.587l2.064.826a1.342,1.342,0,0,1-.5,2.587H6" />
      <line x1="7.504" y1="20.5" x2="7.504" y2="19.5" />
      <line x1="16.004" y1="5" x2="16.004" y2="4" />
      <path d="M17.5,5H15.471a1.342,1.342,0,0,0-.5,2.587l2.064.826a1.342,1.342,0,0,1-.5,2.587H14.5" />
      <line x1="16.004" y1="12" x2="16.004" y2="11" />
    </StreamlineSvg>
  )),

  AddCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11" />
      <line x1="12" y1="6" x2="12" y2="18" />
      <line x1="18" y1="12" x2="6" y2="12" />
    </StreamlineSvg>
  )),

  AlertCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23,11.811A11.162,11.162,0,0,1,12,23,10.837,10.837,0,0,1,1,12.192,11.162,11.162,0,0,1,12,1,10.838,10.838,0,0,1,23,11.811Z" />
      <line x1="12" y1="14.005" x2="12" y2="7.005" />
      <path d="M11.991,16.005a.245.245,0,0,0-.241.255.254.254,0,0,0,.253.245h0a.246.246,0,0,0,.241-.255A.253.253,0,0,0,12,16.005h-.005" />
    </StreamlineSvg>
  )),

  AnalyticsBars: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="0.441" y1="19.5" x2="23.441" y2="19.5" />
      <path d="M4.441,19.5V15a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v4.5" />
      <path d="M10.441,19.5V10a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v9.5" />
      <path d="M16.441,19.5V12a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v7.5" />
      <path d="M22.441,19.5V5a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5V19.5" />
    </StreamlineSvg>
  )),

  AnalyticsPie2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M13.5,10a.5.5,0,0,0,.5.5h9.5a10,10,0,0,0-10-10Z" />
      <path d="M20.575,19.571A9.965,9.965,0,0,0,23.5,12.5H14c-.275,0-.341.159-.147.353Z" />
      <path d="M10.5,3.5a10,10,0,1,0,7.071,17.071l-6.718-6.718A1.391,1.391,0,0,1,10.5,13Z" />
    </StreamlineSvg>
  )),

  ArrowButtonRight: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19.342,12.376l-12.47,10.8a.5.5,0,0,1-.707-.052L4.606,21.3a.5.5,0,0,1,.056-.706l9.892-8.4a.249.249,0,0,0,0-.381l-9.892-8.4A.5.5,0,0,1,4.606,2.7L6.165.881A.5.5,0,0,1,6.872.829L19.342,11.62A.5.5,0,0,1,19.342,12.376Z" />
    </StreamlineSvg>
  )),

  AthleticsJumpingPerson: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="14" cy="3" r="2.5" />
      <path d="M22.561,6.439a1.5,1.5,0,0,0-2.121,0L18.731,8.148a1.5,1.5,0,0,1-1.516.368L9.548,6.077a4.477,4.477,0,0,0-4.294.872L3.024,8.861a1.5,1.5,0,1,0,1.953,2.277l2.23-1.912a1.48,1.48,0,0,1,1.431-.29l1.986.632L8.021,15.5H2.5a1.5,1.5,0,0,0,0,3H9a1.5,1.5,0,0,0,1.374-.9l.724-1.649,3.5-.318a1.493,1.493,0,0,1,1.275.518l1.992,2.324a1.5,1.5,0,0,0,2.277-1.953L18.146,14.2a4.492,4.492,0,0,0-3.824-1.554l-1.847.168L13.5,10.484l2.807.893a4.491,4.491,0,0,0,4.546-1.106L22.56,8.562A1.5,1.5,0,0,0,22.561,6.439Z" />
      <line x1="13" y1="18" x2="13" y2="23.5" />
      <line x1="11" y1="23.5" x2="15" y2="23.5" />
    </StreamlineSvg>
  )),

  BasketBall: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M3.58,19.881c1.388-4.232,5.664-13.5,16.887-15.64" />
      <path d="M3.464,4.294c4.57,1.65,13.1,6.227,14.905,17.295" />
      <path d="M10.544.592A8.522,8.522,0,0,1,.812,9.417" />
      <path d="M11.764,23.462C7.028,15.793,15.953,6.893,23.5,11.7" />
    </StreamlineSvg>
  )),

  Bin: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M20.5,3.5v19a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1V3.5" />
      <path d="M7.5,3.5v-2a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v2" />
      <line x1="0.5" y1="3.5" x2="23.5" y2="3.5" />
      <line x1="7.5" y1="7" x2="7.5" y2="19" />
      <line x1="12" y1="7" x2="12" y2="19" />
      <line x1="16.5" y1="7" x2="16.5" y2="19" />
    </StreamlineSvg>
  )),

  Bracket: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M1,2.765 L4.64606291,2.765 C4.94778469,2.76500896 5.19237852,3.00960279 5.19237852,3.31132457 C5.19237852,3.31132457 5.19237852,3.31132457 5.19237852,3.31132457 L5.19237852,9.10681425 C5.19470995,9.2991979 5.13934472,9.48751728 5.03522404,9.64928981 C4.94171416,9.79457632 4.84185369,9.86721958 4.73564263,9.86721958 L1.02651218,9.86721958"  />
      <path d="M1,14.1285864 L4.64606291,14.1285864 C4.94778469,14.1285955 5.19237852,14.3731893 5.19237852,14.6749111 C5.19237852,14.6749111 5.19237852,14.6749111 5.19237852,14.6749111 L5.19237852,20.4704022 C5.1947099,20.6627834 5.13934606,20.8511005 5.03522786,21.0128717 C4.94171688,21.1581622 4.84185513,21.2308074 4.73564263,21.2308074 L1.02651218,21.2308074"  />
      <path d="M5.5625,5.54753174 L9.12502582,5.54753174 C9.63725567,5.54756207 10.0525,5.96280641 10.0525,6.47503625 C10.0525,6.47503625 10.0525,6.47503625 10.0525,6.47503625 L10.0525,16.5560327 C10.0525305,16.6405511 10.041816,16.7247284 10.0206432,16.8065518 C9.88288867,17.3389111 9.73045438,17.6050908 9.56334036,17.6050908 L5.5908943,17.6050908"  />
      <path d="M18.8101215,2.765 L22.4561844,2.765 C22.7579062,2.76500896 23.0025,3.00960279 23.0025,3.31132457 C23.0025,3.31132457 23.0025,3.31132457 23.0025,3.31132457 L23.0025,9.10681425 C23.0048314,9.2991979 22.9494662,9.48751728 22.8453455,9.64928981 C22.7518356,9.79457632 22.6519752,9.86721958 22.5457641,9.86721958 L18.8366337,9.86721958" transform="translate(20.906311, 6.316110) scale(-1, 1) translate(-20.906311, -6.316110)" />
      <path d="M18.8101215,14.1285864 L22.4561844,14.1285864 C22.7579062,14.1285955 23.0025,14.3731893 23.0025,14.6749111 C23.0025,14.6749111 23.0025,14.6749111 23.0025,14.6749111 L23.0025,20.4704022 C23.0048314,20.6627834 22.9494675,20.8511005 22.8453493,21.0128717 C22.7518384,21.1581622 22.6519766,21.2308074 22.5457641,21.2308074 L18.8366337,21.2308074" transform="translate(20.906311, 17.679697) scale(-1, 1) translate(-20.906311, -17.679697)" />
      <path d="M13.95,5.54753174 L17.5125258,5.54753174 C18.0247557,5.54756207 18.44,5.96280641 18.44,6.47503625 C18.44,6.47503625 18.44,6.47503625 18.44,6.47503625 L18.44,16.5560327 C18.4400305,16.6405511 18.429316,16.7247284 18.4081432,16.8065518 C18.2703887,17.3389111 18.1179544,17.6050908 17.9508404,17.6050908 L13.9783943,17.6050908" transform="translate(16.195000, 11.576311) scale(-1, 1) translate(-16.195000, -11.576311)" />
      <path d="M10.5,11.877755 L13.69,11.877755" />
    </StreamlineSvg>
  )),

  BrowserPageText: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="0.5" width="23" height="23" rx="2" ry="2" />
      <line x1="0.5" y1="5.5" x2="23.5" y2="5.5" />
      <path d="M4,2.75A.25.25,0,1,1,3.75,3,.25.25,0,0,1,4,2.75" />
      <path d="M7,2.75A.25.25,0,1,1,6.75,3,.25.25,0,0,1,7,2.75" />
      <path d="M10,2.75A.25.25,0,1,1,9.75,3,.25.25,0,0,1,10,2.75" />
      <rect x="3.5" y="8.5" width="17" height="3" rx="0.5" ry="0.5" />
      <rect x="3.5" y="13.5" width="3" height="7" rx="0.5" ry="0.5" />
      <line x1="9.5" y1="14.5" x2="19.5" y2="14.5" />
      <line x1="9.5" y1="16.5" x2="19.5" y2="16.5" />
      <line x1="9.5" y1="18.5" x2="19.5" y2="18.5" />
      <line x1="9.5" y1="20.5" x2="16.5" y2="20.5" />
    </StreamlineSvg>
  )),

  BuildingDaylight: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M7.5,23.5H15a.5.5,0,0,0,.5-.5V11a.5.5,0,0,0-.5-.5H8a.5.5,0,0,0-.5.5Z" />
      <path d="M9.5,10.5V5A.5.5,0,0,0,9,4.5H1A.5.5,0,0,0,.5,5V23a.5.5,0,0,0,.5.5H7.5" />
      <path d="M2.5,23.5V21a.5.5,0,0,1,.5-.5H5a.5.5,0,0,1,.5.5v2.5" />
      <path d="M10.5,23.5V21a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2.5" />
      <line x1="15.5" y1="12.5" x2="12.5" y2="12.5" />
      <line x1="9.5" y1="8.5" x2="6.5" y2="8.5" />
      <line x1="9.5" y1="6.5" x2="4.5" y2="6.5" />
      <line x1="15.5" y1="14.5" x2="13.5" y2="14.5" />
      <circle cx="18.502" cy="5.5" r="2" />
      <line x1="18.502" y1="0.5" x2="18.502" y2="1.5" />
      <line x1="14.967" y1="1.964" x2="15.674" y2="2.671" />
      <line x1="13.502" y1="5.498" x2="14.502" y2="5.498" />
      <line x1="14.966" y1="9.035" x2="15.673" y2="8.327" />
      <line x1="18.501" y1="10.498" x2="18.5" y2="9.499" />
      <line x1="22.036" y1="9.035" x2="21.328" y2="8.329" />
      <line x1="23.5" y1="5.501" x2="22.5" y2="5.502" />
      <line x1="22.036" y1="1.966" x2="21.33" y2="2.674" />
    </StreamlineSvg>
  )),

  Buildings1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,7A.5.5,0,0,0,9,6.5H1A.5.5,0,0,0,.5,7V23a.5.5,0,0,0,.5.5H9.5Z" />
      <path d="M1.5,6.5V4A.5.5,0,0,1,2,3.5H8a.5.5,0,0,1,.5.5V6.5" />
      <line x1="4.5" y1="3.5" x2="4.5" y2="0.5" />
      <path d="M23.5,23a.5.5,0,0,1-.5.5H16.5V10a.5.5,0,0,1,.5-.5h6a.5.5,0,0,1,.5.5Z" />
      <path d="M17.5,9.5V8a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5V9.5" />
      <line x1="9.5" y1="12.5" x2="16.5" y2="12.5" />
      <line x1="9.5" y1="23.5" x2="16.5" y2="23.5" />
      <line x1="2.5" y1="9.5" x2="7.5" y2="9.5" />
      <line x1="2.5" y1="12.5" x2="7.5" y2="12.5" />
      <line x1="2.5" y1="15.5" x2="7.5" y2="15.5" />
      <line x1="18.5" y1="12.5" x2="21.5" y2="12.5" />
      <line x1="18.5" y1="15.5" x2="21.5" y2="15.5" />
      <line x1="2.5" y1="18.5" x2="7.5" y2="18.5" />
      <line x1="18.5" y1="18.5" x2="21.5" y2="18.5" />
      <line x1="11.5" y1="15.5" x2="14.5" y2="15.5" />
      <line x1="11.5" y1="18.5" x2="14.5" y2="18.5" />
      <path d="M5.5,23.5V21a.5.5,0,0,0-.5-.5H4a.5.5,0,0,0-.5.5v2.5" />
      <path d="M19.5,23.5V21a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v2.5" />
    </StreamlineSvg>
  )),

  BusinessClimbTop: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M4.25,23.5,12.75,10a1.259,1.259,0,0,1,2,0l8.5,13.5Z" />
      <path d="M4.25,23.5H.75L4.25,18a1.341,1.341,0,0,1,1-.5,1.341,1.341,0,0,1,1,.5L6.9,19.3" />
      <path d="M8.705,16.424l2.343,1.982a1,1,0,0,0,1.353-.057l.642-.642a1,1,0,0,1,1.414,0l.642.642a1,1,0,0,0,1.353.057l2.3-1.943" />
      <line x1="13.75" y1="9.5" x2="13.75" y2="0.5" />
      <path d="M13.75.5h6.96a.5.5,0,0,1,.39.812L20,2.688a.5.5,0,0,0,0,.624l1.1,1.376a.5.5,0,0,1-.39.812H13.75" />
    </StreamlineSvg>
  )),

  BusinessTeamGoal: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="14.25" y1="21.594" x2="14.25" y2="23" />
      <path d="M19.25,23.5a5,5,0,0,0-10,0" />
      <circle cx="14.25" cy="13.5" r="3.5" />
      <path d="M8.252,21.627A4.252,4.252,0,0,0,2.75,23.5" />
      <path d="M7.492,17a4.933,4.933,0,0,0,1.943-.4,2.956,2.956,0,0,0-5.49-1.1A4.938,4.938,0,0,0,7.492,17Z" />
      <circle cx="6.5" cy="17" r="2.975" />
      <line x1="21.25" y1="23.5" x2="21.25" y2="0.5" />
      <path d="M21.25.5h-6a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h6" />
      <path d="M14.25,2.5H7.75L10.25,5,7.75,7.5h7.5a1,1,0,0,0,1-1v-1" />
      <path d="M11.454,11.4A5.739,5.739,0,0,0,15.3,13a7.1,7.1,0,0,0,2.339-.388" />
    </StreamlineSvg>
  )),

  Calendar: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="2.501" width="23" height="21" rx="1" ry="1" />
      <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
      <line x1="18.5" y1="0.501" x2="18.5" y2="5.501" />
      <line x1="0.5" y1="7.501" x2="23.5" y2="7.501" />
    </StreamlineSvg>
  )),

  Calendar1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="3.501" width="22" height="20" rx="1" ry="1" />
      <line x1="3.5" y1="1.501" x2="3.5" y2="4.501" />
      <line x1="7.5" y1="1.501" x2="7.5" y2="4.501" />
      <line x1="11.5" y1="1.501" x2="11.5" y2="4.501" />
      <line x1="15.5" y1="1.501" x2="15.5" y2="4.501" />
      <line x1="19.5" y1="1.501" x2="19.5" y2="4.501" />
      <path d="M12.5,8.5h3a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-3A1,1,0,0,1,12.5,8.5Z" />
      <path d="M15.5,8.5h3a1,1,0,0,1,1,1v3a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-4A0,0,0,0,1,15.5,8.5Z" />
      <rect x="11.5" y="12.501" width="4" height="4" />
      <rect x="15.5" y="12.501" width="4" height="4" />
      <rect x="11.5" y="16.501" width="4" height="4" />
      <path d="M15.5,16.5h4a0,0,0,0,1,0,0v3a1,1,0,0,1-1,1h-3a0,0,0,0,1,0,0v-4A0,0,0,0,1,15.5,16.5Z" />
      <path d="M4.5,12.5h3a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-3A1,1,0,0,1,4.5,12.5Z" />
      <rect x="7.5" y="12.501" width="4" height="4" />
      <path d="M3.5,16.5h4a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-3a1,1,0,0,1-1-1v-3A0,0,0,0,1,3.5,16.5Z" />
      <rect x="7.5" y="16.501" width="4" height="4" />
    </StreamlineSvg>
  )),

  CalendarCheck1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="17.5" cy="17.501" r="6" />
      <path d="M20.174,15.756,17.269,19.63a.751.751,0,0,1-1.131.08l-1.5-1.5" />
      <path d="M9.5,18.5h-8a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1V10" />
      <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
      <line x1="15.5" y1="0.501" x2="15.5" y2="5.501" />
      <line x1="0.5" y1="7.501" x2="20.5" y2="7.501" />
    </StreamlineSvg>
  )),

  CalendarDate: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="2.501" width="23" height="21" rx="1" ry="1" />
      <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
      <line x1="18.5" y1="0.501" x2="18.5" y2="5.501" />
      <line x1="0.5" y1="7.501" x2="23.5" y2="7.501" />
      <line x1="6.5" y1="19.001" x2="11.5" y2="19.001" />
      <path d="M9,19V11.5a.5.5,0,0,0-.812-.391L6.5,12.46" />
      <path d="M15.5,19l2.931-7.255a.469.469,0,0,0-.005-.5A.546.546,0,0,0,17.962,11H14a.5.5,0,0,0-.5.5v1" />
    </StreamlineSvg>
  )),

  CalendarWarning: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M17.5,20.5a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <line x1="17.5" y1="18.501" x2="17.5" y2="15.501" />
      <path d="M18.338,12.5a.95.95,0,0,0-1.676,0l-5.056,9.634a.925.925,0,0,0,.031.915.948.948,0,0,0,.807.448H22.556a.948.948,0,0,0,.807-.448.925.925,0,0,0,.031-.915Z" />
      <path d="M9.5,18.5h-8a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1V10" />
      <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
      <line x1="15.5" y1="0.501" x2="15.5" y2="5.501" />
      <line x1="0.5" y1="7.501" x2="20.5" y2="7.501" />
    </StreamlineSvg>
  )),

  Caret: ({direction='up', ...props}) => {
    const className = `caret ${direction} ${props.className || ''}`;
    return (
      <StreamlineSvg size={12} {...props} className={className}>
        <polyline points="1 3.5 6 8.5 11 3.5" />
      </StreamlineSvg>
    );
  },

  CatAnimals: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M8.166,3.084A5.251,5.251,0,0,0,5.06,2.6,5.446,5.446,0,0,0,.5,8.049v5.515a7.454,7.454,0,0,0,2.5,5.5l1.683-1A5.181,5.181,0,0,1,3.5,14.515V12.433a7.181,7.181,0,0,0,3.507.758l.27,1.349A16.488,16.488,0,0,1,7.5,17.789v4.275h3v-6h1" />
      <path d="M23.5,12.564V10.988a4.785,4.785,0,0,0-4.633-4.924H14.623" />
      <path d="M7.267,4.431C8.451,2.064,9.67,2.119,12,2.064c3.455-.081,3.105,2,2.5,4.514-.484,2.019-1.285,3.769-3.613,3.769" />
      <path d="M4.5,6.814a.25.25,0,0,1,.25.25" />
      <path d="M4.25,7.064a.25.25,0,0,1,.25-.25" />
      <path d="M4.5,7.314a.249.249,0,0,1-.25-.25" />
      <path d="M4.75,7.064a.249.249,0,0,1-.25.25" />
      <path d="M23.5,19.564V17a3.221,3.221,0,0,0-3.25-2.936" />
      <path d="M17.5,19.064a1.6,1.6,0,0,0-1.5-1.5h-.5" />
      <path d="M17.5,13.066a2.512,2.512,0,0,0-2.235-.4,2.456,2.456,0,0,0-1.671,1.7,2.658,2.658,0,0,0-.092.619v5.072a.979.979,0,0,0,1,1" />
      <line x1="17.5" y1="19.064" x2="17.5" y2="22.064" />
      <path d="M18,16.064a1.1,1.1,0,0,0,1.5.5c1-.5,1-4,0-4a5.172,5.172,0,0,0-2,.5" />
      <polyline points="19.5 22.064 19.5 19.564 23.5 19.564 23.5 22.064" />
    </StreamlineSvg>
  )),

  CatCivilRights: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="12" y1="6" x2="12" y2="21" />
      <line x1="9.081" y1="21" x2="14.892" y2="21" />
      <polyline points="1.001 11.998 4.5 4 7.987 11.998" />
      <line x1="10.585" y1="4" x2="3" y2="4" />
      <path d="M8,12a3.5,3.5,0,0,1-7,0Z" />
      <polyline points="22.999 11.998 19.5 4 16.013 11.998" />
      <line x1="13.415" y1="4" x2="21" y2="4" />
      <path d="M16,12a3.5,3.5,0,0,0,7,0Z" />
      <circle cx="12" cy="4.5" r="1.5" />
    </StreamlineSvg>
  )),

  CatCommunityDev: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M15.5,10 C13.8431458,10 12.5,11.4774603 12.5,13.3 L12.5,16.05 L14,16.05 L14.5,21 L16.5,21 L17,16.05 L18.5,16.05 L18.5,13.3 C18.5,11.4774603 17.1568542,10 15.5,10 Z" />
      <path d="M19.0031792,7.89350358 C20.3455816,8.26552794 21.7747648,7.63487075 22.3010179,6.43826287 C22.8272709,5.24165499 22.2774313,3.87283776 21.0322636,3.27971871 C19.7870959,2.68659967 18.25632,3.06434342 17.5,4.15136196" />
      <path d="M19.2557692,9.12417687 C19.5424688,9.04133911 19.8379284,8.99959304 20.1346154,9 C21.993266,9 23.5,10.6351365 23.5,12.652176 L23.5,15.78261 L21.9778846,15.78261 L21.5769231,21 L18.6923077,21 L18.5,18.4956528" />
      <path d="M8.5,11 C10.1568542,11 11.5,12.4923842 11.5,14.3333333 L11.5,17.1111111 L10,17.1111111 L9.5,21 L7.5,21 L7,17.1111111 L5.5,17.1111111 L5.5,14.3333333 C5.5,12.4923842 6.84314575,11 8.5,11 Z" />
      <path d="M3.99682075,7.89350358 C2.65441842,8.26552794 1.22523519,7.63487075 0.698982135,6.43826287 C0.172729084,5.24165499 0.722568689,3.87283776 1.96773637,3.27971871 C3.21290406,2.68659967 4.74367998,3.06434342 5.5,4.15136196" />
      <path d="M4.82236584,9.12417687 C4.53038827,9.04133911 4.22948932,8.99959304 3.92734038,9 C2.03447256,9 0.5,10.6351365 0.5,12.652176 L0.5,15.78261 L2.05013709,15.78261 L2.45848022,21 L5.39620055,21 L5.5,19.6674786" />
      <circle cx="8.5" cy="8" r="2" />
      <circle cx="15.5" cy="7" r="2" />
    </StreamlineSvg>
  )),

  CatCulture: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="1.5" y="0.5" width="21" height="11" rx="1" ry="1" />
      <rect x="3.5" y="2.5" width="17" height="7" rx="1" ry="1" />
      <line x1="1.5" y1="23.5" x2="1.5" y2="16.5" />
      <circle cx="1.5" cy="15.5" r="1" />
      <line x1="12" y1="23.5" x2="12" y2="16.5" />
      <circle cx="12" cy="15.5" r="1" />
      <line x1="22.5" y1="23.5" x2="22.5" y2="16.5" />
      <circle cx="22.5" cy="15.5" r="1" />
      <path d="M7.5,9.5s1.3-3.243,2.919-3.243S13.338,8.2,13.338,8.2l1.955-3.352a1.088,1.088,0,0,1,1.913.062L19.447,9.4" />
      <path d="M6.75,4.5A.25.25,0,0,1,7,4.75" />
      <path d="M6.5,4.75a.25.25,0,0,1,.25-.25" />
      <path d="M6.75,5a.25.25,0,0,1-.25-.25" />
      <path d="M7,4.75A.25.25,0,0,1,6.75,5" />
      <path d="M22.5,16.5c0,2.209-2.351,4-5.25,4S12,18.709,12,16.5c0,2.209-2.351,4-5.25,4s-5.25-1.791-5.25-4" />
    </StreamlineSvg>
  )),

  CatEducation: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12,14.413c.881,0,.294.587,2.055.587s3.521-3.521,3.521-5.869-1.468-4.109-3.228-4.109-1.761.586-2.348.586c-.5,0-.979-.437-1.935-.57a.515.515,0,0,0-.58.484c-.171,2.945-1.789,3.5-2.6,3.6a.485.485,0,0,0-.435.519C6.67,11.955,8.308,15,9.945,15,11.707,15,11.119,14.413,12,14.413Z" />
      <path d="M12,5.608V4.174A1.177,1.177,0,0,0,10.826,3H9.652" />
      <path d="M13.358,3.642A2.642,2.642,0,0,0,16,1,2.643,2.643,0,0,0,13.358,3.642Z" />
      <path d="M.5,23h5a2,2,0,0,0,2-2V19a2,2,0,0,0-2-2H.5" />
      <path d="M5.5,23H22c1.1,0,1.5-.895,1.5-2V19c0-1.1-.4-2-1.5-2H5.5" />
      <line x1="19.5" y1="17" x2="19.5" y2="23" />
      <line x1="17.5" y1="17" x2="17.5" y2="23" />
      <line x1="13.5" y1="17" x2="13.5" y2="23" />
      <line x1="11.5" y1="17" x2="11.5" y2="23" />
      <line x1="5.5" y1="21" x2="1.5" y2="21" />
      <line x1="5.5" y1="19" x2="1.5" y2="19" />
    </StreamlineSvg>
  )),

  CatEnvironment: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="14.528" width="4" height="8" rx="0.5" ry="0.5" />
      <path d="M4.5,21.028c10.729,3.577,6.772,3.614,19-2.5a2.763,2.763,0,0,0-3-1L16.066,19" />
      <path d="M4.5,15.528h3a6.59,6.59,0,0,1,4.5,2h3a1.016,1.016,0,1,1,0,2H9.5" />
      <path d="M6.852,12.124a8,8,0,1,1,11.24,3.281" />
      <path d="M19.914,3.141a3.975,3.975,0,0,1-1.74,2.606c-1.391-.348-2.782.694-2.435.694s.7,1.392.7,1.392c.348.348,1.044,0,1.044,0,.869.869-1.392,2.436-1.392,2.783s.871.522.348,1.044a2.983,2.983,0,0,1-1.043.7c0,1.739-1.74,1.739-2.087,1.739s-1.044-1.392-1.044-1.739.349-.7.349-1.043-.7-1.043-.7-1.043c0-1.392-1.043-1.044-2.088-1.044S8.783,7.833,8.783,7.833s0-2.434,1.74-2.781,1.738.347,1.738.347c.7.7,2.087,0,2.783,0,0,0,.348-2.435-.348-2.087s-1.319.078-1.391-.7C13.2,1.528,16.34.877,16.34.877" />
    </StreamlineSvg>
  )),

  CatHealth: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M15.256,9.453c1.95.457,3.262,1.314,3.262,2.3,0,1.507-1.119,2.157-2.6,2.437A22.365,22.365,0,0,1,12,14.4a13.408,13.408,0,0,0-3.224.3c-1.249.323-2.072.956-2.072,2.138,0,2.037,2.37,2.445,5.3,2.445,0,0,3.666,0,2.443,3.259" />
      <path d="M17.051,15.857a1.931,1.931,0,0,1,.244.981c0,2.037-2.371,2.445-5.3,2.445,0,0-3.666,0-2.444,3.259" />
      <path d="M8.743,9.453c-1.95.457-3.261,1.314-3.261,2.3a2.1,2.1,0,0,0,1.211,1.987" />
      <path d="M10.706,12.5C10.321,7.361,10,2.939,10,2.5a2,2,0,0,1,4,0c0,.442-.321,4.864-.706,10.006" />
      <path d="M12.993,16.5c-.173,2.288-.346,4.555-.5,6.54a.5.5,0,0,1-.991,0c-.152-1.985-.324-4.252-.5-6.54" />
      <path d="M10.029,3.089C7.391-.1,3.444,3.6.868,4.351a.51.51,0,0,0-.348.34.49.49,0,0,0,.107.467C2.134,6.827,6.261,8.171,10.32,7.28" />
      <path d="M13.971,3.089C16.609-.1,20.556,3.6,23.132,4.351a.51.51,0,0,1,.348.34.49.49,0,0,1-.107.467C21.866,6.827,17.739,8.171,13.68,7.28" />
    </StreamlineSvg>
  )),

  CatHumanServices: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="15.5" width="4" height="7" rx="0.5" ry="0.5" />
      <path d="M4.5,21c10.528,3.51,7.3,3.611,18.723-2.164a.511.511,0,0,0,.084-.854A2.483,2.483,0,0,0,20.89,17.5l-4.955,1.644" />
      <path d="M9.5,20.5H15a1,1,0,0,0,0-2H12a6.59,6.59,0,0,0-4.5-2h-3" />
      <rect x="19.5" y="1.5" width="4" height="7" rx="0.5" ry="0.5" />
      <path d="M19.5,3C8.972-.51,12.2-.611.777,5.164a.511.511,0,0,0-.084.854A2.483,2.483,0,0,0,3.11,6.5L8.065,4.856" />
      <path d="M14.5,3.5H9a1,1,0,0,0,0,2h3a6.59,6.59,0,0,0,4.5,2h3" />
      <path d="M12,10.205c-.856-1.34-3.427-.861-3.427,1.531.045,1.826,2.423,3.28,3.191,3.7a.489.489,0,0,0,.472,0c.768-.423,3.146-1.877,3.191-3.7C15.427,9.344,12.857,8.865,12,10.205Z" />
    </StreamlineSvg>
  )),

  CatInternational: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="1.897" y1="17.5" x2="9.5" y2="17.501" />
      <line x1="2.51" y1="5.5" x2="21.495" y2="5.5" />
      <line x1="10.5" y1="11.501" x2="0.509" y2="11.5" />
      <path d="M11.377,23.484A11.5,11.5,0,1,1,23.412,10.5" />
      <path d="M11.268.526c-6,6.5-5.891,14.958.109,22.958" />
      <path d="M12.729.526A15.837,15.837,0,0,1,17.153,10.5" />
      <path d="M17.5,23.5l-5.114-5.335a3.025,3.025,0,0,1-.567-3.492h0a3.026,3.026,0,0,1,4.846-.786l.835.835.835-.835a3.026,3.026,0,0,1,4.846.786h0a3.025,3.025,0,0,1-.567,3.492Z" />
    </StreamlineSvg>
  )),

  CatPublicPolicy: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="8.032" y1="17" x2="15.508" y2="17" />
      <line x1="18.508" y1="21" x2="14.347" y2="21" />
      <line x1="12.032" y1="5" x2="14.032" y2="5" />
      <line x1="12.282" y1="11" x2="18.032" y2="11" />
      <line x1="12.282" y1="8" x2="18.032" y2="8" />
      <line x1="11.532" y1="14" x2="17.532" y2="14" />
      <line x1="10.057" y1="1" x2="22.532" y2="1" />
      <path d="M10.057,1c-1,0-1.025,1.5-1.025,1.5s.112,8.949-1,14.5h-4.5c-2.5,0,.5,6-3,6H12.508c3.5,0,.5-6,3-6s-.5,4,3,4,3-18.5,3-18.5S21.532,1,22.532,1c.586,0,.829,1.03.929,1.883A1,1,0,0,1,22.467,4h-.935" />
    </StreamlineSvg>
  )),

  CatReligion: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="11.251" cy="3.75" r="2.5" />
      <path d="M18.251,22.75h-7a1.5,1.5,0,0,1-.949-2.662l4.726-3.859-1.6-5.086L9.6,13.8a2.5,2.5,0,0,1-3.651-.92L4.481,10.551a1.5,1.5,0,0,1,2.539-1.6l1.307,2.075L13.4,7.518a1.5,1.5,0,0,1,2.284.784L18,15.672a2.5,2.5,0,0,1-.791,2.652l-1.747,1.427H18.25a1.5,1.5,0,1,1,0,3Z" />
      <line x1="15.751" y1="19.75" x2="14.251" y2="19.75" />
    </StreamlineSvg>
  )),

  CatOther: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12.359,21.671a.5.5,0,0,1-.718,0L2.2,11.949A5.669,5.669,0,0,1,1.112,5.342h0A5.84,5.84,0,0,1,10.4,3.854L12,5.313l1.6-1.459a5.839,5.839,0,0,1,9.287,1.488h0A5.671,5.671,0,0,1,21.8,11.949Z" />
    </StreamlineSvg>
  )),

  Check1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <polyline points="23.5 0.499 7 23.499 0.5 16.999" />
    </StreamlineSvg>
  )),

  CheckCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <polyline points="23.5 0.499 9 18.499 4 13.499" />
      <path d="M18.273,12.493A8.993,8.993,0,1,1,13.5,6.435" />
    </StreamlineSvg>
  )),

  CheckCircle1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="11.999" r="11.5" />
      <polyline points="18 6.999 11 16.499 6 12.499" />
    </StreamlineSvg>
  )),

  Cog1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19.922,7.213a1.874,1.874,0,0,0,1.065,2.571l1.265.45a1.875,1.875,0,0,1,0,3.534l-1.265.45a1.874,1.874,0,0,0-1.065,2.571L20.5,18A1.875,1.875,0,0,1,18,20.5l-1.213-.576a1.874,1.874,0,0,0-2.571,1.065l-.45,1.265a1.875,1.875,0,0,1-3.533,0l-.45-1.265a1.875,1.875,0,0,0-2.572-1.065L6,20.5A1.874,1.874,0,0,1,3.5,18l.576-1.213a1.874,1.874,0,0,0-1.065-2.571l-1.265-.45a1.875,1.875,0,0,1,0-3.534l1.265-.45A1.874,1.874,0,0,0,4.076,7.213L3.5,6A1.874,1.874,0,0,1,6,3.5l1.213.576A1.875,1.875,0,0,0,9.783,3.013l.45-1.265a1.875,1.875,0,0,1,3.533,0l.45,1.265a1.874,1.874,0,0,0,2.571,1.065L18,3.5A1.875,1.875,0,0,1,20.5,6Z" />
      <circle cx="11.999" cy="12.001" r="4.5" />
    </StreamlineSvg>
  )),

  CommonFileTextUpload: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="4.5" y1="8.5" x2="14" y2="8.5" />
      <line x1="4.5" y1="11.5" x2="10.5" y2="11.5" />
      <line x1="4.5" y1="5.5" x2="11.5" y2="5.5" />
      <line x1="4.5" y1="14.5" x2="8.5" y2="14.5" />
      <line x1="4.5" y1="17.5" x2="8.5" y2="17.5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="20.5" x2="17.5" y2="14.5" />
      <line x1="17.5" y1="14.5" x2="15.25" y2="16.75" />
      <line x1="17.5" y1="14.5" x2="19.75" y2="16.75" />
    </StreamlineSvg>
  )),

  CommonFileTextDownload: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="4.5" y1="8.5" x2="14" y2="8.5" />
      <line x1="4.5" y1="11.5" x2="10.5" y2="11.5" />
      <line x1="4.5" y1="5.5" x2="11.5" y2="5.5" />
      <line x1="4.5" y1="14.5" x2="8.5" y2="14.5" />
      <line x1="4.5" y1="17.5" x2="8.5" y2="17.5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="14.5" x2="17.5" y2="20.5" />
      <line x1="17.5" y1="20.5" x2="15.25" y2="18.25" />
      <line x1="17.5" y1="20.5" x2="19.75" y2="18.25" />
    </StreamlineSvg>
  )),

  ConcertRock: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12.49,7.5a1,1,0,0,0-1,1V12a1.5,1.5,0,0,0,3,0V8.5a1,1,0,0,0-1-1Z" />
      <path d="M15.49,7.5a1,1,0,0,0-1,1V12a1.5,1.5,0,1,0,3,0V8.5a1,1,0,0,0-1-1Z" />
      <path d="M18.49,19.5v-.526a1,1,0,0,1,.336-.747A4.954,4.954,0,0,0,20.49,14.5V5a1.5,1.5,0,0,0-3,0V9.5" />
      <path d="M11.49,8.5V2a1.5,1.5,0,0,0-3,0V12l-3-3.1a1.2,1.2,0,0,0-1.76,1.6s2.481,3.969,4.2,6.679A4.931,4.931,0,0,0,9.1,18.445a1,1,0,0,1,.391.795v.26" />
      <rect x="8.49" y="19.5" width="11" height="4" rx="1" ry="1" />
      <path d="M10.74,21.25a.25.25,0,0,1,.25.25" />
      <path d="M10.49,21.5a.25.25,0,0,1,.25-.25" />
      <path d="M10.74,21.75a.25.25,0,0,1-.25-.25" />
      <path d="M10.99,21.5a.25.25,0,0,1-.25.25" />
      <path d="M13.99,21.25a.25.25,0,0,1,.25.25" />
      <path d="M13.74,21.5a.25.25,0,0,1,.25-.25" />
      <path d="M13.99,21.75a.25.25,0,0,1-.25-.25" />
      <path d="M14.24,21.5a.25.25,0,0,1-.25.25" />
      <path d="M17.24,21.25a.25.25,0,0,1,.25.25" />
      <path d="M16.99,21.5a.25.25,0,0,1,.25-.25" />
      <path d="M17.24,21.75a.25.25,0,0,1-.25-.25" />
      <path d="M17.49,21.5a.25.25,0,0,1-.25.25" />
    </StreamlineSvg>
  )),

  ContentPenWrite3: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12.916,16.872a2.473,2.473,0,1,1,4.571,1.88c-.519,1.263-4.167,3.631-4.167,3.631S12.4,18.134,12.916,16.872Z" />
      <line x1="14.843" y1="18.684" x2="13.32" y2="22.383" />
      <rect x="13.698" y="9.747" width="7.996" height="4" transform="translate(0.098 23.64) rotate(-67.628)" />
      <path d="M19.651,1.739a2,2,0,0,0-2.61,1.089L14,10.225" />
      <path d="M19.651,1.74a2,2,0,1,1,3.7,1.521L21.067,8.81l-3.7-1.521Z" />
      <path d="M.5,23.174a4.392,4.392,0,0,1,2.933-1.1c1.758,0,4.864,2.847,7.7.5" />
    </StreamlineSvg>
  )),

  CreditCardAmex: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
      <path d="M4.5,14.5v-4a1,1,0,0,1,2,0v4" />
      <line x1="4.504" y1="12.5" x2="6.504" y2="12.5" />
      <polyline points="11.504 14.5 11.504 9.5 10.004 12 8.504 9.5 8.504 14.5" />
      <line x1="17.504" y1="9.5" x2="19.504" y2="14.5" />
      <line x1="19.504" y1="9.5" x2="17.504" y2="14.5" />
      <path d="M15.5,14.5h-1a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h1" />
      <line x1="13.504" y1="12.5" x2="15.504" y2="12.5" />
    </StreamlineSvg>
  )),

  CreditCardGeneric: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.504" y="4" width="23" height="16" rx="2" ry="2" />
      <line x1="0.504" y1="8" x2="23.504" y2="8" />
      <line x1="20.504" y1="12" x2="17.504" y2="12" />
      <line x1="11.504" y1="12" x2="3.504" y2="12" />
      <line x1="6.504" y1="15" x2="3.504" y2="15" />
    </StreamlineSvg>
  )),

  CreditCardMastercard: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
      <path d="M13.5,12a5,5,0,1,1-5-5A5,5,0,0,1,13.5,12Z" />
      <path d="M13.5,12a5,5,0,1,1-5-5A5,5,0,0,1,13.5,12Z" />
      <path d="M12.018,15.557A4.949,4.949,0,0,0,15.5,17a5,5,0,1,0,0-10,4.945,4.945,0,0,0-3.486,1.444" />
      <path d="M12.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M12.5,13.9a.1.1,0,0,0,0,.2.1.1,0,0,0,.092-.056.073.073,0,0,0,.008-.033V14a.1.1,0,0,0-.1-.1" />
      <path d="M12.5,9.9a.1.1,0,1,0,.1.1V9.989a.073.073,0,0,0-.008-.033A.1.1,0,0,0,12.5,9.9" />
      <path d="M6.5,8.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M10.5,8.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M10.5,10.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M10.5,12.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M10.5,14.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M6.5,10.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M6.5,12.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M6.5,14.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M8.5,9.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M8.5,7.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M8.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M8.5,13.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M8.5,15.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M4.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
      <path d="M4.5,13.9a.1.1,0,0,0-.1.1.118.118,0,0,0,.014.055.084.084,0,0,0,.075.045H4.5a.1.1,0,1,0,0-.2" />
      <path d="M4.5,9.9H4.493a.083.083,0,0,0-.075.045A.118.118,0,0,0,4.4,10a.1.1,0,1,0,.1-.1" />
    </StreamlineSvg>
  )),

  CreditCardVisa: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
      <path d="M4.957,10H6.238L6.6,13.6a.448.448,0,0,0,.85.148L9.3,9.891" />
      <line x1="11.113" y1="9.969" x2="10.301" y2="14.031" />
      <path d="M14.832,9.922s-1.375-.36-1.844.422.266,1.281.75,1.578,1.016.75.875,1.281-.734,1.313-2.3.766" />
      <path d="M16,14.031l1.88-3.789a.436.436,0,0,1,.825.158l.3,3.569" />
      <line x1="16.598" y1="12.828" x2="18.926" y2="12.828" />
    </StreamlineSvg>
  )),

  CurrencyDollar: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M16,3.533H11.712A3.829,3.829,0,0,0,8,7.473,3.97,3.97,0,0,0,10.054,11l3.9,2.072A3.974,3.974,0,0,1,16,16.593a3.829,3.829,0,0,1-3.708,3.94H8" />
      <line x1="11.504" y1="20.533" x2="11.504" y2="23.533" />
      <line x1="13.504" y1="0.467" x2="13.504" y2="3.533" />
    </StreamlineSvg>
  )),

  DataTransferHorizontal: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="0.5" y1="9.5" x2="14.5" y2="9.5" />
      <line x1="9.5" y1="14.5" x2="23.5" y2="14.5" />
      <polyline points="4.5 13.5 0.5 9.5 4.5 5.5" />
      <polyline points="19.5 18.5 23.5 14.5 19.5 10.5" />
    </StreamlineSvg>
  )),

  Delete: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="3.869" y1="20.131" x2="20.131" y2="3.869" />
    </StreamlineSvg>
  )),

  DesignToolsPenStation: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M17,12.5h1a3,3,0,0,1,0,6H17" />
      <path d="M17,21.5a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V11a.5.5,0,0,1,.5-.5h12a.5.5,0,0,1,.5.5Z" />
      <path d="M16.881.564c1.033,1.574-.5,2.437-.853,3.282a1.548,1.548,0,0,0,.885,2.129C19.813,7.2,20.677,1.482,16.881.564Z" />
      <line x1="14" y1="10.5" x2="16.609" y2="5.813" />
      <line x1="17.641" y1="6.156" x2="16" y2="10.5" />
      <polygon points="8 10.5 5 10.5 3.5 7.5 3 4.5 5.5 6 8 10.5" />
      <line x1="3.5" y1="7.5" x2="5.5" y2="6" />
      <path d="M13,1a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,9,1v9.5h4Z" />
      <line x1="13" y1="2.5" x2="11" y2="2.5" />
      <line x1="13" y1="4.5" x2="12" y2="4.5" />
      <line x1="13" y1="6.5" x2="11" y2="6.5" />
      <line x1="13" y1="8.5" x2="12" y2="8.5" />
    </StreamlineSvg>
  )),

  DiagramDown: React.memo((props) => (
    <StreamlineSvg {...props}>
      <polyline points="20.477 23.5 23.477 20.5 20.477 17.5" />
      <path d="M23.477,20.5c-.361.018-1.224,0-1.589,0A21.412,21.412,0,0,1,.523.5" />
    </StreamlineSvg>
  )),

  EcologyHumanMind: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M15.5,23.5V20.024c2,.01,3.113-.134,3.815-.836.9-.9.86-4.688.86-4.688s.877.032,1.522.006.661-.428.7-.937c.116-1.606-2.139-4.361-2.139-5.163C20.258,3.375,15.507.5,11.2.5a9.494,9.494,0,0,0-9.6,9.563c0,3.042.905,5.9,3.905,7.677V23.5" />
      <path d="M8.816,7.234c-1.083,1.275-1.655,2.95.926,4.076,1.181,2.636,2.977,1.661,4.172.253,1.08-1.272,1.557-4.47.864-6.544a.5.5,0,0,0-.752-.257C12.184,5.986,10.057,5.772,8.816,7.234Z" />
      <path d="M7.579,16.036a18.034,18.034,0,0,1,5.236-8.661" />
    </StreamlineSvg>
  )),

  EllipsisHorizontal: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="3" cy="12" r="2.5" />
      <circle cx="12" cy="12" r="2.5" />
      <circle cx="21" cy="12" r="2.5" />
    </StreamlineSvg>
  )),

  Envelope: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="4.5" width="23" height="15" rx="1.5" ry="1.5" />
      <polyline points="20.5 8.5 12 14 3.5 8.5" />
      <line x1="3.5" y1="16" x2="7" y2="14" />
      <line x1="20.5" y1="16" x2="17" y2="14" />
    </StreamlineSvg>
  )),

  EnvelopePigeon: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12.041,19.706a9.388,9.388,0,0,0,4.5,1.421c4.338.333,6-.669,6.632-1.373a.5.5,0,0,0-.182-.8,23.012,23.012,0,0,1-4.45-2.832c-.337-.254-.873-.682-1.339-1.053a.5.5,0,0,1,.1-.845c3.35-1.55,5.51-4.627,6.139-8.89a.5.5,0,0,0-.654-.547c-1.725.578-5.854,2.073-6.249,3.256-.371,1.114-1.294,3.3-1.745,4.358a.5.5,0,0,1-.763.2,11.761,11.761,0,0,0-4.492-2.479,2.8,2.8,0,0,0-3.495,2.822.5.5,0,0,1-.124.3,1.863,1.863,0,0,0-.506,1.271A.5.5,0,0,0,6.043,15l1.5-.375" />
      <polyline points="9.046 15.627 11.546 16.127 9.046 21.627 0.546 19.627 3.046 14.127 5.471 14.733" />
      <polyline points="3.046 14.127 5.546 18.127 11.546 16.127" />
      <path d="M8.8,12.127a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
      <path d="M12.546,11.548s-.5-4-.5-6c0-1.128,1.979-3.386,2.961-4.438a.5.5,0,0,1,.837.176,13.032,13.032,0,0,1,.7,4.262" />
      <line x1="17.309" y1="21.193" x2="18.546" y2="23.048" />
      <line x1="20.215" y1="21.051" x2="21.546" y2="23.048" />
    </StreamlineSvg>
  )),

  EscalatorPeople: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="4.5" cy="8.25" r="1.75" />
      <path d="M8,22.71a2.5,2.5,0,0,1-1.824.79H3a2.5,2.5,0,0,1,0-5H4.277a2,2,0,0,0,1.4-.571L17,8.29a2.5,2.5,0,0,1,1.824-.79H21a2.5,2.5,0,0,1,0,5h-.277a2,2,0,0,0-1.4.571Z" />
      <path d="M6,15V13a1.5,1.5,0,0,0-1.5-1.5h0A1.5,1.5,0,0,0,3,13v3.5" />
      <circle cx="9.5" cy="5.25" r="1.75" />
      <path d="M8,13.5V10A1.5,1.5,0,0,1,9.5,8.5h0A1.5,1.5,0,0,1,11,10v.5" />
      <circle cx="14.5" cy="2.25" r="1.75" />
      <path d="M15.914,6.5a1.507,1.507,0,0,0-1.414-1h0A1.5,1.5,0,0,0,13,7V9" />
    </StreamlineSvg>
  )),

  ExternalLink: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="9.5" y1="14.273" x2="23.5" y2="0.5" />
      <polyline points="23.5 8.371 23.5 0.5 15.5 0.5" />
      <path d="M12.75,6H1.375A.875.875,0,0,0,.5,6.875v15.75a.875.875,0,0,0,.875.875h15.75A.875.875,0,0,0,18,22.625V11.25" />
    </StreamlineSvg>
  )),

  // FamilyChildTeeter: React.memo((props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="2.5" cy="8.625" r="1.75" />
  //     <path d="M6,18.625a1,1,0,0,1-.979-.8l-.339-1.7H2.5a1,1,0,0,1-1-1v-2a1,1,0,0,1,2,0v1H4.681a2.007,2.007,0,0,1,1.961,1.606l.339,1.7A1,1,0,0,1,6.2,18.605.971.971,0,0,1,6,18.625Z" />
  //     <circle cx="20.5" cy="4.625" r="1.75" />
  //     <path d="M17,14.625a1,1,0,0,0,.979-.8l.339-1.7H20.5a1,1,0,0,0,1-1v-2a1,1,0,0,0-2,0v1H18.319a2.007,2.007,0,0,0-1.961,1.606l-.339,1.7a1,1,0,0,0,.784,1.177A.971.971,0,0,0,17,14.625Z" />
  //     <line x1="20.881" y1="13.691" x2="23.5" y2="13.125" />
  //     <line x1="9" y1="16.287" x2="13.982" y2="15.209" />
  //     <line x1="0.5" y1="18.125" x2="2.501" y2="17.692" />
  //     <line x1="12" y1="21.125" x2="12" y2="15.638" />
  //     <line x1="10" y1="21.125" x2="14" y2="21.125" />
  //   </StreamlineSvg>
  // )),

  FaceId10: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.831,17.435a34.3,34.3,0,0,1,3.25-1.293c.608-.226.509-1.815.239-2.111A4.49,4.49,0,0,1,9.151,10.55,2.934,2.934,0,0,1,12,7.335a2.934,2.934,0,0,1,2.849,3.215,4.49,4.49,0,0,1-1.169,3.481c-.27.3-.369,1.885.239,2.111a34.3,34.3,0,0,1,3.25,1.293" />
      <circle cx="12" cy="12" r="7.5" />
      <line x1="12" y1="0.5" x2="12" y2="1.5" />
      <line x1="16.401" y1="1.375" x2="16.018" y2="2.299" />
      <line x1="20.132" y1="3.868" x2="19.425" y2="4.575" />
      <line x1="22.625" y1="7.599" x2="21.701" y2="7.982" />
      <line x1="23.5" y1="12" x2="22.5" y2="12" />
      <line x1="22.625" y1="16.401" x2="21.701" y2="16.018" />
      <line x1="20.132" y1="20.132" x2="19.425" y2="19.425" />
      <line x1="16.401" y1="22.625" x2="16.018" y2="21.701" />
      <line x1="12" y1="23.5" x2="12" y2="22.5" />
      <line x1="7.599" y1="22.625" x2="7.982" y2="21.701" />
      <line x1="3.868" y1="20.132" x2="4.575" y2="19.425" />
      <line x1="1.375" y1="16.401" x2="2.299" y2="16.018" />
      <line x1="0.5" y1="12" x2="1.5" y2="12" />
      <line x1="1.375" y1="7.599" x2="2.299" y2="7.982" />
      <line x1="3.868" y1="3.868" x2="4.575" y2="4.575" />
      <line x1="7.599" y1="1.375" x2="7.982" y2="2.299" />
    </StreamlineSvg>
  )),

  FishBowl: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M1.751,4a.5.5,0,0,0-.466.318,11.5,11.5,0,1,0,21.43,0A.5.5,0,0,0,22.249,4Z" />
      <path d="M7.458,13c1.849,1.849,2.633,3,4.5,3,2.437,0,3-1.5,3-1.5s-.625-1.5-3-1.5c-1.867,0-2.651,1.151-4.5,3" />
      <line x1="0.511" y1="8" x2="23.489" y2="8" />
    </StreamlineSvg>
  )),

  FruitStrawberry: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12,23.5c5.625,0,13-14.994,4-16H8C-1,8.506,6.375,23.5,12,23.5Z" />
      <path d="M11,5.5a5,5,0,0,1-5-5A5,5,0,0,1,11,5.5Z" />
      <path d="M13,5.5a5,5,0,0,0,5-5A5,5,0,0,0,13,5.5Z" />
      <line x1="9.25" y1="15" x2="10.25" y2="16" />
      <line x1="13.75" y1="15" x2="14.75" y2="16" />
      <line x1="7" y1="11" x2="8" y2="12" />
      <line x1="11.5" y1="11" x2="12.5" y2="12" />
      <line x1="16" y1="11" x2="17" y2="12" />
      <line x1="11.5" y1="18.5" x2="12.5" y2="19.5" />
    </StreamlineSvg>
  )),

  GiftBox: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,10.5a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
      <path d="M21.5,22.5a1,1,0,0,1-1,1H3.5a1,1,0,0,1-1-1v-11h19Z" />
      <line x1="8.5" y1="7.5" x2="8.5" y2="23.5" />
      <line x1="15.5" y1="23.5" x2="15.5" y2="7.5" />
      <path d="M17.427,3.84C16.663,4.605,13,5.5,13,5.5s.9-3.663,1.66-4.427A1.957,1.957,0,1,1,17.427,3.84Z" />
      <path d="M6.573,3.84C7.337,4.605,11,5.5,11,5.5s-.9-3.663-1.66-4.427A1.957,1.957,0,0,0,6.573,3.84Z" />
    </StreamlineSvg>
  )),

  GiftBox1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M10.5,14h-4a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1h-4" />
      <path d="M13.5,14H17l-.434,6.071a1,1,0,0,1-1,.929H8.431a1,1,0,0,1-1-.929L7,14h3.5" />
      <line x1="10.5" y1="11" x2="10.5" y2="21" />
      <line x1="13.5" y1="21" x2="13.5" y2="11" />
      <path d="M8.5,9.688C9.1,10.292,12,11,12,11s-.709-2.9-1.312-3.5A1.547,1.547,0,0,0,8.5,9.688Z" />
      <path d="M15.5,9.688C14.9,10.292,12,11,12,11s.709-2.9,1.312-3.5A1.547,1.547,0,0,1,15.5,9.688Z" />
      <line x1="12" y1="4.5" x2="12" y2="3" />
      <line x1="19.071" y1="7.429" x2="20.132" y2="6.369" />
      <line x1="22" y1="14" x2="23.5" y2="14" />
      <line x1="4.929" y1="7.429" x2="3.868" y2="6.369" />
      <line x1="2" y1="14" x2="0.5" y2="14" />
    </StreamlineSvg>
  )),

  Hash: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="2.5" y1="8.498" x2="23.5" y2="8.498" />
      <line x1="0.5" y1="15.498" x2="21.5" y2="15.498" />
      <line x1="6.521" y1="23.498" x2="10.531" y2="0.498" />
      <line x1="13.521" y1="23.498" x2="17.531" y2="0.498" />
    </StreamlineSvg>
  )),

  HandExpand: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19,17.35a5.411,5.411,0,0,1,.636-2.544l2.142-4.022a1.5,1.5,0,0,0-2.555-1.57L16.5,13.5l1.617-8.821a1.5,1.5,0,0,0-2.932-.631L13.5,11.5V2a1.5,1.5,0,1,0-3,0V11.5L8.816,3.683a1.5,1.5,0,0,0-2.933.631L7.5,15.5,4.513,12.892a1.5,1.5,0,1,0-2.025,2.213l5.257,7A4.5,4.5,0,0,0,11,23.5h3.5C18.055,23.5,19,20.723,19,17.35Z" />
    </StreamlineSvg>
  )),

  HeavyEquipmentHook: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="13.5" y1="23.36" x2="0.5" y2="23.36" />
      <line x1="8.5" y1="23.36" x2="5.5" y2="23.36" />
      <path d="M2.68,23.36v-5a1.277,1.277,0,0,1,.374-.832L5.5,15.36h3l2.315,2.159a1.32,1.32,0,0,1,.365.841v5" />
      <path d="M8,5.36c.275,0,.724.022,1,.05L23.5,6.86v1a.5.5,0,0,1-.5.5H1a.5.5,0,0,1-.5-.5v-1L5.021,5.5A4.1,4.1,0,0,1,6,5.36Z" />
      <path d="M.5,6.86,6.767,1.041a1.714,1.714,0,0,1,1.659-.3L23.5,6.86" />
      <path d="M20.5,8.36v6.708a1,1,0,0,0,.6.917A1.5,1.5,0,1,1,19,17.36" />
      <line x1="5.5" y1="10.36" x2="8.5" y2="13.36" />
      <line x1="8.5" y1="18.36" x2="5.5" y2="21.36" />
      <line x1="12.5" y1="5.76" x2="12.5" y2="8.36" />
      <line x1="7.5" y1="0.682" x2="7.5" y2="5.36" />
      <line x1="8.5" y1="5.375" x2="8.5" y2="23.36" />
      <line x1="5.5" y1="23.36" x2="5.5" y2="5.403" />
    </StreamlineSvg>
  )),

  Hippo: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M18.216,11.74a9.631,9.631,0,0,0,.285-2.5c0-2.762-2.91-5-6.5-5s-6.5,2.238-6.5,5a9.408,9.408,0,0,0,.253,2.526C4.326,12.941,3,14.74,4,16.74c1.628,3.255,4.5,1.5,8,1.5s6.372,1.755,8-1.5C21,14.74,19.664,12.917,18.216,11.74Z" />
      <path d="M14.5,4.623s.156-2.451,2-1.529c2,1,0,2.539,0,2.539" />
      <path d="M9.39,4.659s-.156-2.487-2-1.565c-2,1,0,2.615,0,2.615" />
      <path d="M9.75,9.427a.25.25,0,0,1,.25.25" />
      <path d="M9.5,9.677a.25.25,0,0,1,.25-.25" />
      <path d="M9.75,9.927a.25.25,0,0,1-.25-.25" />
      <path d="M10,9.677a.25.25,0,0,1-.25.25" />
      <path d="M14.25,9.427a.25.25,0,0,1,.25.25" />
      <path d="M14,9.677a.25.25,0,0,1,.25-.25" />
      <path d="M14.25,9.927a.25.25,0,0,1-.25-.25" />
      <path d="M14.5,9.677a.25.25,0,0,1-.25.25" />
      <path d="M13,15.74s1-2,2-1" />
      <path d="M11,15.74s-1-2-2-1" />
      <path d="M7,18.712V19.99a1.147,1.147,0,0,0,1,1.25H9a1.147,1.147,0,0,0,1-1.25v-1.6" />
      <path d="M17,18.712V19.99a1.147,1.147,0,0,1-1,1.25H15a1.147,1.147,0,0,1-1-1.25v-1.6" />
      <path d="M17,18.876c1.534.458,2.5,1.123,2.5,1.863" />
      <path d="M4.5,20.739c0-.74.966-1.405,2.5-1.863" />
    </StreamlineSvg>
  )),

  HouseModern: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="0.5" y1="21.5" x2="23.5" y2="21.5" />
      <line x1="0.5" y1="13.5" x2="10.5" y2="9.5" />
      <line x1="2.5" y1="12.719" x2="2.5" y2="21.5" />
      <line x1="9" y1="2.5" x2="23.5" y2="11.5" />
      <line x1="21.5" y1="10.281" x2="21.5" y2="21.5" />
      <path d="M18.5,8.407V5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V9.641" />
      <path d="M19,21.5a.5.5,0,0,0,.5-.5V18a.5.5,0,0,0-.5-.5H13a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5" />
      <line x1="16.5" y1="17.5" x2="16.5" y2="21.5" />
      <circle cx="15.001" cy="11.5" r="1.5" />
      <path d="M8,21.5a.5.5,0,0,0,.5-.5V17a.5.5,0,0,0-.5-.5H5a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5" />
      <line x1="10.5" y1="3.453" x2="10.5" y2="21.5" />
    </StreamlineSvg>
  )),

  ImageFileAdd: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="6.5" cy="7.5" r="2" />
      <path d="M12.544,11.17l-.62-.992a.5.5,0,0,0-.848,0L8.437,14.4,7.362,12.678a.5.5,0,0,0-.848,0L3.5,17.5h5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V9.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="14.5" x2="17.5" y2="20.5" />
      <line x1="20.5" y1="17.5" x2="14.5" y2="17.5" />
    </StreamlineSvg>
  )),

  ImageFileUpload: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="6.5" cy="7.5" r="2" />
      <path d="M12.3,10.785l-.379-.607a.5.5,0,0,0-.848,0L8.438,14.4,7.362,12.678a.5.5,0,0,0-.848,0L3.5,17.5h5" />
      <path d="M9.5,23.5h-8a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="20.5" x2="17.5" y2="14.5" />
      <line x1="17.5" y1="14.5" x2="15.25" y2="16.75" />
      <line x1="17.5" y1="14.5" x2="19.75" y2="16.75" />
    </StreamlineSvg>
  )),

  InfoCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12.001" r="11" />
      <path d="M14.5,17.005H13a1,1,0,0,1-1-1v-6.5a.5.5,0,0,0-.5-.5H10" />
      <line x1="11.745" y1="6.504" x2="11.745" y2="6.504" />
      <path d="M11.745,6.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    </StreamlineSvg>
  )),

  JellyFishGroup: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.127,8.751A3.187,3.187,0,0,1,7.7,6.193l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.286-1.5,5.405-.206s3.134,3.5,2.268,4.91a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
      <line x1="9.4" y1="7.239" x2="7.832" y2="9.796" />
      <path d="M13.936,17.924A3.188,3.188,0,0,1,15.5,15.366l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.285-1.505,5.4-.205s3.134,3.5,2.268,4.909a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
      <line x1="17.209" y1="16.412" x2="15.641" y2="18.969" />
      <path d="M1.936,19.424A3.188,3.188,0,0,1,3.5,16.866l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.285-1.505,5.4-.205s3.134,3.5,2.268,4.909a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
      <line x1="5.209" y1="17.912" x2="3.641" y2="20.469" />
    </StreamlineSvg>
  )),

  // KidsBoy: React.memo((props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M11.404166666666667 7.427073750000001c1.8524583333333335 0 3.354166666666667 -1.5017083333333334 3.354166666666667 -3.354166666666667C14.758333333333335 2.2204583333333336 13.256625000000001 0.7187423333333334 11.404166666666667 0.7187423333333334S8.049990416666668 2.2204583333333336 8.049990416666668 4.072907083333334c0 1.8524583333333335 1.5017179166666668 3.354166666666667 3.35417625 3.354166666666667Z" />
  //     <path d="M13.225000000000001 10.014583333333333c0.38333333333333336 0 0.7666666666666667 -0.19166666666666668 1.0541666666666667 -0.38333333333333336l3.066666666666667 -3.066666666666667c0.38333333333333336 -0.38333333333333336 0.8625 -0.575 1.3416666666666666 -0.575 0.4791666666666667 0 0.9583333333333334 0.19166666666666668 1.3416666666666666 0.575 0.7666666666666667 0.7666666666666667 0.7666666666666667 1.9166666666666667 0 2.5875000000000004l-4.695833333333334 4.695833333333334v6.516666666666667c0 1.0541666666666667 -0.8625 1.9166666666666667 -1.9166666666666667 1.9166666666666667s-1.9166666666666667 -0.8625 -1.9166666666666667 -1.9166666666666667v-0.2875c0 1.0541666666666667 -0.8625 1.9166666666666667 -1.91667625 1.9166666666666667 -1.0541666666666667 0 -1.9166666666666667 -0.8625 -1.9166666666666667 -1.9166666666666667v-6.420833333333333L2.9708333333333337 9.15207375c-0.7666666666666667 -0.7666666666666667 -0.7666666666666667 -1.9166666666666667 0 -2.5875000000000004 0.38333333333333336 -0.38333333333333336 0.8624904166666667 -0.5750095833333334 1.3416570833333334 -0.5750095833333334 0.4791666666666667 0 0.9583333333333334 0.19167625 1.3416666666666666 0.5750095833333334L8.720833333333333 9.631250000000001c0.2875 0.2875 0.6708141666666667 0.38333333333333336 1.0541666666666667 0.38333333333333336l3.45 0Z" />
  //     <path d="M11.5 20.077083333333334v-3.066666666666667" />
  //   </StreamlineSvg>
  // )),

  LabTubes: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M.5,7V17a4,4,0,0,0,4,4h15a4,4,0,0,0,4-4V7" />
      <path d="M7.5,14.5a2.5,2.5,0,0,1-2.758,2.487A2.6,2.6,0,0,1,2.5,14.358V3.5A.5.5,0,0,1,3,3H7a.5.5,0,0,1,.5.5Z" />
      <path d="M14.5,14.5a2.5,2.5,0,0,1-2.758,2.487A2.6,2.6,0,0,1,9.5,14.358V3.5A.5.5,0,0,1,10,3h4a.5.5,0,0,1,.5.5Z" />
      <path d="M21.5,14.5a2.5,2.5,0,0,1-2.758,2.487A2.6,2.6,0,0,1,16.5,14.358V3.5A.5.5,0,0,1,17,3h4a.5.5,0,0,1,.5.5Z" />
      <line x1="0.5" y1="10" x2="2.5" y2="10" />
      <line x1="7.5" y1="10" x2="9.5" y2="10" />
      <line x1="14.5" y1="10" x2="16.5" y2="10" />
      <line x1="21.5" y1="10" x2="23.5" y2="10" />
      <line x1="2.5" y1="8" x2="7.5" y2="8" />
      <line x1="9.5" y1="8" x2="14.5" y2="8" />
      <line x1="16.5" y1="8" x2="21.5" y2="8" />
      <path d="M4.5,9.75a.25.25,0,0,1,.25.25" />
      <path d="M4.25,10a.25.25,0,0,1,.25-.25" />
      <path d="M4.5,10.25A.25.25,0,0,1,4.25,10" />
      <path d="M4.75,10a.25.25,0,0,1-.25.25" />
      <path d="M5.5,13.75a.25.25,0,0,1,.25.25" />
      <path d="M5.25,14a.25.25,0,0,1,.25-.25" />
      <path d="M5.5,14.25A.25.25,0,0,1,5.25,14" />
      <path d="M5.75,14a.25.25,0,0,1-.25.25" />
      <path d="M11.5,9.75a.25.25,0,0,1,.25.25" />
      <path d="M11.25,10a.25.25,0,0,1,.25-.25" />
      <path d="M11.5,10.25a.25.25,0,0,1-.25-.25" />
      <path d="M11.75,10a.25.25,0,0,1-.25.25" />
      <path d="M19.5,10.25a.25.25,0,0,1,.25.25" />
      <path d="M19.25,10.5a.25.25,0,0,1,.25-.25" />
      <path d="M19.5,10.75a.25.25,0,0,1-.25-.25" />
      <path d="M19.75,10.5a.25.25,0,0,1-.25.25" />
      <path d="M18.5,12.75a.25.25,0,0,1,.25.25" />
      <path d="M18.25,13a.25.25,0,0,1,.25-.25" />
      <path d="M18.5,13.25a.25.25,0,0,1-.25-.25" />
      <path d="M18.75,13a.25.25,0,0,1-.25.25" />
      <path d="M12.5,12.75a.25.25,0,0,1,.25.25" />
      <path d="M12.25,13a.25.25,0,0,1,.25-.25" />
      <path d="M12.5,13.25a.25.25,0,0,1-.25-.25" />
      <path d="M12.75,13a.25.25,0,0,1-.25.25" />
    </StreamlineSvg>
  )),

  LayoutDashboard: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="16.5" width="10" height="7" rx="1" ry="1" />
      <rect x="13.5" y="10.5" width="10" height="13" rx="1" ry="1" transform="translate(37 34) rotate(180)" />
      <rect x="13.5" y="0.5" width="10" height="7" rx="1" ry="1" transform="translate(37 8) rotate(180)" />
      <rect x="0.5" y="0.5" width="10" height="13" rx="1" ry="1" />
    </StreamlineSvg>
  )),

  LayoutFeed: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="15.5" width="6" height="6" rx="1" />
      <rect x="8.5" y="10.5" width="6" height="11" rx="1" />
      <rect x="8.5" y="2.5" width="6" height="6" rx="1" />
      <rect x="0.5" y="2.5" width="6" height="11" rx="1" />
      <rect x="17.5" y="12.5" width="6" height="9" rx="1" />
      <rect x="17.5" y="2.5" width="6" height="8" rx="1" />
    </StreamlineSvg>
  )),

  Loading: React.memo((props) => (
    <StreamlineSvg {...props} className={`${props.className || ''} spin`}>
      <path d="M11,2a1,1,0,1,1,2,0V5a1,1,0,1,1-2,0Z" />
      <path d="M4.382,5.753A1,1,0,0,1,5.8,4.338L7.918,6.459A1,1,0,1,1,6.505,7.874Z" />
      <path d="M1,12a1,1,0,0,1,1-1H5a1,1,0,0,1,0,2H2A1,1,0,0,1,1,12Z" />
      <path d="M4.129,19.852a1,1,0,0,1,0-1.415l2.12-2.122a1,1,0,1,1,1.415,1.414L5.543,19.851A1,1,0,0,1,4.129,19.852Z" />
      <path d="M11,19a1,1,0,0,1,2,0v3a1,1,0,0,1-2,0Z" />
      <path d="M16.4,17.774a1,1,0,0,1,1.416-1.416l2.122,2.12A1,1,0,0,1,18.527,19.9Z" />
      <path d="M18,12a1,1,0,0,1,1-1h3a1,1,0,0,1,0,2H19A1,1,0,0,1,18,12Z" />
      <path d="M16.218,7.738a1,1,0,0,1,0-1.416L18.337,4.2a1,1,0,0,1,1.416,1.416L17.632,7.738A1,1,0,0,1,16.218,7.738Z" />
    </StreamlineSvg>
  )),

  Lock1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="15" r="1" />
      <line x1="12" y1="16" x2="12" y2="19" />
      <rect x="3.5" y="9.5" width="17" height="14" rx="1" ry="1" />
      <path d="M6.5,6a5.5,5.5,0,0,1,11,0V9.5H6.5Z" />
    </StreamlineSvg>
  )),

  LogoFacebook: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12.5,23.5H1.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1v21a1,1,0,0,1-1,1h-6v-9h2.559a.5.5,0,0,0,.5-.438l.375-3a.5.5,0,0,0-.5-.562H16.5V9.185A1.687,1.687,0,0,1,18.186,7.5H20a.5.5,0,0,0,.5-.5V4a.5.5,0,0,0-.5-.5H18.186A5.692,5.692,0,0,0,12.5,9.185V10.5H10a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h2.5v9Z" />
    </StreamlineSvg>
  )),

  LogoInstagram: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="0.5" y="0.5" width="23" height="23" rx="6" ry="6" />
      <circle cx="12" cy="12" r="6" />
      <circle cx="19" cy="5" r="1.5" />
    </StreamlineSvg>
  )),

  LogoLinkedIn: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.5,22.5h-5V9.5h5Zm9-9a2,2,0,0,0-2,2v7h-5V9.5h5v1.485A6.307,6.307,0,0,1,17.49,9.49c2.962,0,5.01,2.2,5.01,6.355V22.5h-5v-7A2,2,0,0,0,15.5,13.5ZM6.5,5A2.5,2.5,0,1,1,4,2.5,2.5,2.5,0,0,1,6.5,5Z" />
    </StreamlineSvg>
  )),

  LogoMedium: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M7.5,6V21.5a.5.5,0,0,1-.724.447l-6-3.309A.5.5,0,0,1,.5,18.191V3.309a.5.5,0,0,1,.724-.448Z" />
      <line x1="16" y1="18" x2="7.5" y2="6" />
      <polyline points="23.444 6.089 21.625 8.999 15.747 18.404" />
      <path d="M7.5,14.248l15.282,7.7a.5.5,0,0,0,.718-.45V6.318a.5.5,0,0,0-.289-.453L16.384,2.679a.5.5,0,0,0-.624.169l-5.168,7.517" />
    </StreamlineSvg>
  )),

  // LogoMsTeams: React.memo((props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M2.95714286,17 L8.02857143,17 C8.46250945,17 8.81428571,16.6482237 8.81428571,16.2142857 L8.81428571,6.78571429 C8.81428571,6.35177627 8.46250945,6 8.02857143,6 L0.6,6" transform="translate(4.707143, 11.500000) scale(-1, 1) translate(-4.707143, -11.500000) " />
  //     <line x1="3.01628571" y1="9" x2="6.41628571" y2="9" />
  //     <line x1="4.63569973" y1="9.00289466" x2="4.7928717" y2="13.5037116" transform="translate(4.714286, 11.253303) rotate(2.000000) translate(-4.714286, -11.253303) " />
  //     <path d="M15.8,22 L18.3,22 L18.3,15.5 C18.3,12.7385763 16.0614237,10.5 13.3,10.5 C10.5385763,10.5 8.3,12.7385763 8.3,15.5 L8.3,22 L10.8,22 L15.8,22 Z" transform="translate(13.300000, 16.250000) scale(1, -1) translate(-13.300000, -16.250000) " />
  //     <path d="M19.9950935,18.5 L23.4095238,18.5 L23.4095238,14.3095238 C23.4095238,12.2055819 21.7039419,10.5 19.6,10.5" transform="translate(21.504762, 14.500000) scale(1, -1) translate(-21.504762, -14.500000) " />
  //     <circle cx="13.3" cy="5" r="3" />
  //     <circle cx="20.2" cy="6" r="2" />
  //   </StreamlineSvg>
  // )),

  LogoSlack: React.memo(({color, ...props}) => (
    <StreamlineSvg className={`slack ${color ? 'color' : ''}`} {...props}>
      <path d="M8.85607992,1 C7.67162821,1.00087374 6.71303647,1.96024465 6.7139113,3.14242027 C6.71303647,4.32459589 7.67250364,5.2839668 8.85695535,5.28484054 L10.9999994,5.28484054 L10.9999994,3.14329401 C11.0008742,1.96111839 10.0414071,1.00174749 8.85607992,1 C8.85695535,1 8.85695535,1 8.85607992,1 L8.85607992,1 Z M8.85607992,6.71428571 L3.14304823,6.71428571 C1.95859652,6.71515946 0.999129352,7.67453036 1.00000239,8.85670599 C0.998253926,10.0388816 1.95772109,10.9982525 3.14217281,11 L8.85607992,11 C10.0405316,10.9991263 11.0000024,10.0397554 10.999124,8.85757973 C11.0000024,7.67453036 10.0405316,6.71515946 8.85607992,6.71428571 Z" />
      <path d="M22.9999994,8.85670599 C23.0008743,7.67453036 22.0413231,6.71515946 20.8567677,6.71428571 C19.6722123,6.71515946 18.7126611,7.67453036 18.713536,8.85670599 L18.713536,11 L20.8567677,11 C22.0413231,10.9991263 23.0008743,10.0397554 22.9999994,8.85670599 Z M17.285592,8.85670599 L17.285592,3.14242027 C17.2864669,1.96111839 16.3277913,1.00174749 15.1432359,1 C13.9586804,1.00087374 12.9991293,1.96024465 13.0000024,3.14242027 L13.0000024,8.85670599 C12.9982538,10.0388816 13.9578049,10.9982525 15.1423604,11 C16.3269158,10.9991263 17.2864669,10.0397554 17.285592,8.85670599 Z" />
      <path d="M15.1430447,23 C16.3274964,22.9991263 17.2869635,22.0398392 17.2860887,20.8577669 C17.2869635,19.6756946 16.3274964,18.7164075 15.1430447,18.7155338 L13.0000006,18.7155338 L13.0000006,20.8577669 C12.9991258,22.0389656 13.9585929,22.9982527 15.1430447,23 Z M15.1430447,17.2853399 L20.8569518,17.2853399 C22.0414035,17.2844662 23.000876,16.3251791 22.9999976,15.1431068 C23.0017461,13.9610344 22.0422789,13.0017473 20.8578272,13 L15.1439201,13 C13.9594684,13.0008737 13.0000012,13.9601608 13.000876,15.1422331 C13.0000012,16.3251791 13.9585929,17.2844662 15.1430447,17.2853399 L15.1430447,17.2853399 Z" />
      <path d="M1.0000006,15.1424203 C0.999125694,16.3245959 1.95867686,17.2839668 3.14323227,17.2848405 C4.32778769,17.2839668 5.28733886,16.3245959 5.28646395,15.1424203 L5.28646395,13 L3.14323227,13 C1.95867686,13.0008737 0.999125694,13.9602446 1.0000006,15.1424203 Z M6.71440618,15.1424203 L6.71440618,20.856706 C6.71265756,22.0388816 7.67220873,22.9982525 8.85676414,23 C10.0413196,22.9991263 11.0008707,22.0397554 10.9999976,20.8575797 L10.9999976,15.1441678 C11.0017462,13.9619921 10.0421951,13.0026212 8.85763965,13.0008737 C7.67220873,13.0008737 6.71353307,13.9602446 6.71440618,15.1424203 C6.71440618,15.1424203 6.71440618,15.143294 6.71440618,15.1424203 L6.71440618,15.1424203 Z" />
    </StreamlineSvg>
  )),

  LogoTwitter: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23,6.628l-2-.5,1-2-2.464.7A4.48,4.48,0,0,0,12,8.128v1c-3.539.73-6.634-1.2-9.5-4.5q-.75,4,1.5,6l-3-.5c.405,2.069,1.362,3.7,4,4l-2.5,1c1,2,2.566,2.31,5,2.5a10.748,10.748,0,0,1-6.5,2c12.755,5.669,20-2.664,20-10V8.3Z" />
    </StreamlineSvg>
  )),

  Logout2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="23.5" y1="12" x2="11.5" y2="12" />
      <polyline points="19.5 16 23.5 12 19.5 8" />
      <path d="M9.5,2.5H17a.5.5,0,0,1,.5.5V5.5" />
      <path d="M17.5,18.5V21a.5.5,0,0,1-.5.5H9.5" />
      <path d="M.5,21.223a.5.5,0,0,0,.392.488l8,1.777A.5.5,0,0,0,9.5,23V1A.5.5,0,0,0,8.892.512l-8,1.778A.5.5,0,0,0,.5,2.778Z" />
      <circle cx="6" cy="12" r="1.5" />
    </StreamlineSvg>
  )),

  LogoYoutube: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,6.939A3.438,3.438,0,0,0,20.062,3.5H3.937A3.438,3.438,0,0,0,.5,6.939V16.06A3.438,3.438,0,0,0,3.937,19.5H20.062A3.438,3.438,0,0,0,23.5,16.06Zm-14,8.5V6.779l6.769,4.331Z" />
    </StreamlineSvg>
  )),

  LoveHeartHandsHold: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M16.442,8.08A4.354,4.354,0,0,0,12,11.108,4.354,4.354,0,0,0,7.558,8.08" />
      <path d="M3.9,2.5,1.486,5.517A4.5,4.5,0,0,0,.5,8.329V13.5A1.5,1.5,0,0,0,2,15H2a1.5,1.5,0,0,0,1.5-1.5V9.625" />
      <path d="M8,2.5V6.136a4.493,4.493,0,0,1-.757,2.5L6.1,10.346a1.4,1.4,0,0,1-1.977.445h0A1.4,1.4,0,0,1,3.648,9L5,6.75" />
      <rect x="2.5" y="0.5" width="6" height="2" rx="0.5" ry="0.5" />
      <path d="M20.1,2.5l2.414,3.017A4.5,4.5,0,0,1,23.5,8.329V13.5A1.5,1.5,0,0,1,22,15h0a1.5,1.5,0,0,1-1.5-1.5V9.625" />
      <path d="M16,2.5V6.136a4.493,4.493,0,0,0,.757,2.5L17.9,10.346a1.4,1.4,0,0,0,1.977.445h0A1.4,1.4,0,0,0,20.352,9L19,6.75" />
      <rect x="15.5" y="0.5" width="6" height="2" rx="0.5" ry="0.5" transform="translate(37 3) rotate(-180)" />
      <path d="M19.547,16.125c-1.911,3.31-6.124,6.466-7.26,7.281a.485.485,0,0,1-.574,0c-1.136-.815-5.349-3.971-7.26-7.281" />
    </StreamlineSvg>
  )),

  LoveHeartHold: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M4.036,11.129A5.97,5.97,0,0,0,3,14.5v4.046c0,1.513.672,2.23,2,2.954v2" />
      <path d="M16,17.5,16,18.693c-.043,1.413-.713,2.106-2,2.807v2" />
      <path d="M6.28,13.346,2.8,9.909C-2.42,5.041,4.866-2.795,10,1.994l2,1.864,2-1.864c5.136-4.789,12.422,3.047,7.2,7.915l-8.849,8.738a.5.5,0,0,1-.7,0l-.666-.658c.1-1,.483-2.789-1.338-3.753l1.19-2.248a1.7,1.7,0,1,0-3-1.585L5,15.765" />
    </StreamlineSvg>
  )),

  LoveItHandGive: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="1" y1="23" x2="1" y2="13" />
      <path d="M1,15H8a3,3,0,0,1,3,3h6a3,3,0,0,1,3,3H1Z" />
      <line x1="11" y1="18" x2="8" y2="18" />
      <path d="M17.347,11.139a.477.477,0,0,1-.692,0L11.887,6.165a3.027,3.027,0,0,1-.567-3.493h0a3.026,3.026,0,0,1,4.846-.786L17,2.722l.835-.835a3.024,3.024,0,0,1,4.844.786h0a3.027,3.027,0,0,1-.567,3.493Z" />
    </StreamlineSvg>
  )),

  LoveItRibbon1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M11.372,13.672a1,1,0,0,0,1.258,0c1.238-1,3.87-3.353,3.87-5.208,0-2.8-3.682-3.681-4.5-.279-.818-3.4-4.5-2.517-4.5.279C7.5,10.318,10.135,12.669,11.372,13.672Z" />
      <path d="M4.148,14.034.56,20.181c-.138.238-.028.395.243.349l3.358-.569,1.164,3.2c.094.258.286.277.427.04l3.065-5.148" />
      <path d="M19.852,14.034l3.588,6.147c.138.238.028.395-.243.349l-3.358-.569-1.164,3.2c-.094.258-.286.277-.427.04l-3.065-5.148" />
      <circle cx="12" cy="9.632" r="9" />
    </StreamlineSvg>
  )),

  M: React.memo((props) => (
    <StreamlineSvg {...props}>
      <polygon points="20.7121212 17.953125 23.5 20.3320312 23.5 21 13.8369286 21 13.8369286 20.3320312 16.6366204 17.953125 16.6366204 7.46484375 11.3916281 20.765625 10.5174628 20.765625 5.1779661 8.30859375 5.1779661 17.7890625 8.15485362 20.34375 8.21391885 21 0.5 21 0.5 20.3554688 3.5241397 17.7890625 3.5241397 6.12890625 0.653569594 3.6796875 0.653569594 3 7.84771443 3 12.2539805 14.0742188 16.6484335 3 23.488187 3 23.488187 3.66796875 20.7121212 6.046875" />
    </StreamlineSvg>
  )),

  MartialArtsKarate: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="4.5" cy="6" r="2.5" />
      <path d="M22.846,4.261a1.5,1.5,0,0,0-2.085.392l-5.1,7.223-4.1-3.065.479-.8a1.06,1.06,0,0,1,.9-.513A4.058,4.058,0,0,0,17,3.447V2.5a1.5,1.5,0,0,0-3,0v.946A1.055,1.055,0,0,1,12.946,4.5,4.075,4.075,0,0,0,9.469,6.469L6.8,10.941A4.5,4.5,0,0,0,8.164,17l.62.413a1.5,1.5,0,1,0,1.664-2.5l-.62-.413a1.5,1.5,0,0,1-.454-2.021l.637-1.089L14.5,14.751V21.5a1.5,1.5,0,1,0,3,0V14.476l5.738-8.13A1.5,1.5,0,0,0,22.846,4.261Z" />
      <polyline points="5.879 3.914 2.506 7.5 0.5 7.5" />
      <line x1="2.5" y1="7.5" x2="2.025" y2="9.889" />
    </StreamlineSvg>
  )),

  // MedicalHospital: React.memo((props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M22.775,8.664a.5.5,0,0,0,.183-.683l-2-3.464a.5.5,0,0,0-.3-.233.5.5,0,0,0-.379.05L14.5,7.669V1A.5.5,0,0,0,14,.5H10a.5.5,0,0,0-.5.5V7.668L3.725,4.334a.5.5,0,0,0-.683.183l-2,3.464a.5.5,0,0,0,.183.683L7,12,1.225,15.334a.5.5,0,0,0-.183.683l2,3.464a.5.5,0,0,0,.683.183L9.5,16.329V23a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5V16.329l5.776,3.335a.5.5,0,0,0,.379.05.5.5,0,0,0,.3-.233l2-3.464a.5.5,0,0,0-.183-.683L17,12Z" />
  //   </StreamlineSvg>
  // )),

  Megaphone1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="0.5" y1="8.999" x2="0.5" y2="13.999" />
      <line x1="23.5" y1="1.999" x2="23.5" y2="20.999" />
      <line x1="0.5" y1="9.999" x2="23.5" y2="2.999" />
      <line x1="0.5" y1="12.999" x2="23.5" y2="19.999" />
      <path d="M5.5,14.521V17.1a3.5,3.5,0,1,0,7-.032v-.413" />
    </StreamlineSvg>
  )),

  MessageBubbleEmpty: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,10A7.522,7.522,0,0,1,16,17.5H13.5l-4,4v-4H8a7.5,7.5,0,0,1,0-15h8A7.522,7.522,0,0,1,23.5,10Z" />
    </StreamlineSvg>
  )),

  MessageBubbleInfo: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.522,17.5a1,1,0,0,1-1,1h-11l-4,4v-4h-6a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
      <path d="M12.522,14.5v-6a1,1,0,0,0-1-1h-1" />
      <line x1="10.522" y1="14.5" x2="14.522" y2="14.5" />
      <path d="M12.272,5a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    </StreamlineSvg>
  )),

  MessageBubbleQuestion: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,17.5a1,1,0,0,1-1,1h-11l-4,4v-4h-6a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
      <path d="M9.5,7.5a3,3,0,1,1,4.2,2.75,2,2,0,0,0-1.2,1.834V12.5" />
      <path d="M12.5,14.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    </StreamlineSvg>
  )),

  MicrophonePodcast2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M18,12.5a6,6,0,0,1-12,0v-6a6,6,0,0,1,12,0Z" />
      <path d="M4,10.5v2a7.929,7.929,0,0,0,8,8,7.93,7.93,0,0,0,8-8v-2" />
      <line x1="12" y1="20.5" x2="12" y2="23.5" />
      <line x1="12" y1="0.5" x2="12" y2="3.5" />
      <line x1="14.001" y1="0.842" x2="14" y2="3.5" />
      <line x1="10" y1="0.842" x2="10" y2="3.5" />
      <line x1="4" y1="12.5" x2="20" y2="12.5" />
      <line x1="6" y1="6.5" x2="10" y2="6.5" />
      <line x1="6" y1="8.5" x2="10" y2="8.5" />
      <line x1="18" y1="6.5" x2="14" y2="6.5" />
      <line x1="18" y1="8.5" x2="14" y2="8.5" />
      <line x1="6" y1="10.5" x2="10" y2="10.5" />
      <line x1="18" y1="10.5" x2="14" y2="10.5" />
    </StreamlineSvg>
  )),

  MonetizationTablet: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M21.254,23.5l-2.481-3.434L18.754,15.5c.042-1.778-2.081-3.363-4-5" />
      <path d="M16.273,17.5l-3.255-3.349A1.248,1.248,0,1,0,11.233,15.9l3.54,3.605V21c0,.961,1.56,2.5,1.56,2.5" />
      <path d="M8.5,17a.25.25,0,1,1-.25.25A.25.25,0,0,1,8.5,17" />
      <path d="M11.754,19.5h-8a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H13.773a1,1,0,0,1,1,1V15.956" />
      <line x1="2.754" y1="15.5" x2="8.754" y2="15.5" />
      <line x1="8.754" y1="10.5" x2="8.754" y2="11.5" />
      <line x1="8.754" y1="5.5" x2="8.754" y2="6.5" />
      <path d="M6.754,10.5h3a1,1,0,0,0,0-2h-2a1,1,0,0,1,0-2h3" />
    </StreamlineSvg>
  )),

  MoneyWalletOpen: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M1.3,1.758,14.594,5.077A4.052,4.052,0,0,1,17.5,8.805v12a2.272,2.272,0,0,1-2.92,2.312L3.424,20.493A3.985,3.985,0,0,1,.5,16.805v-13a3.009,3.009,0,0,1,3-3h17a3.008,3.008,0,0,1,3,3v11a3.008,3.008,0,0,1-3,3h-3" />
      <line x1="9.504" y1="3.805" x2="19.504" y2="3.805" />
      <circle cx="13.004" cy="13.805" r="2" />
      <line x1="17.504" y1="8.805" x2="19.504" y2="8.805" />
    </StreamlineSvg>
  )),

  MoodPeace: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19,15.25a1.5,1.5,0,0,1-3,0v-3a1.5,1.5,0,0,1,3,0Z" />
      <path d="M16,14.25a1.5,1.5,0,0,1-3,0v-3a1.5,1.5,0,0,1,3,0Z" />
      <path d="M10,11.25l.869-9.142a1.5,1.5,0,0,1,2.986.29L13,11.25" />
      <path d="M7.034,11.247,6.157,2.393a1.5,1.5,0,0,1,2.986-.286L10,11.25" />
      <path d="M11.5,19.25a4,4,0,0,0-4-4H11a2,2,0,0,0,0-4H7a2,2,0,0,0-2,2v5a3,3,0,0,0,3,3v2" />
      <path d="M18,16.665V17.75a3,3,0,0,1-3,3v2.5" />
    </StreamlineSvg>
  )),

  MultipleUsers3: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="12" y1="11.5" x2="12" y2="14" />
      <line x1="12" y1="14" x2="14.25" y2="16.25" />
      <line x1="12" y1="14" x2="9.75" y2="16.25" />
      <circle cx="12" cy="2.75" r="2.25" />
      <path d="M16.5,9.5a4.644,4.644,0,0,0-9,0Z" />
      <circle cx="5" cy="16.75" r="2.25" />
      <path d="M9.5,23.5a4.644,4.644,0,0,0-9,0Z" />
      <circle cx="19" cy="16.75" r="2.25" />
      <path d="M23.5,23.5a4.644,4.644,0,0,0-9,0Z" />
    </StreamlineSvg>
  )),

  NavigationMenu: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="21" y1="7" x2="3" y2="7" />
      <line x1="21" y1="12" x2="3" y2="12" />
      <line x1="21" y1="17" x2="3" y2="17" />
    </StreamlineSvg>
  )),

  OfficeEmployee: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M18.5,23H5.5L3.811,16.242A1,1,0,0,1,4.781,15H19.219a1,1,0,0,1,.97,1.242Z" />
      <line x1="1" y1="23" x2="23" y2="23" />
      <line x1="12" y1="12" x2="12" y2="13.5" />
      <path d="M13.167,4.5a5.805,5.805,0,0,0,2.286-.467,3.478,3.478,0,0,0-6.459-1.3A5.809,5.809,0,0,0,13.167,4.5Z" />
      <circle cx="12" cy="4.5" r="3.5" />
      <circle cx="12" cy="18.75" r="1.25" />
      <path d="M16.745,13a4.966,4.966,0,0,0-9.49,0" />
    </StreamlineSvg>
  )),

  OfficialBuilding3: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M1.5,23.462,2.386,20.8a.5.5,0,0,1,.474-.342H21.14a.5.5,0,0,1,.474.342l.886,2.658" />
      <rect x="3.5" y="9.462" width="17" height="11" />
      <line x1="23.5" y1="23.462" x2="0.5" y2="23.462" />
      <line x1="6.502" y1="9.462" x2="6.502" y2="20.462" />
      <line x1="9.502" y1="9.462" x2="9.502" y2="20.462" />
      <line x1="14.502" y1="9.462" x2="14.502" y2="20.462" />
      <line x1="17.502" y1="9.462" x2="17.502" y2="20.462" />
      <path d="M22.37,6.851a.5.5,0,0,0-.226-.592L12.248.6a.5.5,0,0,0-.5,0l-9.9,5.655a.5.5,0,0,0-.226.592L2.386,9.12a.5.5,0,0,0,.474.342H21.14a.5.5,0,0,0,.474-.342Z" />
      <line x1="22.333" y1="6.462" x2="1.662" y2="6.462" />
    </StreamlineSvg>
  )),

  PaginateFilterHeart: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,1.5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1v18a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1Z" />
      <path d="M3.5,4.5h-2a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h17a1,1,0,0,0,1-1v-2" />
      <path d="M13.5,16,8.387,10.662A3.027,3.027,0,0,1,7.82,7.169h0a3.027,3.027,0,0,1,4.846-.786l.834.836.835-.835a3.026,3.026,0,0,1,4.845.786h0a3.027,3.027,0,0,1-.567,3.493Z" />
    </StreamlineSvg>
  )),

  PartyConfetti: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M16.483,12.216a4.967,4.967,0,0,1,7.017,0" />
      <path d="M11.768,7.518a4.961,4.961,0,0,0,0-7.018" />
      <line x1="18.075" y1="8.249" x2="21.584" y2="7.081" />
      <line x1="15.736" y1="5.912" x2="16.906" y2="2.402" />
      <path d="M7.965,4.771a.25.25,0,0,1,.25.25" />
      <path d="M7.715,5.021a.25.25,0,0,1,.25-.25" />
      <path d="M7.965,5.271a.25.25,0,0,1-.25-.25" />
      <path d="M8.215,5.021a.25.25,0,0,1-.25.25" />
      <path d="M15.465,7.771a.25.25,0,0,1,.25.25" />
      <path d="M15.215,8.021a.25.25,0,0,1,.25-.25" />
      <path d="M15.465,8.271a.25.25,0,0,1-.25-.25" />
      <path d="M15.715,8.021a.25.25,0,0,1-.25.25" />
      <path d="M19.465,13.771a.25.25,0,0,1,.25.25" />
      <path d="M19.215,14.021a.25.25,0,0,1,.25-.25" />
      <path d="M19.465,14.271a.25.25,0,0,1-.25-.25" />
      <path d="M19.715,14.021a.25.25,0,0,1-.25.25" />
      <path d="M19.965,3.771a.25.25,0,0,1,.25.25" />
      <path d="M19.715,4.021a.25.25,0,0,1,.25-.25" />
      <path d="M19.965,4.271a.25.25,0,0,1-.25-.25" />
      <path d="M20.215,4.021a.25.25,0,0,1-.25.25" />
      <ellipse cx="11.502" cy="12.498" rx="2.5" ry="5.5" transform="translate(-5.469 11.794) rotate(-45)" />
      <path d="M15.145,16.575,2.484,23.341A1.348,1.348,0,0,1,.66,21.516L7.425,8.855" />
    </StreamlineSvg>
  )),

  PartyMusicDanceWoman: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="19.237" cy="5.023" r="1.575" />
      <polyline points="20.802 5.198 21.327 0.503 23.5 2.331" />
      <circle cx="19.547" cy="13.603" r="1.188" />
      <polyline points="20.703 13.328 19.879 9.862 21.887 10.605" />
      <circle cx="5" cy="2.994" r="2.5" />
      <path d="M13.234,15.018c.365-.116,1.2-.457,1.283-.816a1,1,0,0,0-.268-.945L9.589,9.138c.157-.036.308-.088.467-.115l3.24-.54A1.5,1.5,0,1,0,12.8,5.524l-3.24.54A10.247,10.247,0,0,0,4.006,8.907L.945,11.937a1.5,1.5,0,1,0,2.11,2.132l2.861-2.832L4.682,15.913a1,1,0,0,0,.335.914,2.1,2.1,0,0,0,1.408.278l.009,0L5.061,21.58a1.5,1.5,0,1,0,2.878.847l1.249-4.21.523-1.777.747.868a2.191,2.191,0,0,1,.476,1.894l-.4,1.976a1.5,1.5,0,1,0,2.928.651l.6-2.7c.036-.718.318-2.778-.743-4.01Z" />
    </StreamlineSvg>
  )),

  PasswordLock2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M10.75,10.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M6,10.75a.25.25,0,1,1-.25.25A.25.25,0,0,1,6,10.75" />
      <rect x="14.5" y="15.501" width="9" height="7.999" rx="1" ry="1" />
      <circle cx="19" cy="19.563" r="1.25" />
      <path d="M21.5,14a2.5,2.5,0,0,0-5,0v1.5h5Z" />
      <path d="M12.5,15.5H2.5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2v5" />
    </StreamlineSvg>
  )),

  PeaceSign: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.628724583333334 12.425941666666668 3.9770833333333337 3.4390270833333334c-0.2875 -0.8625 0.2875 -1.725 1.15 -2.0125 0.8625 -0.2875 1.725 0.2875 2.0125 1.15l1.8208333333333333 6.9958237500000005 1.8208333333333333 -7.570833333333334c0.19166666666666668 -0.8625 1.15 -1.4375009583333334 2.0125 -1.2458342916666667 0.8625 0.19166666666666668 1.4375 1.1500009583333335 1.2458333333333333 2.0125009583333333l-1.6978791666666668 7.046203333333334" />
      <path d="M13.943845833333334 15.2559c-0.9583333333333334 0 -1.725 -0.7666666666666667 -1.725 -1.725v-3.066666666666667c0 -0.9583045833333333 0.7666666666666667 -1.72497125 1.725 -1.72497125s1.725 0.7666666666666667 1.725 1.72497125v3.066666666666667c0 1.0541666666666667 -0.7666666666666667 1.725 -1.725 1.725Z" />
      <path d="M17.39375 14.585066666666668c-0.9583333333333334 0 -1.725 -0.7666666666666667 -1.725 -1.725v-1.4375c0 -0.9583333333333334 0.7666666666666667 -1.725 1.725 -1.725s1.725 0.7666666666666667 1.725 1.725v1.4375c0 0.9583333333333334 -0.7666666666666667 1.725 -1.725 1.725Z" />
      <path d="M19.11875 13.08125v3.45c0 3.1625 -2.5875000000000004 5.75 -5.75 5.75h-3.545833333333334c-1.05417625 0 -2.1083429166666665 -0.2875 -2.9708429166666668 -0.8625l-0.19166666666666668 -0.09583333333333334c-1.725 -1.0541666666666667 -2.779166666666667 -2.875 -2.779166666666667 -4.8875v-0.4791666666666667c0 -1.9166666666666667 1.5333333333333334 -3.45 3.45 -3.45H10.78125c0.7666666666666667 0 1.3416666666666666 0.575 1.3416666666666666 1.3416666666666666 0 0.4791666666666667 -0.2875 0.9583333333333334 -0.7666666666666667 1.2458333333333333l-3.35417625 1.4375" />
    </StreamlineSvg>
  )),

  Pencil: React.memo((props) => (
    <StreamlineSvg {...props}>
      <rect x="9.268" y="1.09" width="6" height="21.284" transform="translate(11.889 -5.238) rotate(45)" />
      <polygon points="2.621 17.136 0.5 23.5 6.864 21.379 2.621 17.136" />
      <path d="M21.914,6.328,17.672,2.086l.707-.707a3,3,0,0,1,4.242,4.242Z" />
    </StreamlineSvg>
  )),

  Pin: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12,.71a7.5,7.5,0,0,1,7.5,7.5c0,3.547-5.5,12.381-7.079,14.85a.5.5,0,0,1-.842,0C10,20.592,4.5,11.757,4.5,8.21A7.5,7.5,0,0,1,12,.71Z" />
      <circle cx="12" cy="8.21" r="3" />
    </StreamlineSvg>
  )),

  Pin1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.547,9.674l7.778,7.778a4.363,4.363,0,0,0,.9-4.435l5.965-5.964.177.176a1.25,1.25,0,0,0,1.768-1.767l-4.6-4.6A1.25,1.25,0,0,0,16.77,2.633l.177.177L10.982,8.775A4.366,4.366,0,0,0,6.547,9.674Z" />
      <line x1="10.436" y1="13.563" x2="0.5" y2="23.499" />
    </StreamlineSvg>
  )),

  PoliceMan1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M13.5,11.978a2.5,2.5,0,0,1-3,0" />
      <path d="M23,23.478V21.372a1.978,1.978,0,0,0-.77-1.579C20.9,18.774,17.73,16.978,12,16.978s-8.895,1.8-10.23,2.815A1.978,1.978,0,0,0,1,21.372v2.106" />
      <path d="M14,9.228a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M10,9.228a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M6,5.686V9.478a6,6,0,0,0,12,0V5.686" />
      <line x1="12" y1="16.978" x2="12" y2="23.478" />
      <path d="M19.228,4.8l.524-1.574a.5.5,0,0,0-.5-.653C16.7,2.75,14.727,1.614,12.2.56a.522.522,0,0,0-.4,0C9.273,1.614,7.3,2.75,4.747,2.568a.5.5,0,0,0-.5.653L4.772,4.8a1,1,0,0,0,.949.683H18.279A1,1,0,0,0,19.228,4.8Z" />
      <path d="M12,2.728a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M5.775,5.478A8.969,8.969,0,0,0,12,7.978a8.969,8.969,0,0,0,6.225-2.5Z" />
      <path d="M18,20.978v.43a2,2,0,0,1-.891,1.664l-.332.221a.5.5,0,0,1-.554,0l-.332-.221A2,2,0,0,1,15,21.408v-.43a.5.5,0,0,1,.5-.5h2A.5.5,0,0,1,18,20.978Z" />
    </StreamlineSvg>
  )),

  ProductsGiftGive: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M15.5,7.476h-4a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1h-4" />
      <path d="M18.5,7.476h4v6a1,1,0,0,1-1,1h-9a1,1,0,0,1-1-1v-6h4" />
      <line x1="15.5" y1="4.476" x2="15.5" y2="14.476" />
      <line x1="18.5" y1="14.476" x2="18.5" y2="4.476" />
      <path d="M13.5,3.164c.6.605,3.5,1.312,3.5,1.312s-.709-2.9-1.312-3.5A1.547,1.547,0,0,0,13.5,3.164Z" />
      <path d="M20.5,3.164c-.6.605-3.5,1.312-3.5,1.312s.709-2.9,1.312-3.5A1.547,1.547,0,0,1,20.5,3.164Z" />
      <line x1="0.5" y1="23.476" x2="0.5" y2="13.476" />
      <path d="M.5,15.476h7a3,3,0,0,1,3,3h6a3,3,0,0,1,3,3H.5Z" />
      <line x1="10.5" y1="18.476" x2="7.5" y2="18.476" />
    </StreamlineSvg>
  )),

  QuillCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="11" y1="12.5" x2="16" y2="12.5" />
      <path d="M9,14.5h5.3c-2.8,2.542-6.184,2.912-7.206,1.9-1.179-1.179.044-4.8,2.973-7.725A15.192,15.192,0,0,1,14.5,5.5V9" />
      <path d="M13,10.5h4.25c1.145-2,1.284-3.714.378-4.629L5.5,18" />
    </StreamlineSvg>
  )),

  RatingStar: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M11.533,1.571a.5.5,0,0,1,.936,0L15,8.747h7.148a.5.5,0,0,1,.325.88L16.5,14.579l2.5,7.51a.5.5,0,0,1-.77.561L12,18.079,5.767,22.65A.5.5,0,0,1,5,22.089l2.5-7.51L1.526,9.627a.5.5,0,0,1,.325-.88H9Z" />
    </StreamlineSvg>
  )),

  ReceiptDollar: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M4,22.293a.5.5,0,0,0,.854.353L6,21.5l1.646,1.646a.5.5,0,0,0,.708,0L10,21.5l1.646,1.646a.5.5,0,0,0,.708,0L14,21.5l1.646,1.646a.5.5,0,0,0,.708,0L18,21.5l1.146,1.146A.5.5,0,0,0,20,22.293V1.707a.5.5,0,0,0-.854-.353L18,2.5,16.354.854a.5.5,0,0,0-.708,0L14,2.5,12.354.854a.5.5,0,0,0-.708,0L10,2.5,8.354.854a.5.5,0,0,0-.708,0L6,2.5,4.854,1.354A.5.5,0,0,0,4,1.707Z" />
      <line x1="7" y1="6.5" x2="15" y2="6.5" />
      <line x1="7" y1="9.5" x2="11" y2="9.5" />
      <line x1="7" y1="12.5" x2="11" y2="12.5" />
      <line x1="7" y1="15.5" x2="10" y2="15.5" />
      <line x1="15" y1="17.414" x2="15" y2="18.5" />
      <line x1="16" y1="10.5" x2="16" y2="11.588" />
      <path d="M14,16a1.5,1.5,0,1,0,1.5-1.5A1.5,1.5,0,1,1,17,13" />
    </StreamlineSvg>
  )),

  ReceiptRegister: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19.968,18.5h.688a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1h-17a1,1,0,0,1-1-1v-3" />
      <line x1="9.656" y1="4.5" x2="9.656" y2="7.5" />
      <line x1="12.523" y1="17.866" x2="9.423" y2="14.767" />
      <path d="M9.656,7.643A12.256,12.256,0,0,1,19.968,18.5" />
      <circle cx="13.406" cy="18.75" r="1.25" />
      <line x1="2.656" y1="18.5" x2="8.656" y2="18.5" />
      <line x1="17.156" y1="18.5" x2="19.968" y2="18.5" />
      <path d="M9.656,4.5l1.277-2.553A1,1,0,0,0,10.038.5H3.656a1,1,0,0,0-1,1v18" />
      <circle cx="7.656" cy="13" r="2.5" />
      <line x1="2.656" y1="4.5" x2="9.656" y2="4.5" />
      <line x1="14.656" y1="7.529" x2="15.656" y2="8.029" />
      <line x1="17.261" y1="9.259" x2="18.11" y2="9.988" />
      <line x1="19.404" y1="11.61" x2="19.971" y2="12.574" />
      <line x1="20.83" y1="14.435" x2="21.171" y2="15.5" />
    </StreamlineSvg>
  )),

  ReceiptSlip1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19.5,22.475a.5.5,0,0,1-.825.38L16,20.563l-3.7,2.774a.5.5,0,0,1-.6,0L8,20.563,5.325,22.855a.5.5,0,0,1-.825-.38V1.563a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1Z" />
      <line x1="7" y1="5.563" x2="11" y2="5.563" />
      <line x1="7" y1="8.563" x2="12.5" y2="8.563" />
      <line x1="7" y1="11.563" x2="13" y2="11.563" />
      <line x1="7" y1="14.563" x2="11.5" y2="14.563" />
      <path d="M16.5,5.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
      <path d="M16.5,8.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
      <path d="M16.5,11.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
      <path d="M16.5,14.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
    </StreamlineSvg>
  )),

  Remove: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="23.5" y1="0.5" x2="0.5" y2="23.5" />
      <line x1="23.5" y1="23.5" x2="0.5" y2="0.5" />
    </StreamlineSvg>
  )),

  SafetyFloat: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="5" />
      <path d="M13.5,1.607A10.507,10.507,0,0,1,22.393,10.5" />
      <path d="M1.605,10.5a10.508,10.508,0,0,1,8.9-8.893" />
      <path d="M10.5,22.394A10.506,10.506,0,0,1,1.605,13.5" />
      <path d="M22.393,13.5A10.5,10.5,0,0,1,13.5,22.394" />
      <path d="M13.5,7.229V1.5a1,1,0,0,0-1-1h-1a1,1,0,0,0-1,1V7.229" />
      <path d="M10.5,16.771V22.5a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V16.771" />
      <path d="M7.229,10.5H1.5a1,1,0,0,0-1,1v1a1,1,0,0,0,1,1H7.229" />
      <path d="M16.771,13.5H22.5a1,1,0,0,0,1-1v-1a1,1,0,0,0-1-1H16.771" />
    </StreamlineSvg>
  )),

  SavingBank: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="4.452" y1="20.927" x2="4.452" y2="11.927" />
      <line x1="3.452" y1="20.927" x2="9.452" y2="20.927" />
      <line x1="1.004" y1="22.927" x2="23.004" y2="22.927" />
      <line x1="3.452" y1="11.927" x2="9.452" y2="11.927" />
      <line x1="6.452" y1="20.927" x2="6.452" y2="11.927" />
      <line x1="8.452" y1="20.927" x2="8.452" y2="11.927" />
      <line x1="14.452" y1="20.927" x2="20.452" y2="20.927" />
      <line x1="14.452" y1="11.927" x2="20.452" y2="11.927" />
      <line x1="15.452" y1="20.927" x2="15.452" y2="11.927" />
      <line x1="17.452" y1="20.927" x2="17.452" y2="11.927" />
      <line x1="19.452" y1="20.927" x2="19.452" y2="11.927" />
      <path d="M2.1,9.032a.5.5,0,0,0,.308.895H21.6a.5.5,0,0,0,.326-.88L12.793,1.194a.5.5,0,0,0-.633-.015Z" />
    </StreamlineSvg>
  )),

  ScienceMolecule: React.memo((props) => (
    <StreamlineSvg {...props}>
      <ellipse cx="12" cy="12" rx="4.5" ry="11.5" />
      <path d="M14.55,15.708c-5.233,3.6-10.616,4.857-12.026,2.809S4.217,11.892,9.45,8.292,20.066,3.436,21.476,5.483,19.783,12.108,14.55,15.708Z" />
      <path d="M9.45,15.708c5.233,3.6,10.616,4.857,12.026,2.809S19.783,11.892,14.55,8.292,3.934,3.436,2.524,5.483,4.217,12.108,9.45,15.708Z" />
      <circle cx="12" cy="12" r="2" />
    </StreamlineSvg>
  )),

  Search: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="8.5" cy="8.5" r="8" />
      <line x1="14.156" y1="14.156" x2="23.5" y2="23.5" />
    </StreamlineSvg>
  )),

  SendEmail: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M23.5,2,.931,11.323a.7.7,0,0,0,.08,1.318L9.5,15Z" />
      <path d="M23.5,2,19.65,20.286a.9.9,0,0,1-1.376.562L9.5,15Z" />
      <path d="M9.5,15V21.48a.519.519,0,0,0,.967.261l2.561-4.39" />
    </StreamlineSvg>
  )),

  // SettingsSlider: React.memo((props) => (
  //   <StreamlineSvg {...props}>
  //     <rect x="0.5" y="0.501" width="23" height="23" rx="1" ry="1" />
  //     <circle cx="9" cy="6.501" r="3" />
  //     <circle cx="15.5" cy="17.501" r="3" />
  //     <line x1="12.5" y1="17.501" x2="3.5" y2="17.501" />
  //     <line x1="20.5" y1="17.501" x2="18.5" y2="17.501" />
  //     <line x1="12" y1="6.501" x2="20.5" y2="6.501" />
  //     <line x1="3.5" y1="6.501" x2="6" y2="6.501" />
  //     <path d="M9,6.251a.25.25,0,1,1-.25.25A.25.25,0,0,1,9,6.251" />
  //     <path d="M15.5,17.251a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //   </StreamlineSvg>
  // )),

  Share1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="12" y1="13.499" x2="12" y2="0.499" />
      <polyline points="7.5 4.999 12 0.499 16.5 4.999" />
      <path d="M15,8.5h4a1,1,0,0,1,1,1v13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9.5a1,1,0,0,1,1-1H9" />
    </StreamlineSvg>
  )),

  ShipmentBox: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M18.8.919A1,1,0,0,0,17.985.5H6.015A1,1,0,0,0,5.2.919L.686,7.239A1,1,0,0,0,.5,7.82V22.5a1,1,0,0,0,1,1h21a1,1,0,0,0,1-1V7.82a1,1,0,0,0-.186-.581Z" />
      <line x1="12" y1="0.5" x2="12" y2="7.5" />
      <rect x="12.501" y="17.5" width="8" height="3" rx="0.5" ry="0.5" />
      <line x1="0.553" y1="7.5" x2="23.447" y2="7.5" />
    </StreamlineSvg>
  )),

  ShowHatMagician: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="0.5" y1="1.5" x2="2.5" y2="1.5" />
      <line x1="1.5" y1="0.5" x2="1.5" y2="2.5" />
      <line x1="14.5" y1="8.5" x2="16.5" y2="8.5" />
      <line x1="15.5" y1="7.5" x2="15.5" y2="9.5" />
      <line x1="10.5" y1="2.5" x2="12.5" y2="2.5" />
      <line x1="11.5" y1="1.5" x2="11.5" y2="3.5" />
      <polyline points="6 2.5 5 4.5 3 5.5 5 6.5 6 8.5 7 6.5 9 5.5 7 4.5 6 2.5" />
      <path d="M15.5,20.833A2.43,2.43,0,0,1,13.42,23.5H5.58A2.428,2.428,0,0,1,3.5,20.833V11.5h12Z" />
      <line x1="0.5" y1="11.5" x2="18.5" y2="11.5" />
      <line x1="3.5" y1="15.5" x2="15.5" y2="15.5" />
      <line x1="23.5" y1="0.5" x2="12" y2="6.5" />
    </StreamlineSvg>
  )),

  ShowTheater: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M1.5.5h21a1,1,0,0,1,1,1v2a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0v-2A1,1,0,0,1,1.5.5Z" />
      <line x1="17.585" y1="22.5" x2="6.415" y2="22.5" />
      <path d="M3.5,22a1.5,1.5,0,0,1-3,0V3.5" />
      <path d="M6.5,3.5V22a1.5,1.5,0,0,1-3,0V7.465" />
      <path d="M20.5,22a1.5,1.5,0,0,0,3,0V3.5" />
      <path d="M17.5,3.5V22a1.5,1.5,0,0,0,3,0V7.465" />
    </StreamlineSvg>
  )),

  StampsMail: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="3.5" />
      <path d="M15.4,18.063A7.162,7.162,0,0,1,11.845,19a7,7,0,1,1-.039-14c9.5,0,8.982,10.5,4.944,10.5a1.25,1.25,0,0,1-1.25-1.25V9" />
      <path d="M.5,4.5H1a1.5,1.5,0,0,1,0,3H.5v3H1a1.5,1.5,0,0,1,0,3H.5v3H1a1.5,1.5,0,0,1,0,3H.5v3a1,1,0,0,0,1,1h3V23a1.5,1.5,0,0,1,3,0v.5h3V23a1.5,1.5,0,0,1,3,0v.5h3V23a1.5,1.5,0,0,1,3,0v.5h3a1,1,0,0,0,1-1v-3H23a1.5,1.5,0,0,1,0-3h.5v-3H23a1.5,1.5,0,0,1,0-3h.5v-3H23a1.5,1.5,0,0,1,0-3h.5v-3a1,1,0,0,0-1-1h-3V1a1.5,1.5,0,0,1-3,0V.5h-3V1a1.5,1.5,0,0,1-3,0V.5h-3V1a1.5,1.5,0,0,1-3,0V.5h-3a1,1,0,0,0-1,1Z" />
    </StreamlineSvg>
  )),

  StartupLaunch1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <line x1="9" y1="2" x2="9" y2="4" />
      <line x1="10" y1="3" x2="8" y2="3" />
      <line x1="5" y1="5" x2="5" y2="7" />
      <line x1="6" y1="6" x2="4" y2="6" />
      <line x1="20" y1="18" x2="18" y2="18" />
      <line x1="19" y1="17" x2="19" y2="19" />
      <path d="M7.051,20.444C6.058,21.437,3,21.5,3,21.5s.23-2.892,1.222-3.884,2.026-.1,2.475.353S8.043,19.451,7.051,20.444Z" />
      <line x1="9.001" y1="11.593" x2="12.907" y2="15.499" />
      <line x1="19.439" y1="5.061" x2="20.5" y2="4" />
      <path d="M19.793,8.95l-.354-3.889L15.55,4.707a2.982,2.982,0,0,0-2.828,1.414L6.711,14.96l1.415,1.414L9.54,17.789l8.839-6.011A2.982,2.982,0,0,0,19.793,8.95Z" />
      <path d="M9,11.593l-1.078-.412a2,2,0,0,0-2.129.454L3.837,13.592a.5.5,0,0,0,.255.844l2.619.524" />
      <path d="M12.907,15.5l.412,1.078a2,2,0,0,1-.454,2.129l-1.957,1.957a.5.5,0,0,1-.844-.255L9.54,17.789" />
      <circle cx="15.55" cy="8.95" r="1.5" />
    </StreamlineSvg>
  )),

  SyncHeart: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M12,15 L9.44322839,12.4598774 C8.98272286,12.0211923 8.86853388,11.3511074 9.159726,10.7962257 L9.159726,10.7962257 C9.37723896,10.3820896 9.7904901,10.0927632 10.2704104,10.0186107 C10.7503307,9.94445816 11.2384241,10.0945177 11.5822465,10.4219219 L12,10.8197983 L12.4177535,10.4219219 C12.7615759,10.0945177 13.2496693,9.94445816 13.7295896,10.0186107 C14.2095099,10.0927632 14.622761,10.3820896 14.840274,10.7962257 L14.840274,10.7962257 C15.1314661,11.3511074 15.0172771,12.0211923 14.5567716,12.4598774 L12,15 Z" />
      <polyline points="0.714 10.353 3.501 14.503 6.706 10.665" />
      <polyline points="23.286 14.655 20.501 10.503 17.294 14.342" />
      <path d="M20.464,10.54 C21.1653222,14.9849276 18.330348,19.2217117 13.954,20.269 C11.1036981,20.9450436 8.10620701,20.1044913 6.023,18.045" />
      <path d="M3.505,14.479 C2.93329712,12.1853457 3.3026107,9.7583119 4.53067588,7.73852222 C5.75874106,5.71873254 7.74355537,4.27394966 10.043,3.726 C13.0926051,3.00336504 16.2923354,4.01894498 18.367,6.368" />
    </StreamlineSvg>
  )),

  SynchronizeArrows1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <polyline points="0.714 10.353 3.501 14.503 6.706 10.665" />
      <polyline points="23.286 14.655 20.501 10.503 17.294 14.342" />
      <path d="M20.464,10.54a8.622,8.622,0,0,1-6.51,9.729,8.493,8.493,0,0,1-7.931-2.224" />
      <path d="M3.505,14.479A8.853,8.853,0,0,1,10.043,3.726a8.493,8.493,0,0,1,8.324,2.642" />
    </StreamlineSvg>
  )),

  TaskChecklistCheck: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M10,23.5H1.5a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h2" />
      <path d="M15.5,2.5h2a1,1,0,0,1,1,1v6" />
      <path d="M11.5,2.5a2,2,0,0,0-4,0h-2v3h8v-3Z" />
      <path d="M16.5,9.5V5a.5.5,0,0,0-.5-.5H13.5" />
      <path d="M5.5,4.5H3a.5.5,0,0,0-.5.5V21a.5.5,0,0,0,.5.5h7" />
      <line x1="5.5" y1="8.499" x2="13.5" y2="8.499" />
      <line x1="5.5" y1="11.499" x2="11.5" y2="11.499" />
      <line x1="5.5" y1="14.499" x2="9.5" y2="14.499" />
      <circle cx="17.5" cy="17.499" r="6" />
      <path d="M20.174,15.755l-2.905,3.874a.75.75,0,0,1-1.13.08l-1.5-1.5" />
    </StreamlineSvg>
  )),

  TeamworkHands: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M6.854,9.838a1.13,1.13,0,0,0-2.2-.481c-.395,1.21-1.078,3.371-1.4,4.825a3.368,3.368,0,0,0,.052,1.673L.781,18.367" />
      <line x1="2.785" y1="23.192" x2="6.54" y2="19.462" />
      <path d="M16.805,8.422c-.463-.864-.968-1.766-1.4-2.447a3.385,3.385,0,0,0-1.219-1.146L14.2.766" />
      <path d="M9.367.77,9.349,4.563A3.394,3.394,0,0,0,7.218,6.428" />
      <path d="M9,12.134A1.03,1.03,0,0,0,7.545,13.59l-.017.017,3.784,3.713-1.447-.159a1.13,1.13,0,0,0-.481,2.2c1.209.395,3.371,1.077,4.825,1.4a3.38,3.38,0,0,0,1.673-.053l2.512,2.528" />
      <path d="M12.643,12.924l-2.5-2.558a1.03,1.03,0,0,0-1.456,1.456l2.395,2.345" />
      <path d="M10.457,10.678a1.03,1.03,0,1,1,1.456-1.456l2.395,2.512" />
      <path d="M23.219,21.231l-3.73-3.755a3.363,3.363,0,0,0-.567-3.986l-4.959-4.9,0,0A.976.976,0,0,0,12.4,9.713" />
    </StreamlineSvg>
  )),

  UserFriends1: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M4.5616666666666665 3.2458750000000003a2.7667083333333333 2.7667083333333333 0 1 0 5.533416666666667 0 2.7667083333333333 2.7667083333333333 0 1 0 -5.533416666666667 0" />
      <path d="M5.389666666666667 8.884708333333334a1.5515416666666668 1.5515416666666668 0 0 1 -2.0479583333333333 0.784875L2.3958333333333335 9.247916666666667a1.5505833333333334 1.5505833333333334 0 0 1 1.2640416666666667 -2.8328333333333333l0.9449166666666667 0.42070833333333335a1.5515416666666668 1.5515416666666668 0 0 1 0.784875 2.0489166666666665Z" />
      <path d="m7.599583333333333 20.969291666666667 0 -5.850625000000001" />
      <path d="M10.701708333333334 11.699333333333334v9.269958333333333a1.5515416666666668 1.5515416666666668 0 1 1 -3.102125 0" />
      <path d="M7.599583333333333 20.969291666666667a1.5515416666666668 1.5515416666666668 0 1 1 -3.102125 0v-8.311625000000001" />
      <path d="M1.9166666666666667 11.459750000000001v3.2784583333333335A1.549625 1.549625 0 0 0 3.4624583333333336 16.291666666666668h1.0350000000000001" />
      <path d="M13.34 3.2458750000000003a2.7667083333333333 2.7667083333333333 0 1 0 5.533416666666667 0 2.7667083333333333 2.7667083333333333 0 1 0 -5.533416666666667 0" />
      <path d="m15.835500000000001 20.969291666666667 0 -5.850625000000001" />
      <path d="M18.937625 11.699333333333334v9.269958333333333a1.5515416666666668 1.5515416666666668 0 1 1 -3.102125 0" />
      <path d="M18.937625 16.291666666666668h1.0340416666666667a1.5505833333333334 1.5505833333333334 0 0 0 1.5515416666666668 -1.5505833333333334v-3.555416666666667a5.175000000000001 5.175000000000001 0 0 0 -5.175000000000001 -5.175000000000001h-1.0302083333333334a5.1405 5.1405 0 0 0 -1.7671666666666668 0.31433333333333335" />
      <path d="M10.701708333333334 16.291666666666668h1.0350000000000001a1.549625 1.549625 0 0 0 1.5505833333333334 -1.5505833333333334v-3.555416666666667a5.175000000000001 5.175000000000001 0 0 0 -5.170208333333333 -5.175000000000001H7.082083333333333a5.148166666666667 5.148166666666667 0 0 0 -1.6914583333333333 0.2875 1.6675 1.6675 0 0 0 -0.7858333333333333 0.5405" />
      <path d="M12.732416666666667 18.168083333333332v2.8012083333333333a1.5515416666666668 1.5515416666666668 0 0 0 3.1030833333333336 0" />
    </StreamlineSvg>
  )),

  UserMultipleNeutral2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M14.407,19.385,14.75,23.5h4l.5-6h2.5V14a5,5,0,0,0-8-4" />
      <circle cx="16.75" cy="4" r="3.5" />
      <path d="M9.75,23.5l.5-6h2.5V14a5,5,0,0,0-10,0v3.5h2.5l.5,6Z" />
      <circle cx="7.75" cy="4" r="3.5" />
    </StreamlineSvg>
  )),

  UserSignal: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M17.657,17.156a8,8,0,1,0-11.314,0" />
      <path d="M19.778,19.277a11,11,0,1,0-15.556,0" />
      <path d="M16.177,14.25a5,5,0,1,0-8.354,0" />
      <circle cx="12" cy="10.999" r="2" />
      <path d="M12,14a3,3,0,0,0-3,3v3h1.5l.248,3.5h2.5L13.5,20H15V17A3,3,0,0,0,12,14Z" />
    </StreamlineSvg>
  )),

  UserSingleNeutralCircle: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M3.959,20.221a25.59,25.59,0,0,1,5.413-2.352c.837-.309.928-2.229.328-2.889-.866-.953-1.6-2.07-1.6-4.766A3.812,3.812,0,0,1,12,6.047a3.812,3.812,0,0,1,3.9,4.167c0,2.7-.734,3.813-1.6,4.766-.6.66-.509,2.58.328,2.889a25.59,25.59,0,0,1,5.413,2.352" />
    </StreamlineSvg>
  )),

  ViewOff: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M19.518,8.288A22.111,22.111,0,0,1,23.5,12S18.352,18.5,12,18.5a10.3,10.3,0,0,1-3-.464" />
      <path d="M4.468,15.7A22.105,22.105,0,0,1,.5,12S5.648,5.5,12,5.5a10.106,10.106,0,0,1,2.5.325" />
      <path d="M8,12a4,4,0,0,1,4-4" />
      <path d="M16,12a4,4,0,0,1-4,4" />
      <line x1="21.75" y1="2.25" x2="2.25" y2="21.75" />
    </StreamlineSvg>
  )),

  WifiSignal4: React.memo((props) => (
    <StreamlineSvg {...props}>
      <path d="M17,23.5,12.6,9.371a1.485,1.485,0,0,1-1.2,0L7,23.5" />
      <circle cx="11.996" cy="7.998" r="1.5" />
      <path d="M17.592,3.4a6.5,6.5,0,0,1,0,9.192" />
      <path d="M19.714,1.28a9.5,9.5,0,0,1,0,13.435" />
      <path d="M6.4,3.4a6.5,6.5,0,0,0,0,9.192" />
      <path d="M4.278,1.28a9.5,9.5,0,0,0,0,13.435" />
      <line x1="9.182" y1="16.498" x2="14.818" y2="16.498" />
      <line x1="7.935" y1="20.498" x2="16.065" y2="20.498" />
    </StreamlineSvg>
  )),

  WomanDoctor2: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="6.5" cy="22.001" r="1.5" />
      <line x1="6.5" y1="20.501" x2="6.5" y2="17.001" />
      <line x1="17.5" y1="20.501" x2="17.5" y2="17.001" />
      <path d="M15,23.5l.772-2.317a1,1,0,0,1,.949-.683h1.558a1,1,0,0,1,.949.683L20,23.5" />
      <path d="M23.5,23.5v-1a4,4,0,0,0-2.6-3.746L16,17.109" />
      <path d="M8,17.109,3.1,18.755A4,4,0,0,0,.5,22.5v1" />
      <line x1="12" y1="23.501" x2="12" y2="18.501" />
      <path d="M10.5,13a1.971,1.971,0,0,0,1.358.5h.284A1.969,1.969,0,0,0,13.5,13" />
      <path d="M11,9a1.087,1.087,0,0,0-1-.5A1.087,1.087,0,0,0,9,9" />
      <path d="M15,9a1.087,1.087,0,0,0-1-.5A1.087,1.087,0,0,0,13,9" />
      <path d="M12.02,16.5c1.387,0,4.49-1.721,4.932-4.365a1,1,0,0,1,.572-.747C18.6,10.9,18.872,8.937,18,8.5c.5-2.986.5-8-5.982-8s-6.477,5.014-5.981,8c-.871.436-.6,2.4.478,2.888a1,1,0,0,1,.572.747C7.53,14.78,10.633,16.5,12.02,16.5Z" />
      <path d="M5.848,6.485c1.338-.031,4.034.378,6.152-2.17,2.128,2.56,4.871,2.145,6.193,2.179" />
      <path d="M5.732,10.546a5.167,5.167,0,0,1-1.961,3.172.5.5,0,0,0-.041.831,4.428,4.428,0,0,0,2.915.933" />
      <path d="M18.309,10.546a5.159,5.159,0,0,0,1.961,3.172.5.5,0,0,1,.04.831,4.426,4.426,0,0,1-2.915.933" />
    </StreamlineSvg>
  )),

  YogaMeditate: React.memo((props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="3.5" r="3" />
      <path d="M6.256,22.834a1.5,1.5,0,0,0,1.639.613l4.1-1.12" />
      <path d="M12.677,19.4l4.217,1.15a1.5,1.5,0,1,1-.789,2.895l-11-3A1.5,1.5,0,0,1,5.9,17.552l2.605.711V14A4.36,4.36,0,0,1,5,15.5,4.472,4.472,0,0,1,.552,12.39a1.5,1.5,0,1,1,2.9-.779A1.459,1.459,0,0,0,5,12.5c.874,0,2.353-1.407,3.134-3.12A1.5,1.5,0,0,1,9.5,8.5h5a1.5,1.5,0,0,1,1.368.88c.781,1.713,2.26,3.12,3.134,3.12a1.459,1.459,0,0,0,1.551-.889,1.5,1.5,0,1,1,2.9.779A4.472,4.472,0,0,1,19,15.5,4.36,4.36,0,0,1,15.5,14v4.263l2.605-.711a1.5,1.5,0,0,1,1.352,2.6" />
      <line x1="8.5" y1="12.5" x2="8.5" y2="14" />
      <line x1="15.5" y1="12.5" x2="15.5" y2="14" />
    </StreamlineSvg>
  )),

};

const catIconMap = {
  [Categories.CULTURE]: Icon.CatCulture,
  [Categories.EDUCATION]: Icon.CatEducation,
  [Categories.ENVIRONMENT]: Icon.CatEnvironment,
  [Categories.ANIMALS]: Icon.CatAnimals,
  [Categories.HEALTH]: Icon.CatHealth,
  [Categories.CIVIL_RIGHTS]: Icon.CatCivilRights,
  [Categories.HUMAN_SERVICES]: Icon.UserFriends1,
  [Categories.PUBLIC_SERVICES]: Icon.OfficialBuilding3,
  [Categories.INTERNATIONAL]: Icon.CatInternational,
  [Categories.COMMUNITY_DEV]: Icon.CatCommunityDev,
  [Categories.PUBLIC_POLICY]: Icon.ScienceMolecule,
  [Categories.RELIGION]: Icon.CatReligion,
  [Categories.OTHER]: Icon.CatOther,
};

Icon.Category = React.memo(({category}) => {
  const Comp = catIconMap[category] || Icon.CatOther;
  if (!Comp) return null;
  return <Comp />;
});

const nteeIconMap = {
  A: Icon.CatCulture,
  B: Icon.CatEducation,
  C: Icon.CatEnvironment,
  D: Icon.CatAnimals,
  E: Icon.WomanDoctor2,
  F: Icon.YogaMeditate,
  G: Icon.CatHealth,
  H: Icon.LabTubes,
  I: Icon.PoliceMan1,
  J: Icon.OfficeEmployee,
  K: Icon.FruitStrawberry,
  L: Icon.HouseModern,
  M: Icon.SafetyFloat,
  N: Icon.BasketBall,
  O: Icon.PeaceSign,
  P: Icon.UserFriends1,
  Q: Icon.CatInternational,
  R: Icon.CatCivilRights,
  S: Icon.HeavyEquipmentHook,
  T: Icon.LoveItHandGive,
  U: Icon.ScienceMolecule,
  V: Icon.EcologyHumanMind,
  W: Icon.OfficialBuilding3,
  X: Icon.CatReligion,
  Y: Icon.MultipleUsers3,
  Z: null,
};

Icon.Ntee = React.memo(({nteeCode, ...props}) => {
  const major = (nteeCode || '')[0] || 'Z';
  const Comp = nteeIconMap[major] || Icon.CatOther;
  if (!Comp) return null;
  return <Comp {...props} />;
});

Icon.GoodOrBad = React.memo(({isGood}) => {
  return isGood
    ? <Icon.CheckCircle1 className="goodorbad good" />
    : <Icon.Delete className="goodorbad bad" />
});

const ccBrandIconMap = {
  visa: Icon.CreditCardVisa,
  amex: Icon.CreditCardAmex,
  'american express': Icon.CreditCardAmex,
  mastercard: Icon.CreditCardMastercard,
};

Icon.CreditCard = React.memo(({brand}) => {
  const Comp = ccBrandIconMap[brand] || Icon.CreditCardGeneric;
  if (!Comp) return null;
  return <Comp />;
});

export default Icon;

