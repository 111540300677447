import moment from 'moment';
// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi    from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Link            from 'app/components/common/link';
import StandardInput   from 'app/components/common/standard-input';
import paths           from 'app/paths';
import pricing         from 'app/pricing';


class BackstagePagesPricing extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      employeeCount: null,
    };

    this.onChangeEmployeeCount = this.onChangeEmployeeCount.bind(this);
  }

  onChangeEmployeeCount(event) {
    const employeeCount = parseInt(event.target.value) || null;
    this.setState({employeeCount});
  }

  render() {
    const {employeeCount} = this.state;
    const products = Object.values(pricing).filter(v => !!v.productType);
    return (
      <BackstageLayout>
        <div className="page-bs-pricing">
          <h1>Pricing (Old)</h1>
          <p>This is out-dated. See elsewhere current pricing.</p>
          <div style={{width: 300}}>
            <StandardInput type="number" name="employeeCount" label="Enter employee count to filter..." onChange={this.onChangeEmployeeCount} value={employeeCount || ''} />
          </div>
          {products.map((product) => {
            const prices = pricing.logPrices(product.type);
            const filteredPrices = prices.filter((price) => {
              if (!employeeCount) return true;
              return (price.empMin <= employeeCount) && (price.empMax >= employeeCount);
            });
            return (
              <div key={product.type}>
                <h2>{product.name} {product.productType}</h2>
                <p><b>Features:</b> {(product.features || []).join(', ')}</p>
                <table className="backstage sticky-header pricing-table">
                  <thead>
                    <tr>
                      <th>empMin</th>
                      <th>empMax</th>
                      <th>gradMax</th>
                      <th>monthlyTotal</th>
                      <th>yearlyTotal</th>
                      <th>effectiveRate</th>
                      <th>gradCost</th>
                      <th>gradRate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPrices.map((price) => (
                      <tr key={price.empMax}>
                        <td>{price.empMin}</td>
                        <td>{price.empMax}</td>
                        <td>{price.gradMax}</td>
                        <td>{price.monthlyTotal}</td>
                        <td>{price.yearlyTotal}</td>
                        <td>{price.effectiveRate}</td>
                        <td>{price.gradCost}</td>
                        <td>{price.gradRate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstagePagesPricing);
