import React from 'react';
import {connect} from 'react-redux';

import Link    from 'app/components/common/link';
import Meta    from 'app/components/common/meta';
import {
  BackstageRoles as Roles,
}              from 'app/constants';
import paths   from 'app/paths';
import AuthSlx from 'app/selectors/auth';

class BackstageLayout extends React.PureComponent {

  render() {
    const {currentUser} = this.props;

    const makeItem = ({path, title, roles=[]}) => {
      roles.push(Roles.SUPER);
      if (!roles.includes(currentUser?.backstageRole)) return null;
      return <Link href={path}>{title}</Link>
    };

    return (
      <div className={`bs ${this.props.onlyMenu ? 'nobody' : ''}`}>
        <Meta title="Backstage" />
        <div className="bs-nav">
          <h1 className="bs-page-h1">Backstage</h1>
          <Link href={paths.home}>&larr; Main App</Link>
          <div className="bs-nav-sep" />
          {makeItem({path: paths.bsDashboardOverview(),   title: 'Dashboard'})}
          {makeItem({path: paths.bsPendingClaims(),       title: 'Pending Claims'})}
          {makeItem({path: paths.bsNonprofitRequests(),   title: 'Nonprofit Requests', roles: [Roles.NONPROFIT_DATA]})}
          {makeItem({path: paths.bsFailedCharges(),       title: 'Failed & Disputed Charges'})}
          {makeItem({path: paths.bsFeatureFlags(),        title: 'Feature Flags'})}
          {makeItem({path: paths.bsConfig(),              title: 'Config'})}
          <div className="bs-nav-sep" />
          {makeItem({path: paths.bsNonprofits(),          title: 'Nonprofits', roles: [Roles.NONPROFIT_DATA]})}
          {makeItem({path: paths.bsDisbursements(),       title: 'Disbursements'})}
          {makeItem({path: paths.bsNteeCodes(),           title: 'NTEE Codes'})}
          {makeItem({path: paths.bsUsers(),               title: 'Users'})}
          {makeItem({path: paths.bsCollections(),         title: 'Collections'})}
          {makeItem({path: paths.bsCompanies(),           title: 'Companies'})}
          {makeItem({path: paths.bsBalances(),            title: 'Balances'})}
        </div>
        {!this.props.onlyMenu &&
          <div className="bs-body">
            {this.props.children}
          </div>
        }
      </div>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
});

export default connect(stateToProps)(BackstageLayout);
