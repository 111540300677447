import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ToastAx         from 'app/actions/toast';
import ClientTimestamp from 'app/components/common/client-timestamp';
import EllipsisMenu    from 'app/components/common/ellipsis-menu';
import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Text            from 'app/components/common/text';
import Comments        from 'app/components/social/comments';
import ReactionSet     from 'app/components/social/reaction-set';
import UrlPreview      from 'app/components/social/url-preview';
import {
  SocialReactionTargets as Targets,
  EmployeeRoles,
}                      from 'app/constants';
import ModalFormDuck   from 'app/ducks/modal-social-post-form';
import SocialDuck      from 'app/ducks/social';
import Duck            from 'app/ducks/social-post-view';
import cdn             from 'app/helpers/cdn';
import helpers         from 'app/helpers/social';
import history         from 'app/history';
import paths           from 'app/paths';
import prompts         from 'app/prompts';
import AuthSlx         from 'app/selectors/auth';

class SocialPostView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      carouselIndex: 0,
    };

    this.onKeydownDocument = this.onKeydownDocument.bind(this);
    this.onClickCarouselImg = this.onClickCarouselImg.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydownDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydownDocument);
  }

  get largeText() {
    return helpers.useLargeText(this.props.post);
  }

  get imgCount() {
    return this.props.post?.imgPaths?.length || 0;
  }

  get hasImg() {
    return this.imgCount > 0;
  }

  get currentEmployee() {
    return this.props.currentUser?.employment?.employee;
  }

  get isOwner() {
    const {post} = this.props;
    if (!post || !this.currentEmployee) return false;
    return this.currentEmployee.id === post.employee?.id;
  }

  get isAdmin() {
    if (!this.currentEmployee) return false;
    return this.currentEmployee.role === EmployeeRoles.ADMIN;
  }

  get canEdit() {
    return this.isOwner;
  }

  get canDelete() {
    return !!(this.isOwner || this.isAdmin);
  }

  get postPath() {
    const {postId, listingId} = this.props;
    if (!postId) return '#';
    return paths.socialPost(postId, {listingId});
  }

  get isDeleted() {
    return !!this.props.post?._deleted;
  }

  async onClickShare(event) {
    event.preventDefault();
    try {
      const origin = (new URL(document.URL)).origin;
      const url = `${origin}${this.postPath}`;
      await navigator.clipboard.writeText(url);
      this.props.toastSuccess('URL copied to clipboard. Only people within your company can see this post.');
    } catch (error) {
      console.error(error);
      history.push(this.postPath);
    }
  }

  onClickEdit(event) {
    event.preventDefault();
    const {closeViewModal, openEditModal, post} = this.props;
    openEditModal({post});
    closeViewModal();
  }

  async onClickDelete(event) {
    event.preventDefault();
    const msg = 'Are you sure you want to delete this post? This can not be undone.';
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (didConfirm) {
      await this.props.delete(this.props.postId);
    }
  }

  onClickCarouselImg(carouselIndex) {
    this.setState({carouselIndex});
  }

  onKeydownDocument(event) {
    const el = event.target;
    if (['TEXTAREA', 'INPUT'].includes((el?.tagName || '').toUpperCase())) return;
    const arrowIndex = ['ArrowRight', 'ArrowDown', 'ArrowLeft', 'ArrowUp'].indexOf(event.key);
    if (arrowIndex !== -1) {
      event.preventDefault();
      (arrowIndex < 2) ? this.carouselNext() : this.carouselBack();
    }
  }

  carouselNext() {
    const count = this.props.post?.imgPaths?.length || 0;
    this.setState((prevState) => {
      let carouselIndex = prevState.carouselIndex + 1;
      if (carouselIndex >= count) carouselIndex = 0;
      return {carouselIndex};
    });
  }

  carouselBack() {
    const count = this.props.post?.imgPaths?.length || 0;
    this.setState((prevState) => {
      let carouselIndex = prevState.carouselIndex - 1;
      if (carouselIndex < 0) carouselIndex = count - 1;
      return {carouselIndex};
    });
  }

  renderMenu() {
    return (
      <EllipsisMenu>
        <a href="#" onClick={this.onClickShare}><Icon.Share1 />Share</a>
        {this.canEdit && (
          <a href="#" onClick={this.onClickEdit}><Icon.Pencil />Edit Post</a>
        )}
        {this.canDelete && (
          <a href="#" onClick={this.onClickDelete}><Icon.Bin />Delete Post</a>
        )}
      </EllipsisMenu>
    );
  }

  renderHead() {
    const {post, listing} = this.props;
    const name = post?.employee ? `${post.employee.firstName} ${post.employee.lastName}` : `User`;
    return (
      <div className="spost-vw-main-head">
        <img className="spost-vw-main-head-avatar" src={helpers.avatarUrl(post?.user?.avatarUrl, {width: 120})} />
        <div className="spost-vw-main-head-text">
          <Link href={post?.user ? paths.user(post.user.id) : '#'} className="spost-vw-main-head-text-name pink-hover">{name}</Link>
          <Link href={this.postPath} className="spost-vw-main-head-text-date"><ClientTimestamp timestamp={post?.createdTs || 0} /></Link>
        </div>
        <div className="spost-vw-main-head-menu">
          {this.renderMenu()}
        </div>
      </div>
    );
  }

  renderBody() {
    const {post} = this.props;
    return (
      <div className={`spost-vw-main-body ${this.largeText ? 'large' : 'small'}`}>
        <Text.Paragraphs str={post?.body || ''} showEmptyLines />
      </div>
    );
  }

  renderImgs() {
    if (!this.hasImg) return null;
    const {imgPaths} = this.props.post;
    const {carouselIndex} = this.state;
    const currentImgPath = imgPaths[carouselIndex];
    const showCarousel = imgPaths.length > 1;
    const countClass = showCarousel ? 'multi' : 'single';

    return (
      <div className={`spost-vw-main-imgs ${countClass}`}>
        <div className="spost-vw-main-imgs-crnt">
          <img src={cdn.imgUrl(currentImgPath, {width: 1300, height: 1300})} className="spost-vw-main-imgs-crnt-img" />
        </div>
        {showCarousel && (
          <div className="spost-vw-main-imgs-car">
            {imgPaths.map((imgPath, i) => {
              const url = cdn.imgUrl(imgPath, {height: 120, width: 240});
              const isActive = i === carouselIndex;
              return (
                <div key={imgPath} className={`spost-vw-main-imgs-car-img ${isActive ? 'active' : ''}`} onClick={this.onClickCarouselImg.bind(this, i)}>
                  <img src={url}  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderUrl() {
    if (this.props.post?.imgPaths?.length) return null;
    const urlObj = this.props.post?.previewUrlObj;
    if (!urlObj) return null;
    return (
      <UrlPreview urlObj={urlObj} className="spost-vw-main-url" />
    );
  }

  render() {
    const {post, listing} = this.props;

    return (
      <div className={`spost-vw ${this.hasImg ? 'has-media' : ''}`}>
        {this.isDeleted ? (<>
          <p className="spost-vw-deleted">This post has been deleted.</p>
        </>) : (<>
          <div className="spost-vw-main">
            {this.renderHead()}
            {this.renderImgs()}
            {this.renderBody()}
            {this.renderUrl()}
            {!!post && (
              <ReactionSet targetType={Targets.POST} targetId={post.id} count={26} className="spost-vw-main-react" />
            )}
          </div>
          <div className="spost-vw-cmnt">
            <Comments count={post.commentCount} postId={post?.id} />
          </div>
        </>)}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  postId: Duck.Slx.postId(state),
  listingId: Duck.Slx.listingId(state),

  listing: Duck.Slx.listing(state),
  listings: Duck.Slx.listings(state),
  post: Duck.Slx.post(state),

  deletePending: SocialDuck.Slx.postDeletePending(state),

  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  toastSuccess: (msg) => dispatch(ToastAx.success(msg)),
  openEditModal: (params) => dispatch(ModalFormDuck.Ax.open(params)),
  closeViewModal: () => dispatch(Duck.Ax.closeModal()),
  delete: (postId) => dispatch(SocialDuck.Ax.postsDelete(postId)),
});

export default connect(stateToProps, dispatchToProps)(SocialPostView);
