import React from 'react';
import PropTypes from 'prop-types';

import Link   from 'app/components/common/link';
import config from 'app/config';
import utils  from 'app/helpers/utils';

class Linkify extends React.PureComponent {
  render() {
    const {str} = this.props;
    if (!str) return null;
    const parts = utils.extractUrlParts(str);
    return (
      <>
        {parts.map(({type, val}, i) => {
          if (type === 'url') {
            const isInternal = (val || '').startsWith(config.baseUrl);
            const href = isInternal ? (new URL(val)).pathname : val;
            return isInternal
              ? <Link href={href} key={i} className="blue-pink-hover">{val}</Link>
              : <a href={href} target="_blank" key={i} className="blue-pink-hover" rel="nofollow">{val}</a>;
          }
          return <React.Fragment key={i}>{val}</React.Fragment>;
        })}
      </>
    );
  }
}

Linkify.propTypes = {
  str: PropTypes.string,
};

Linkify.defaultProps = {
  str: null,
};

class Paragraphs extends React.PureComponent {
  render() {
    const {str, pClassName, linkify, showEmptyLines} = this.props;
    if (!str) return null;
    return (<>
      {str.trim().split('\n').map((p, i) => {
        if (!p && !showEmptyLines) return null;
        return <p className={pClassName} key={i}>{linkify ? <Linkify str={p} /> : p}</p>;
      })}
    </>);
  }
}

Paragraphs.propTypes = {
  str: PropTypes.string,
  pClassName: PropTypes.string,
  linkify: PropTypes.bool,
  showEmptyLines: PropTypes.bool,
};

Paragraphs.defaultProps = {
  str: null,
  pClassName: '',
  linkify: true,
  showEmptyLines: false,
};

export default {
  Linkify,
  Paragraphs,
};
