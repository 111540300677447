import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import BackstageApi from 'app/apis/backstage';
import BackstageLayout from 'app/components/backstage/layout';
import Icon from 'app/components/common/icon';
import Link from 'app/components/common/link';
import Modal from 'app/components/common/modal';
import paths from 'app/paths';

class BackstagePageDisbursement extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      disb: null,
    };

    // this.onCloseDispute = this.onCloseDispute.bind(this);
  }

  componentDidMount() {
    // BackstageApi.failedChargesFetch().then(({charges}) => {
    //   this.setState({charges});
    // });
  }

  render() {
    return (
      <BackstageLayout>
        <div className="bspage-disbs">
          <h1>Disbursement</h1>
          <pre>{JSON.stringify({disb},null,4)}</pre>
        </div>
      </BackstageLayout>
    );
  }

}

export default BackstagePageDisbursement;
