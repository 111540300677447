import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import currencies     from 'app/helpers/currencies';

const noop = () => {};
const curOpts = currencies.all.map((cur) => {
  return {label: `${cur.symbol} ${cur.code} - ${cur.name}`, value: cur.code};
});

class CurrencyInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refInput = React.createRef();

    this.state = {
      inputStr: null,
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    // this.onSelectCurrency = this.onSelectCurrency.bind(this);
  }

  get currency() {
    return currencies.byCode[this.props.currencyCode];
  }

  get value() {
    if (this.state.inputStr != null) return this.state.inputStr;
    if (!_.isFinite(this.props.amount)) return '';
    return this.currency.format(this.props.amount);
  }

  get inputElement() {
    return this.refInput.current.element;
  }

  onKeyPress(event) {
    const isEnter = event.key === 'Enter';
    if (isEnter) {
      this.refInput.current.element.blur();
    }
  }

  onChangeInput(event) {
    const inputStr = event.target.value || '';
    this.setState({inputStr});
    if (!this.props.changeOnBlur) {
      const amount = this.currency.parse(inputStr);
      this.props.onChange({amount, currencyCode: this.props.currencyCode});
    }
  }

  onBlurInput(event) {
    if (this.props.changeOnBlur) {
      const amount = this.currency.parse(this.state.inputStr);
      this.props.onChange({amount, currencyCode: this.props.currencyCode});
    }
    this.setState({inputStr: null});
    this.props.onBlur();
  }

  // onSelectCurrency(currencyCode) {
  //   this.props.onChange({currencyCode, amount: null});
  // }

  render() {
    return (
      <StandardInput
        name={this.props.name}
        value={this.value}
        label={this.currency.example}
        onChange={this.onChangeInput}
        onBlur={this.onBlurInput}
        prefix={this.currency.code}
        prefixWidth={45}
        onKeyPress={this.onKeyPress}
        ref={this.refInput}
        className={this.props.className}
        validations={this.props.validations}
      />
    );
  }

}

CurrencyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  currencyCode: PropTypes.string,
  amount: PropTypes.number,
  className: PropTypes.string,
  validations: PropTypes.object,
  name: PropTypes.string,
  changeOnBlur: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  onBlur: noop,
  currencyCode: 'USD',
  amount: null,
  className: '',
  name: 'amount',
  changeOnBlur: false,
};

export default CurrencyInput;
