import _ from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import Icon        from 'app/components/common/icon';
import Section     from 'app/components/company-dashboards/section';
import StatsRow    from 'app/components/company-dashboards/stats-row';

class SectionGift extends React.PureComponent {

  get showCustomer() {
    const { dashboard, data } = this.props;
    if (!dashboard.showCustomerGifts) return false;
    return !!data.giftCustomer;
  }

  get showEmployee() {
    const { dashboard, data } = this.props;
    if (!dashboard.showEmployeeGifts) return false;
    return !!data.giftEmployee;
  }

  renderCustomer() {
    if (!this.showCustomer) return null;
    const { data } = this.props;
    const amountInDollars = (_.get(data, 'giftCustomer.amountInCents') || 0) / 100;
    const count = _.get(data, 'giftCustomer.count') || 0;
    const stats = [
      {val: numeral(count).format('0,0'), key: 'Charitable Gifts'},
      {val: numeral(amountInDollars).format('$0,0'), key: 'Gifted'},
    ];
    return (
      <div className="cdash-gift-customer cdash-box">
        <h3 className="cdash-box-heading">Customer Impact</h3>
        <StatsRow stats={stats} color="blue" className="cdash-gift-customer-stats" />
      </div>
    );
  }

  renderEmployee() {
    if (!this.showEmployee) return null;
    const { data } = this.props;
    const amountInDollars = (_.get(data, 'giftEmployee.amountInCents') || 0) / 100;
    const count = _.get(data, 'giftEmployee.count') || 0;
    const stats = [
      {val: numeral(count).format('0,0'), key: 'Charitable Gifts'},
      {val: numeral(amountInDollars).format('$0,0'), key: 'Gifted'},
    ];
    return (
      <div className="cdash-gift-employee cdash-box">
        <h3 className="cdash-box-heading">Employee Gifts</h3>
        <StatsRow stats={stats} color="blue" className="cdash-gift-employee-stats" />
      </div>
    );
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard.showSectionGift) return null;

    const renderCellFns = [];
    if (this.showCustomer) renderCellFns.push(this.renderCustomer.bind(this));
    if (this.showEmployee) renderCellFns.push(this.renderEmployee.bind(this));
    if (renderCellFns.length === 0) return null;
    if (renderCellFns.length === 1) renderCellFns.push(() => null);

    return (
      <Section title="Gifts" color="blue" className="cdash-gift" janchorId="ja-gifts">
        <div className="cdash-gift-row1">
          {renderCellFns.map((renderFn, i) => (
            <div key={i} className={`cdash-gift-row1-cell${i+1}`}>
              {renderFn()}
            </div>
          ))}
        </div>
      </Section>
    );
  }

}

SectionGift.propTypes = {
  data: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default SectionGift;
