import moment from 'moment';
// import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import backstageApi from 'app/apis/backstage';
import BtnMasqueradeCompany from 'app/components/backstage/common/btn-masquerade-company';
import BackstageLayout from 'app/components/backstage/layout';
import ModalEditCompany from 'app/components/backstage/modal-edit-company';
import Link from 'app/components/common/link';
import StandardInput from 'app/components/common/standard-input';
import config from 'app/config';
import {
  CompanyCustomerTypes as CustomerTypes,
} from 'app/constants';
import paths from 'app/paths';

const custTypeNames = {
  [CustomerTypes.CUSTOMER]: 'Customer',
  [CustomerTypes.FRIENDS]: 'Friends & Family',
  [CustomerTypes.TEST]: 'Testing/Dev',
};

class BackstageCompaniesPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      companies: null,
      showNewCompanyModal: false,
      searchStr: null,
    };

    this.onClickNewCompany = this.onClickNewCompany.bind(this);
    this.onCloseNewCompanyModal = this.onCloseNewCompanyModal.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  componentDidMount() {
    backstageApi.companiesFetch().then(({companies}) => {
      this.setState({companies});
    });
  }

  onClickNewCompany() {
    this.setState({showNewCompanyModal: true});
  }

  onCloseNewCompanyModal() {
    this.setState({showNewCompanyModal: false});
  }

  onChangeSearch(event) {
    const searchStr = event.target.value || '';
    this.setState({searchStr});
  }

  getVisibleCompanies() {
    const { companies, searchStr } = this.state;
    if (!companies) return null;
    const searchStrFixed = (searchStr || '').trim().toUpperCase().replace(/[\W_]+/g, '');
    if (!searchStrFixed) return companies;
    return companies.filter((company) => {
      return (company.name || '').toUpperCase().replace(/[\W_]+/g, '').includes(searchStrFixed);
    });
  }

  renderTable() {
    const companies = this.getVisibleCompanies();
    if (!companies) return 'Loading...';
    if (!companies.length) return 'No results.';

    return (
      <table className="backstage sticky-header">
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th className="right">Employees</th>
            <th>Customer Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => {
            const { customerGivingUser: cgUser, employeeGivingUser: egUser } = company;
            const customerUrl = company.stripeCustomerId && `${config.stripeDashboardUrl}/customers/${company.stripeCustomerId}`;

            return (<tr key={company.id}>
              <td className="page-bs-companies-img-field">
                <img src={company.logoUrl} alt={company.name} />
              </td>
              <td><Link className="pink-hover" href={paths.bsCompany(company.id)}>{company.name}</Link></td>
              <td className="right">{company.employeeCount} / {company.saasEmployeeCap}</td>
              <td>{custTypeNames[company.customerType] || company.customerType}</td>
              <td>
                <Link className="btn small secondary" href={paths.bsCompany(company.id)}>Manage</Link>
                {/* <a className="btn small secondary" target="blank" href={customerUrl || '#'}>View in Stripe</a> */}
                {/* <BtnMasqueradeCompany id={company.id} /> */}
                {/* <Link className="btn small secondary danger" href={paths.cadminDashboardView(company.slug, company.companyDashboardId)}>Admin</Link> */}
              </td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { showNewCompanyModal, searchStr } = this.state;
    return (
      <BackstageLayout>
        <div className="bs-page page-bs-companies">
          <div className="page-bs-companies-head">
            <h1 className="bs-page-h1">Companies</h1>
            <StandardInput value={searchStr || ''} onChange={this.onChangeSearch} label="Search..." name="searchStr" />
            <div className="page-bs-companies-head-spacer" />
            <button className="btn blue small" onClick={this.onClickNewCompany}>New Company</button>
          </div>
          {this.renderTable()}
        </div>
        {showNewCompanyModal && (
          <ModalEditCompany onClose={this.onCloseNewCompanyModal} />
        )}
      </BackstageLayout>
    );
  }

}

const stateToProps = (state) => ({
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(BackstageCompaniesPage);
