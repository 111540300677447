import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Checkbox       from 'app/components/common/checkbox';
import EllipsisMenu   from 'app/components/common/ellipsis-menu';
import Icon           from 'app/components/common/icon';
import Link           from 'app/components/common/link';
import Modal          from 'app/components/common/modal';
import StandardSelect from 'app/components/common/standard-select';
import {
  SocialFeedTypes as Types,
  EmployeeRoles,
}                     from 'app/constants';
import Duck           from 'app/ducks/modal-social-post-form';
import paths          from 'app/paths';
import AuthSlx        from 'app/selectors/auth';

const typeLabels = {
  [Types.BRACKET]: 'Bracket',
  [Types.CAMPAIGN]: 'Campaign',
  [Types.EVENT]: 'Event',
  [Types.GROUP]: 'Group',
  [Types.COMPANY]: 'Company Dashboard',
};

class ModalSocialPostAudience extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      feeds: null,
      showAdd: false,
      addType: null,
    };

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickCancelAdd = this.onClickCancelAdd.bind(this);
    this.onSelectAddType = this.onSelectAddType.bind(this);
    this.onSelectAddFeed = this.onSelectAddFeed.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
  }

  getSelectedFeeds(state=this.state) {
    return state.feeds || this.props.feeds || [];
  }

  get availFeeds() {
    return this.props.audOpts?.availableFeeds || [];
  }

  get availTypes() {
    const typeSet = new Set(this.availFeeds.map(f => f.type));
    typeSet.delete(Types.COMPANY);
    return [...typeSet];
  }

  get typeOpts() {
    return this.availTypes.map((type) => {
      return {value: type, label: typeLabels[type]};
    });
  }

  get companyId() {
    return this.availFeeds.find(f => f.type === Types.COMPANY)?.id;
  }

  get showGlobalToggle() {
    // return true;
    const globalChecked = this.getSelectedFeeds().some(f => f.type === Types.COMPANY);
    if (globalChecked) return true;
    return this.isAdmin;
  }

  get isAdmin() {
    // return false;
    return this.props.currentUser?.employment?.employee?.role === EmployeeRoles.ADMIN;
  }

  get canSubmit() {
    if (!this.getSelectedFeeds().length) return false;
    return true;
  }

  get globalFeed() {
    if (!this.showGlobalToggle) return null;
    return this.availFeeds.find(f => f.type === Types.COMPANY) || null;
  }

  get groupFeed() {
    const groups = this.props.audOpts?.associatedGroups || [];
    const group = _.orderBy(groups, 'isPrimary', 'desc')[0];
    if (!group) return null;
    return this.availFeeds.find(af => af.id === group.id) || null;
  }

  get requiredFeed() {
    const {requiredId} = this.props;
    if (!requiredId) return null;
    return this.availFeeds.find(f => f.id === requiredId) || null;
  }

  get staticFeeds() {
    return _.uniqBy([
      this.requiredFeed,
      this.groupFeed,
      this.globalFeed,
    ], 'id').filter(f => f);
  }

  getPath(feed) {
    if (feed.type === Types.BRACKET) return paths.bracket(feed.id);
    if (feed.type === Types.GROUP) return paths.group(feed.id);
    if (feed.type === Types.EVENT) return paths.volEvent(feed.id);
    if (feed.type === Types.CAMPAIGN) return paths.campaign(feed.id);
    return null;
  }

  getSelectOpts(type) {
    const selectedIds = this.getSelectedFeeds().map(f => f.id);
    return this.availFeeds
      .filter((feed) => {
        if (selectedIds.includes(feed.id)) return false;
        if (feed.type === Types.COMPANY) return false;
        if (!type) return true;
        return feed.type === type;
      })
      .map((feed) => {
        const prefix = type ? '' : `${typeLabels[feed.type]}: `;
        return {value: feed.id, label: `${prefix}${feed.name}`};
      });
  }

  onToggle(type, id, event) {
    const checked = event.target.checked;
    this.setState((prevState) => {
      let feeds = [...this.getSelectedFeeds(prevState)];
      if (checked) {
        feeds.push({type, id});
      } else {
        feeds = feeds.filter(f => f.id !== id);
      }
      return {feeds};
    });    
  }

  onSelectAddFeed(id) {
    const type = this.availFeeds.find(f => f.id === id)?.type;
    if (!type) throw new Error(`Oops! Couldn't find the type for the given id to add.`);
    this.setState((prevState) => {
      let feeds = [...this.getSelectedFeeds(prevState)];
      feeds.push({type, id});
      return {feeds, showAdd: false};
    });
  }

  onClickAdd() {
    this.setState({showAdd: true});
  }
  onClickCancelAdd() {
    this.setState({showAdd: false});
  }
  onSelectAddType(addType) {
    this.setState({addType});
  }

  onClose() {
    this.props.onClose();
  }

  onClickCancel() {
    this.props.onClose();
  }

  onClickDone() {
    this.props.onClose(this.getSelectedFeeds());
  }

  renderAdd() {
    const {showAdd, addType} = this.state;
    const availOptsCount = this.getSelectOpts().length;
    if (!availOptsCount) return null;
    if (!showAdd) return (
      <button className="link-btn" onClick={this.onClickAdd}>Add Another Place</button>
    );

    return (<>
      <div className="modal-saud-add-form-row">
        <StandardSelect options={this.getSelectOpts(addType)} onSelect={this.onSelectAddFeed} label="Select a Place" className="modal-saud-add-form-feed" searchLabel="Search..." />
        <StandardSelect options={this.typeOpts} value={addType || null} onSelect={this.onSelectAddType} label="Filter" className="modal-saud-add-form-type" />
        <button className="modal-saud-add-form-row-close" onClick={this.onClickCancelAdd}><Icon.Remove /></button>
      </div>
    </>);
  }

  render() {
    const {feeds, audOpts, requiredId} = this.props;
    if (!audOpts || !feeds) return null;
    const {groupFeed} = this;
    const selectedFeeds = this.getSelectedFeeds();
    const selectedIds = selectedFeeds.map(f => f.id);
    const staticFeeds = this.staticFeeds;
    const staticIds = staticFeeds.map(f => f.id);

    return (
      <Modal onClose={this.onClose} className="modal-saud bform">
        <h2 className="bform-h2 modal-saud-title">Where do you want to share this post?</h2>
        <div className="modal-saud-feeds">
          {staticFeeds.map((feed) => {
            const checked = selectedIds.includes(feed.id);
            const isGlobal = feed.type === Types.COMPANY;
            const isRequired = feed.id === requiredId;
            const disabled = (() => {
              if (isGlobal && !this.isAdmin) return true;
              if (isRequired) return true;
              return false;
            })();
            const name = isGlobal ? 'Company Dashboard' : feed.name;
            const path = this.getPath(feed);
            const typeLabel = isGlobal ? '' : typeLabels[feed.type];
            return (
              <div className="modal-saud-feed" key={feed.id}>
                <Checkbox checked={checked} onChange={this.onToggle.bind(this, feed.type, feed.id)} isToggle offOk className="modal-saud-feed-check" disabled={disabled} />
                <div className="modal-saud-feed-text">
                  <div className="modal-saud-feed-text-name">
                    {!!path ? (
                      <Link href={path} target="_blank" className="pink-hover">{name}</Link>
                    ) : name}
                  </div>
                  {typeLabel && <div className="modal-saud-feed-text-type">{typeLabel}</div>}
                </div>
              </div>
            );
          })}
          {this.getSelectedFeeds().map((feed) => {
            const isStatic = staticIds.includes(feed.id);
            if (isStatic) return null;
            const name = this.availFeeds.find(af => af.id === feed.id)?.name || feed.id;
            const path = this.getPath(feed);
            const typeLabel = typeLabels[feed.type];
            return (
              <div key={feed.id} className="modal-saud-feed">
                <Checkbox checked onChange={this.onToggle.bind(this, feed.type, feed.id)} isToggle offOk className="modal-saud-feed-check" />
                <div className="modal-saud-feed-text">
                  <div className="modal-saud-feed-text-name">
                    {!!path ? (
                      <Link href={path} target="_blank" className="pink-hover">{name}</Link>
                    ) : name}
                  </div>
                  <div className="modal-saud-feed-text-type">{typeLabel}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="modal-saud-add-form">
          {this.renderAdd()}
        </div>

        <div className="bform-actions">
          <div className="bform-actions-right">
            <button className="btn secondary" onClick={this.onClickCancel}>Cancel</button>
            <button className="btn blue" onClick={this.onClickDone} disabled={!this.canSubmit}>Done</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalSocialPostAudience.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ModalSocialPostAudience.defaultProps = {
};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),

  audOpts: Duck.Slx.audOpts(state),
  feeds: Duck.Slx.feeds(state),
  requiredId: Duck.Slx.feedId(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(ModalSocialPostAudience);
